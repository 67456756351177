import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Theme,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useState } from 'react'
import {
  useGetReportGroupUsers,
  useRemoveReportGroupUser,
} from '../../../../hooks'
import { SearchAndPage } from '../../shared/existing-items/search-and-page'
import { NoResultsText } from '../../../../components'
import { LinkedItemChip, LinkedItemChips } from '../../shared'
import { ReportGroup, User } from '../../../../models'
import { LinkUsersMenu } from './link-users-menu'

const PAGE_SIZE = 10

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export function LinkedUsers(props: LinkedUsersProps) {
  const { reportGroup } = props
  const classes = useStyles()
  const [reportSearchQuery, setUserSearchQuery] = useState('')
  const [reportPage, setUserPage] = useState(1)

  const { mutate: removeUser } = useRemoveReportGroupUser()
  const { data: linkedUsers, isLoading: loadingReportGroupsUsers } =
    useGetReportGroupUsers(reportGroup?.id)

  function handleDelete(user: User) {
    removeUser({
      reportGroupId: reportGroup?.id,
      userId: user?.id,
    })
  }

  const filteredUsers =
    linkedUsers?.filter(user =>
      user?.id?.includes(reportSearchQuery?.toLowerCase())
    ) || []

  const usersTotalPages = Math.ceil(filteredUsers?.length / PAGE_SIZE)

  const paginatedUsers = filteredUsers.slice(
    (reportPage - 1) * PAGE_SIZE,
    reportPage * PAGE_SIZE
  )

  function handleUserNextPage() {
    setUserPage(prevPage => prevPage + 1)
  }

  function handleUserPrevPage() {
    setUserPage(prevPage => Math.max(prevPage - 1, 1))
  }

  if (loadingReportGroupsUsers) {
    return (
      <div className={classes.loaderContainer}>
        <Divider sx={{ mb: 1 }} />
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={28}
          sx={{
            borderRadius: 1,
            mb: 1,
          }}
        />
      </div>
    )
  }

  const handleUserSearch = searchText => {
    setUserSearchQuery(searchText)
    setUserPage(1)
  }

  const renderUserSearchAndPage = () => {
    return (
      <SearchAndPage
        show={linkedUsers?.length > 0}
        pageNumber={reportPage}
        totalPagesAvailable={usersTotalPages}
        placeHolderText='Search Users'
        onSearch={handleUserSearch}
        onPrevPage={handleUserPrevPage}
        onNextPage={handleUserNextPage}
      />
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{ width: '100%' }}
    >
      <Box sx={{ px: 2, mt: 1.5 }}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
            Users
          </Typography>
          <LinkUsersMenu reportGroup={reportGroup} />
        </Stack>

        <Divider sx={{ mb: 1 }} />
        {renderUserSearchAndPage()}
        {linkedUsers?.length === 0 ? (
          <NoResultsText>This group has no linked users</NoResultsText>
        ) : (
          <LinkedItemChips>
            {paginatedUsers.map((user: User) => (
              <LinkedItemChip
                key={user?.id}
                label={`${user?.firstName} ${user?.lastName}`}
                onDelete={() => handleDelete(user)}
              />
            ))}
          </LinkedItemChips>
        )}
      </Box>
    </Stack>
  )
}

export type LinkedUsersProps = {
  reportGroup: ReportGroup
}
