import {useState} from 'react'
import {
  Box,
  Typography,
  Stack,
  Switch,
  FormControl,
  IconButton,
} from '@mui/material'
import {useGetPaypalSubscriptionPlan} from '../../hooks/queries/useGetPaypalSubscriptionPlan'
import {LogoutOutlined} from '@mui/icons-material'
import {useSetRecoilState} from 'recoil'
import {environmentAtom} from '../../state'
import {FullPageLoader} from '../../components'
import CompanyMenu from '../../components/layout/company-menu'
import {PricingTier} from './pricingTier'

export function Pricing() {
  
  const {data: plans, isFetched: plansFetched} = useGetPaypalSubscriptionPlan()
  const setCurrentEnvironment = useSetRecoilState(environmentAtom)

  const monthlyPlans = plans?.filter(plan => plan.intervalType === 'MONTH')
  const yearlyPlans = plans?.filter(plan => plan.intervalType === 'YEAR')

  const [isMonthly, setIsMonthly] = useState(true)

  const logout = (): void => {
    setCurrentEnvironment(null)
    window.location.href = '/.auth/logout'
  }

  if (!plansFetched) {
    return <FullPageLoader />
  }

  return (
    <Stack direction='column' justifyContent='center' alignContent='center'>
      <Stack direction='row' justifyContent='flex-end' alignContent='center'>
        <CompanyMenu sx={{color: 'inherit'}} />
        <IconButton
          edge='start'
          color='inherit'
          aria-label='logout'
          LinkComponent={'a'}
          href='/.auth/logout'
          onClick={logout}
          size='large'
          sx={{ml: 1}}
        >
          <LogoutOutlined />
        </IconButton>
      </Stack>
      {/* Hero unit */}
      <Box
        sx={{
          height: '260px',
          backgroundColor: 'secondary.main',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          clipPath: 'ellipse(168% 100% at 50% 0%)',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 4,
          mb: 2,
        }}
      >
        <Typography
          component='h1'
          variant='h4'
          align='center'
          color='text.primary'
          gutterBottom
        >
          Subscribe to start sharing reports
        </Typography>
        <Typography
          variant='subtitle1'
          align='center'
          color='text.secondary'
          component='p'
          sx={{maxWidth: '600px'}}
        >
          It looks like we can't find a valid environment for you. If you'd like to
          purchase environments please subscribe below:
        </Typography>

        <FormControl>
          <Stack direction='row' spacing={1} sx={{mt: 2}} alignItems='baseline'>
            <Typography>Monthly</Typography>
            <Switch
              value={isMonthly}
              onChange={() => setIsMonthly(!isMonthly)}
            />
            <Typography>Yearly</Typography>
          </Stack>
        </FormControl>
      </Box>
      {/* End hero unit */}

      <Stack direction='row' sx={{mt: 2}} gap={2} justifyContent='center'>
        {isMonthly &&
          monthlyPlans?.map(plan => (
            <PricingTier key={plan.planId} plan={plan} />
          ))}
        {!isMonthly &&
          yearlyPlans?.map(plan => (
            <PricingTier key={plan.planId} plan={plan} />
          ))}
      </Stack>

      {/* End footer */}
    </Stack>
  )
}
