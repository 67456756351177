// import { useRecoilValue } from 'recoil'
import { ApiReport } from '../../models'
// import { environmentAtom } from '../../state'
import { reportApi, reportGroupApi } from '../../api-interface'
import { useMutation, useQueryClient } from 'react-query'
import {
  getDashboardKey,
  getReportGroupReportsKey,
  getEnvironmentReportsKey,
} from '../queries'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

type AddReportGroupReportPayload = {
  reportGroupId: string
  reportIds: string[]
  isKeyReport?: boolean
}

export function useAddReportGroupReports() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddReportGroupReportPayload) =>
      reportGroupApi.addReportGroupReports({
        environmentId,
        reportGroupId: payload.reportGroupId,
        reportIds: payload.reportIds,
        isKeyReport: payload.isKeyReport,
      }),
    {
      async onMutate(
        payload: AddReportGroupReportPayload
      ): Promise<ApiReport[]> {
        await queryClient.cancelQueries([
          getReportGroupReportsKey,
          payload.reportGroupId,
        ])

        let allReports: ApiReport[] = queryClient.getQueryData([
          getEnvironmentReportsKey,
          environmentId,
        ])

        if (!allReports) {
          allReports = await queryClient.fetchQuery(
            [getEnvironmentReportsKey, environmentId],
            () => reportApi.getReports(environmentId)
          )
        }

        const previousReports: ApiReport[] = queryClient.getQueryData([
          getReportGroupReportsKey,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getReportGroupReportsKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const reportsBeingAdded = allReports
              .filter((r: ApiReport) => payload.reportIds.includes(r.id))
              .map((r: ApiReport) => {
                return {
                  ...r,
                  isKeyReport: payload.isKeyReport,
                }
              })

            const reportsToSet = [...old, ...reportsBeingAdded]

            return reportsToSet
          }
        )

        return previousReports || []
      },
      onSettled( _data, _error, payload: AddReportGroupReportPayload) {
        
        queryClient.invalidateQueries([ getReportGroupReportsKey, payload.reportGroupId ])
        queryClient.invalidateQueries([ getDashboardKey, environmentId ])
      }
    }
  )
}
