import { useMutation, useQueryClient } from 'react-query'
import { AllBookmarks } from '../../models'
import { bookmarkApi } from '../../api-interface'
import { getBookmarksKey } from '..'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

type DeleteBookmarkContext = {
  previousBookmarks: AllBookmarks
}

type DeleteBookmarkProps = {
  reportId: string
  bookmarkId: string
}


export function useDeleteBookmark() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    
        (payload: DeleteBookmarkProps) => {
          return bookmarkApi.deleteBookmark(
            environmentId,
            payload.reportId,
            payload.bookmarkId
          )
        },
    {
    async onMutate(payload:DeleteBookmarkProps): Promise<DeleteBookmarkContext> {
      const { reportId, bookmarkId: removedBookmarkId } = payload
      await queryClient.cancelQueries([getBookmarksKey, environmentId, payload.reportId])

      let previousBookmarks: AllBookmarks = queryClient.getQueryData([
        getBookmarksKey,
        environmentId,
        reportId,
      ])
      queryClient.setQueryData(
        [getBookmarksKey, environmentId, reportId],
        (old: AllBookmarks) => {
          if (!old) {
            old = {
              ownedBookmarks: [],
              sharedBookmarks: [],
              environmentBookmarks: [],
            }
          }
          return {
            ownedBookmarks:
              old.ownedBookmarks?.filter(
                bookmark => bookmark.id !== removedBookmarkId
              ) || [],
            sharedBookmarks:
              old.sharedBookmarks?.filter(
                bookmark => bookmark.id !== removedBookmarkId
              ) || [],
            environmentBookmarks:
              old.environmentBookmarks?.filter(
                bookmark => bookmark.id !== removedBookmarkId
              ) || [],
          }
        }
      )

      return { previousBookmarks }
    },
    onError(err, payload, context: DeleteBookmarkContext) {
      queryClient.setQueryData(
        [getBookmarksKey, environmentId, payload.reportId],
        context.previousBookmarks
      )
    },
    onSettled(data, error, payload) {
      queryClient.invalidateQueries([getBookmarksKey, environmentId, payload.reportId])
    },
  })
}
