import { useMutation, useQueryClient } from 'react-query'
import { HomepageCards, ReportGroup } from '../../models'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'
import { reportGroupApi } from '../../api-interface'
import { getReportGroupsKey } from '../queries/useGetReportGroups'
import { getDashboardKey } from '../queries'

type DeleteReportGroupContext = {
  previousReportGroups: ReportGroup[]
}

export function useDeleteReportGroup() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (reportGroupId: string) =>
      reportGroupApi.deleteReportGroup(reportGroupId, environmentId),
    {
      async onMutate(reportGroupId: string): Promise<DeleteReportGroupContext> {
        await queryClient.cancelQueries([getReportGroupsKey, environmentId])
        await queryClient.cancelQueries([getDashboardKey, environmentId])

        let previousReportGroups: ReportGroup[] = queryClient.getQueryData([
          getReportGroupsKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getReportGroupsKey, environmentId],
          (old: ReportGroup[] | undefined) =>
            (old ?? []).filter(
              (reportGroup: ReportGroup) => reportGroup.id !== reportGroupId
            )
        )

        queryClient.setQueryData(
          [getDashboardKey, environmentId],
          (old: HomepageCards) => {
            const newGroups = old?.reportGroups?.filter(
              (reportGroup: ReportGroup) => reportGroup.id !== reportGroupId
            )
            return {
              reportGroups: newGroups,
              reports: old?.reports || [],
            }
          }
        )

        return { previousReportGroups }
      },
      onError(_err, _deletedReportGroup, context: DeleteReportGroupContext) {
        queryClient.setQueryData(
          [getReportGroupsKey, environmentId],
          context.previousReportGroups
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getDashboardKey, environmentId])
        queryClient.invalidateQueries([getReportGroupsKey, environmentId])
      },
    }
  )
}
