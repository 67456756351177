import { useMemo } from 'react'
import { useGetFavorites } from './queries/useGetFavorites'
import { useAddItemsToFavorites } from './mutations/useAddItemToFavoritesFolder'
import { useRemoveItemFromFavorites } from './mutations/useRemoveItemFromFavoritesFolder'
import { FolderItem } from '../models'

/**
 * Custom hook to manage favorite state and actions for an item.
 * @param item Folder item to manage favorite state for
 * @returns Object containing isFavorite state, loading state, and toggle function
 */
export const useFavorites = (item: FolderItem) => {
  // Fetch favorites data
  const {
    data: favorites = { items: [] },
    isLoading: isFavoritesLoading,
    refetch,
  } = useGetFavorites()
  const { mutateAsync: addToFavorites, isLoading: isAdding } =
    useAddItemsToFavorites()
  const { mutateAsync: removeFromFavorites, isLoading: isRemoving } =
    useRemoveItemFromFavorites()

  // Determine if the item is favorited using useMemo for performance
  const isFavorite = useMemo(() => {
    const status = favorites?.items?.some(fav => fav.id === item.id)

    return status
  }, [favorites, item.id])

  // Combine loading states for adding/removing favorites
  const isLoadingFavorites = isFavoritesLoading || isAdding || isRemoving

  /**
   * Toggles the favorite status of the item.
   * Refetches favorites data after mutation for consistency.
   */
  const toggleFavorite = async () => {
    try {
      let newFavoriteStatus = !isFavorite // Optimistic update
      if (isFavorite) {
        await removeFromFavorites({ itemId: item.id })
      } else {
        await addToFavorites({ item })
      }

      // Refetch to confirm the change
      const refetchResult = await refetch()

      // Verify the update
      const updatedFavorites = refetchResult.data || favorites
      const updatedStatus = updatedFavorites?.items?.some(
        fav => fav.id === item.id
      )
      if (updatedStatus !== newFavoriteStatus) {
        console.warn(
          'Favorite status mismatch after refetch, expected:',
          newFavoriteStatus,
          'got:',
          updatedStatus
        )
      }
    } catch (error) {
      console.error('Failed to toggle favorite status:', error)
      // Optionally revert optimistic update or notify user
    }
  }

  return {
    isFavorite,
    isLoadingFavorites,
    toggleFavorite,
  }
}
