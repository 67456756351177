import {useMutation} from 'react-query'
import {useRecoilValue} from 'recoil'
import {profileApi} from '../../api-interface'
// import {Profile} from '../../models'
import {environmentAtom} from '../../state'

type AddProfileToWorkspacePayload = {
  profileId: string
  workspaceId: string
  appRegistrationNodeId: string
  appClientId: string
  appRegistrationTenantId: string
}

export function useAddProfileToWorkspace() {
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation((payload: AddProfileToWorkspacePayload) =>
    profileApi.assignProfileToWorkspace({
      profileId: payload.profileId,
      workspaceId: payload.workspaceId,
      appRegistrationNodeId: payload.appRegistrationNodeId,
      appClientId: payload.appClientId,
      appRegistrationTenantId: payload.appRegistrationTenantId,
      environmentId: environmentId,
    })
  )
}
