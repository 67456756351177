import React from 'react'
import { Box, Typography, TextField } from '@mui/material'
import CustomDialog from '../../../shared/dialog/dialog'

type UpgradeLicenseDialogProps = {
  open: boolean
  onClose: () => void
  licenseSku: string
  onUpgrade: () => void
  onSkuChange: (sku: string) => void
}

export const UpgradeLicenseDialog: React.FC<UpgradeLicenseDialogProps> = ({
  open,
  onClose,
  licenseSku,
  onUpgrade,
  onSkuChange,
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title='Upgrade License'
      primaryButton
      primaryButtonProps={{
        children: 'Upgrade',
        onClick: onUpgrade,
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography>Current License: {licenseSku}</Typography>
        <TextField
          fullWidth
          label='License SKU'
          margin='normal'
          value={licenseSku}
          onChange={e => onSkuChange(e.target.value)}
        />
        <Typography variant='body2'>
          Are you sure you want to upgrade your license?
        </Typography>
      </Box>
    </CustomDialog>
  )
}
