import { Box, CircularProgress, Stack, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { PbiReport } from './pbi-report'
import {
  useGetBookmarks,
  useGetEnvironment,
  useGetReport,
  useCloneReport,
} from '../../../hooks'
import { Layout } from '../../../components'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { BookmarkSelector } from '../bookmarks/bookmark-selector'
import { useRecoilState } from 'recoil'
import { bookmarkAtom } from '../../../state'
import { ErrorPage } from './error-page'
import BookmarkDialog from '../bookmarks/bookmark-dialog'
import { ReportOptionsBar } from './report-options-bar'
import {
  ResizableDrawer,
  ResizeState,
} from '../../admin/shared/resizable-drawer'
import { CustomReportTable } from '../create-custom-report/custom-report-table'
import { REPORT_TYPES, WorkspaceInfo } from '../../../models'

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: { display: 'flex', justifyContent: 'space-between' },

  drawerPaper: {
    zIndex: 8,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },

  embedWrapper: {
    height: 'calc(100vh - 64px)',
  },

  embeddedReport: {
    '& iframe': {
      border: 'none',
    },

    width: '100%',
    // flex: 1,
  },
  disablePointerEvents: {
    pointerEvents: 'none',
    flex: 1,
    filter: 'blur(2px)',
    '& iframe': {
      border: 'none',
    },
  },
}))

export function ReportView() {
  const classes = useStyles()
  const routeParams = useParams<any>()
  const reportId = routeParams.id
  const bookmarkId = routeParams.bookmark

  const { data: bookmarks, isLoading: bookmarksLoading } =
    useGetBookmarks(reportId)
  const { data: environment, isLoading: environmentLoading } =
    useGetEnvironment()
  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)

  const {
    data: reportData,
    isLoading,
    refetch: reloadReport,
  } = useGetReport(reportId)

  const report = reportData?.report

  const [bookmarkToEdit, setBookmarkToEdit] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [editMode, setEditMode] = useState(false)
  const toggleEditMode = () => {
    setEditMode(o => !o)
  }

  // if the permissions includes OWNER or ITEM_OWNER then the user is the owner of the report
  // const isOwner =
  //   reportData?.permissions?.includes('OWNER') ||
  //   reportData?.permissions?.includes('ITEM_OWNER')

  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [copyReport, setCopyReport] = useState<{
    name: string
    description: string
    shareWithEnvironment: boolean
    workspace: WorkspaceInfo
  }>(null)

  const [isCloning, setIsCloning] = useState(false)

  const [resizableState, setResizableState] = useState<ResizeState>({
    isResizing: false,
    lastDownX: 0,
    newWidth: { width: 260 },
  })

  const [hasRendered, setHasRendered] = useState(false)

  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {
    setIsResizing(true)

    setTimeout(() => {
      setIsResizing(false)
    }, 300)
  }, [bookmarkState?.panelOpen])

  useEffect(() => {
    //if resizableState.isResizing is true then set isResizing to true if it is false wait 1 second then set to false
    if (resizableState.isResizing) {
      setIsResizing(true)
    } else {
      setTimeout(() => {
        setIsResizing(false)
      }, 400)
    }
  }, [resizableState.isResizing])

  const { mutateAsync: cloneReport } = useCloneReport()
  const history = useHistory()

  useEffect(() => {
    if (bookmarksLoading || !bookmarks) return
    const allBookmarks = [
      ...bookmarks.environmentBookmarks,
      ...bookmarks.sharedBookmarks,
      ...bookmarks.ownedBookmarks,
    ]

    if (!bookmarkId || bookmarkId === bookmarkState?.selectedBookmark?.id)
      return
    const bookmark = allBookmarks.find(b => b.id === bookmarkId)
    if (!bookmark) return
    setBookmarkState({
      ...bookmarkState,
      selectedBookmark: bookmark,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarks, bookmarksLoading, bookmarkId])

  const toggleDialog = useCallback(() => {
    setIsDialogOpen(o => !o)
  }, [])

  const reportEmbedSettings = useMemo(
    () => ({
      filterPaneEnabled: report?.filterPaneEnabled,
      showPageNavigation: report?.showPageNavigation,
      defaultPageName: report?.defaultPageName,
    }),
    [report]
  )

  useEffect(() => {
    const onSave = async () => {
      if (!copyReport || !copyReport.workspace?.id) return

      setIsCloning(true)

      const newReport = await cloneReport({
        sourceReportId: reportId,
        newName: copyReport.name,
        description: copyReport?.description,
        environmentNodeId: environment.id,
        workspaceId: copyReport.workspace?.id,
        profileId: copyReport.workspace?.profileId,
      })

      await reloadReport()
      setEditMode(false)
      setCopyReport(null)
      setIsCloning(false)
      setShowCopyDialog(false)
      history.push(`/reports/${newReport.id}`)
    }
    onSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyReport])

  const isDataset = report?.type === REPORT_TYPES.DATASET

  const isCustomPaginatedReport = report?.type === REPORT_TYPES.CUSTOM_PAGINATED

  return (
    <Layout
      title={report?.name || ''}
      showBackButton
      hideLogo
      hideBackground
      fullWidth
    >
      {isLoading || bookmarksLoading || environmentLoading || isCloning ? (
        <Box
          sx={{
            width: 'calc(100vw)',
            height: 'calc(100vh - 400px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            sx={{
              fontSize: 40,
            }}
          />
        </Box>
      ) : !report ? (
        <ErrorPage>
          Error: You do not have access to this report or the report does not
          exist
        </ErrorPage>
      ) : (
        <>
          {isDataset || (isCustomPaginatedReport && editMode) ? (
            <Box
              className={classes.embedWrapper}
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <CustomReportTable
                currentReportId={reportId}
                name={report?.name}
                report={report}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  width: bookmarkState.panelOpen
                    ? `calc(100vw - ${resizableState.newWidth.width + 12}px)`
                    : '100%',
                  transition: theme =>
                    theme.transitions.create(['margin', 'width'], {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                }}
              >
                <ReportOptionsBar
                  reportId={reportId}
                  editMode={editMode}
                  toggleEditMode={toggleEditMode}
                  showCopyDialog={showCopyDialog}
                  setShowCopyDialog={setShowCopyDialog}
                  setCopyReport={setCopyReport}
                  reportType={report?.type || ''}
                  hasRendered={hasRendered}
                />
              </Box>
              <Stack
                direction='column'
                // className={classes.embedWrapper}
                sx={{
                  display: 'flex',
                  flex: 1,
                  height: 'calc(100vh - 112px)',
                  marginRight: bookmarkState.panelOpen
                    ? `${resizableState.newWidth.width + 12}px`
                    : 0,
                  width: bookmarkState.panelOpen
                    ? `calc(100vw - ${resizableState.newWidth.width + 12}px)`
                    : 'calc(100vw)',
                  transition: theme =>
                    theme.transitions.create(['margin', 'width'], {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                }}
              >
                <PbiReport
                  setHasRendered={setHasRendered}
                  reportId={reportId}
                  reportEmbedSettings={reportEmbedSettings}
                  embedType='report'
                  className={
                    isResizing
                      ? classes.disablePointerEvents
                      : classes.embeddedReport
                  }
                  editMode={editMode}
                  setShowCopyDialog={setShowCopyDialog}
                  copyReport={copyReport}
                  isResizing={isResizing}
                />
              </Stack>
            </>
          )}
          {bookmarkState.panelOpen && (
            <ResizableDrawer
              open={bookmarkState.panelOpen}
              resizeState={resizableState}
              setResizeState={setResizableState}
              minWidth={100}
              maxWidth={1000}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <BookmarkSelector
                  bookmarks={bookmarks}
                  bookmarksLoading={bookmarksLoading}
                  reportId={reportId}
                  environmentId={environment.id}
                  onAddClick={() => {
                    setBookmarkToEdit(null)
                    toggleDialog()
                  }}
                  onEdit={bookmark => {
                    setIsDialogOpen(true)
                    setBookmarkToEdit(bookmark)
                  }}
                  drawerWidth={resizableState?.newWidth?.width}
                />
              </div>
            </ResizableDrawer>
          )}
          <BookmarkDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            reportId={reportId}
            bookmark={bookmarkToEdit}
          />
        </>
      )}
    </Layout>
  )
}
