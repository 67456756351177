import { Profile } from '../models'
import { getAxios, endpoints } from './util'

export const profileApi = {
  // async createProfile(profile: Profile) {
  //   let axios = await getAxios()
  //   const response = await axios({
  //     method: 'post',
  //     url: endpoints.profiles,
  //     data: profile,
  //   })
  //   return response
  // },

  async createProfileConnection({
    profile,
    appRegistrationNodeId,
    appClientId,
    appRegistrationTenantId,
    environmentId,
  }: {
    profile: Profile
    appRegistrationNodeId: string
    appClientId: string
    appRegistrationTenantId: string
    environmentId: string
  }) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.appRegistrationProfiles(
        environmentId,
        appRegistrationNodeId
      ),
      data: {
        profileName: profile.name,
        profileDescription: profile.description,
        appClientId,
        appRegistrationTenantId,
      },
    })

    return response
  },

  async assignProfileToWorkspace({
    profileId,
    workspaceId,
    appRegistrationNodeId,
    appClientId,
    appRegistrationTenantId,
    environmentId,
  }: {
    profileId: string
    workspaceId: string
    appRegistrationNodeId: string
    appClientId: string
    appRegistrationTenantId: string
    environmentId: string
  }) {
    let axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.pbiWorkspaceProfiles(environmentId, workspaceId),
      data: {
        profileId: profileId,
        // todo: consider removing tenant and client id.
        appRegistrationNodeId: appRegistrationNodeId,
        appClientId: appClientId,
        appRegistrationTenantId: appRegistrationTenantId,
      },
    })
  },

  async updateProfile(
    profile: Profile,
    appRegistrationNodeId: string,
    environmentId: string
  ) {
    let axios = await getAxios()
    const patchData = {
      profileName: profile.name,
      profileDescription: profile.description,
    }
    const updateProfileResponse = await axios({
      method: 'patch',
      url: endpoints.appRegistrationProfile(
        environmentId,
        appRegistrationNodeId,
        profile.id
      ),
      data: patchData,
    })

    return updateProfileResponse
  },

  async deleteProfile(
    profileId: string,
    environmentId: string,
    appRegistrationNodeId: string,
    appClientId: string,
    appRegistrationTenantId: string
  ) {
    let deleteData = {
      appClientId,
      appRegistrationTenantId,
    }

    let axios = await getAxios()
    const deleteProfileResponse = await axios({
      method: 'delete',
      url: endpoints.appRegistrationProfile(
        environmentId,
        appRegistrationNodeId,
        profileId
      ),
      data: deleteData,
    })

    return deleteProfileResponse
  },

  // async getProfilesByAppRegId(appRegId: string) {
  //   let axios = await getAxios()

  //   const url = getUrl(endpoints.profile, {appRegId: appRegId})
  //   const response = await axios({
  //     method: 'get',
  //     url: url,
  //   })

  //   const existingAzureRegistrations: Profile[] = response.data.payload.map(
  //     (profile: Profile) => {
  //       return {
  //         id: profile.id,
  //         name: profile.name,
  //       }
  //     }
  //   )

  //   return existingAzureRegistrations
  // }

  //TODO: Implement these functions

  //   async getLinkedReportsByProfile(profileId: string) {
  //     let axios = await getAxios()

  //     let response = await axios({
  //       method: 'get',
  //       url: getUrl(endpoints.profileReports, {
  //         profileId: profileId,
  //       }),
  //     })

  //     let reports: Array<{id: string; name: string}> = response.data.payload
  //     return reports.map(r => r.id)
  //   },

  //   async linkReportsToProfile(
  //     profileId: string,
  //     reportIds: string[],
  //     environmentId: string
  //   ): Promise<void> {
  //     let axios = await getAxios()

  //     let response = await axios({
  //       method: 'post',
  //       url: endpoints.profileReports,
  //       data: {
  //         environmentId: environmentId,
  //         profileId: profileId,
  //         reportIds: reportIds,
  //       },
  //     })

  //     const {success, errorMessage} = response.data
  //     if (!success) throw new Error(errorMessage)
  //   },

  //   async unlinkReportFromProfile(
  //     profileId: string,
  //     reportId: string
  //   ): Promise<void> {
  //     let axios = await getAxios()

  //     let response = await axios({
  //       method: 'delete',
  //       url: endpoints.profileReports,
  //       data: {
  //         profileId: profileId,
  //         reportId: reportId,
  //       },
  //     })

  //     const {success, errorMessage} = response.data
  //     if (!success) throw new Error(errorMessage)
  //   },
}
