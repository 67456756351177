import { FC, useRef, useState } from 'react'

import {
  Box,
  Button,
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Typography,
  Theme,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material'
import { alpha, SxProps } from '@mui/system'
import { useCurrentUserInfo } from '../../hooks/queries/useGetCurrentUserInfo'
import { useRecoilState } from 'recoil'
import { environmentAtom } from '../../state'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { useUpdateDefaultEnvironment } from '../../hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { createStyles, makeStyles } from '@mui/styles'

type CompanyMenuProps = {
  sx?: SxProps<Theme>
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      buttonColor: {
        [theme.breakpoints.up('md')]: {
          backgroundColor: alpha('#fff', 0.2),
        },
        [theme.breakpoints.down('md')]: {
          backgroundColor: 'transparent',
        },
      },
    }),
  { name: 'company-menu' }
)

const CompanyMenu: FC<CompanyMenuProps> = () => {
  const { data: userInfo } = useCurrentUserInfo()
  const { mutate: updateDefaultEnvironment } = useUpdateDefaultEnvironment()
  const [currentEnvironmentId, setCurrentEnvironment] =
    useRecoilState(environmentAtom)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const handleClick = (): void => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleCloseMenu = (event: MouseEvent | TouchEvent): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const onEnvironmentChange = (environmentId: string): void => {
    setCurrentEnvironment(environmentId)
    window.location.href = '/'
    setOpen(false)
  }

  const onFavorite = (event: React.MouseEvent, environmentId: string): void => {
    event.stopPropagation()
    event.preventDefault()
    updateDefaultEnvironment(environmentId)
  }

  if (!userInfo || !userInfo.environments || userInfo.environments.length <= 1)
    return <></>

  return (
    <>
      <Button
        ref={anchorRef}
        id='company-menu-button'
        aria-controls={open ? 'company-environment-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
        disableElevation
        className={classes.buttonColor}
      >
        <Stack direction='row' alignItems='center' gap={1} sx={{ mr: -1 }}>
          <BusinessOutlinedIcon sx={{ height: '20px' }} />
          <Typography variant='body2'>
            {
              userInfo.environments.find(
                environment => environment.id === currentEnvironmentId
              )?.name
            }
          </Typography>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Stack>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role='menu'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            {userInfo.environments && (
              <Paper>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList
                    autoFocusItem={open}
                    id='company-environment-menu'
                    onKeyDown={handleListKeyDown}
                  >
                    {userInfo.environments.map((environment, index: number) => [
                      <MenuItem
                        key={environment.id}
                        onClick={() => onEnvironmentChange(environment.id)}
                        //disabled={environment.id === currentEnvironmentId}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          width='100%'
                          spacing={1}
                        >
                          <Typography
                            sx={{
                              fontWeight:
                                environment.id === currentEnvironmentId
                                  ? 700
                                  : 400,
                            }}
                          >
                            {environment.name}
                          </Typography>
                          <Tooltip title='Default Company'>
                            <IconButton
                              onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => onFavorite(event, environment.id)}
                            >
                              {environment.default ? (
                                <StarIcon />
                              ) : (
                                <StarBorderIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </MenuItem>,
                      <Box>
                        {index < userInfo.environments.length - 1 && (
                          <Divider />
                        )}
                      </Box>,
                    ])}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            )}
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default CompanyMenu
