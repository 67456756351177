import { useMutation, useQueryClient } from 'react-query'
import { reportGroupApi } from '../../api-interface'
import { getDashboardKey, getReportGroupReportsKey } from '../queries'
import { ApiReport } from '../../models'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

export const removeReportGroupReportKey = 'remove-report-group-reports'

export type RemoveReportGroupReportsPayload = {
  reportGroupId: string
  reportId: string
  onlyRemoveKeyReport?: boolean
}

export function useRemoveReportGroupReport() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (payload: RemoveReportGroupReportsPayload) =>
      reportGroupApi.removeReportGroupReport(
        environmentId,
        payload.reportGroupId,
        payload.reportId,
        payload.onlyRemoveKeyReport
      ),
    {
      async onMutate(
        payload: RemoveReportGroupReportsPayload
      ): Promise<ApiReport[]> {
        await queryClient.cancelQueries([
          getReportGroupReportsKey,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getReportGroupReportsKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const updatedReports = old?.filter(r => r.id !== payload.reportId)
            return updatedReports
          }
        )

        let previousReports: ApiReport[] = queryClient.getQueryData([
          getReportGroupReportsKey,
          payload.reportGroupId,
        ])

        let allPrevReports = previousReports
        // why
        if (allPrevReports?.length === 0) {
          previousReports = await queryClient.fetchQuery(
            [getReportGroupReportsKey, payload.reportGroupId],
            () =>
              reportGroupApi.getReportGroupReports(
                environmentId,
                payload.reportGroupId
              )
          )
          allPrevReports = previousReports
        }

        queryClient.setQueryData(
          [getReportGroupReportsKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const updatedReports = old?.filter(
              (oldReport: ApiReport) => oldReport.id !== payload.reportId
            )

            return updatedReports
          }
        )

        return allPrevReports
      },
      onError(
        _err,
        payload: RemoveReportGroupReportsPayload,
        previousReports: ApiReport[]
      ) {
        queryClient.setQueryData(
          [getReportGroupReportsKey, payload.reportGroupId],
          previousReports
        )
      },
      onSettled: (data, error, payload: RemoveReportGroupReportsPayload) => {
        queryClient.invalidateQueries([
          getReportGroupReportsKey,
          payload.reportGroupId,
        ])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
