import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getGroupUsersKey = 'get-group-users'

export function useGetGroupUsers(groupId: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery([getGroupUsersKey, groupId], () =>
    groupApi.getGroupUsers(groupId, environmentId)
  )
}
