import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { groupApi } from '../../api-interface'
import { User } from '../../models'
import { environmentAtom } from '../../state'
import {
  getGroupUsersKey,
  getOtherUsersOnEnvironmentKey,
  getDashboardKey,
} from '../queries'

type AddUsersToGroupPayload = { groupId: string; userIds: string[] }

export function useAddUsersToGroup() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddUsersToGroupPayload) =>
      groupApi.addGroupUsers(payload.groupId, payload.userIds, environmentId),
    {
      async onMutate(payload: AddUsersToGroupPayload): Promise<User[]> {
        await queryClient.cancelQueries([getGroupUsersKey, payload.groupId])

        const allUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnEnvironmentKey,
          environmentId,
        ])

        const previousUsers: User[] = queryClient.getQueryData([
          getGroupUsersKey,
          payload.groupId,
        ])

        const updatedUsers = allUsers
          .filter((u: User) => payload.userIds.includes(u.id))
          .concat(previousUsers)
          .sort((a: User, b: User) => a.firstName.localeCompare(b.firstName))

        queryClient.setQueryData(
          [getGroupUsersKey, payload.groupId],
          updatedUsers
        )

        return previousUsers
      },
      onSettled(
        _data,
        _error,
        payload: AddUsersToGroupPayload,
        _previousGroups
      ) {
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
