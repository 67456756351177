import { useMutation, useQueryClient } from 'react-query'
import { ReportGroup, ReportGroupToCreate } from '../../models'
import { environmentAtom } from '../../state'
import { useRecoilValue } from 'recoil'
import { reportGroupApi } from '../../api-interface'
import { getReportGroupsKey } from '../queries/useGetReportGroups'
import { getDashboardKey } from '../queries'

type CreateReportGroupContext = {
  previousReportGroups: ReportGroup[]
}

export function useCreateReportGroup() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: ReportGroupToCreate) =>
      reportGroupApi.createReportGroup({ reportGroup: payload, environmentId }),
    {
      async onMutate(
        newReportGroup: ReportGroupToCreate
      ): Promise<CreateReportGroupContext> {
        await queryClient.cancelQueries([getReportGroupsKey, environmentId])

        let previousReportGroups: ReportGroup[] = queryClient.getQueryData([
          getReportGroupsKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getReportGroupsKey, environmentId],
          (old: ReportGroup[]) => {
            if (old) return [...old, { ...newReportGroup }]

            return old
          }
        )

        return { previousReportGroups }
      },

      onError(_err, _newReportGroup, context: CreateReportGroupContext) {
        queryClient.setQueryData(
          [getReportGroupsKey, environmentId],
          context.previousReportGroups
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getReportGroupsKey, environmentId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
