import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {workspacesApi} from '../../api-interface'
import {User} from '../../models'
import {environmentAtom} from '../../state'
import {getWorkspaceUsersKey, getOtherUsersOnEnvironmentKey} from '../queries'

type AddUsersToWorkspacePayload = {workspaceId: string; userIds: string[]}

export function useAddUsersToWorkspace() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddUsersToWorkspacePayload) =>
      workspacesApi.addWorkspaceUsers(payload.workspaceId, payload.userIds, environmentId), 
    {
      async onMutate(payload: AddUsersToWorkspacePayload): Promise<User[]> {
        await queryClient.cancelQueries([getWorkspaceUsersKey, payload.workspaceId])

        const allUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnEnvironmentKey,
          environmentId,
        ])

        const previousUsers: User[] = queryClient.getQueryData([
          getWorkspaceUsersKey,
          payload.workspaceId,
        ])

        const updatedUsers = allUsers
          .filter((u: User) => payload.userIds.includes(u.id))
          .concat(previousUsers)
          .sort((a: User, b: User) => a.firstName.localeCompare(b.firstName))

        queryClient.setQueryData(
          [getWorkspaceUsersKey, payload.workspaceId],
          updatedUsers
        )

        return previousUsers
      },
    }
  )
}
