
import {Group, User} from '../models'
import {getAxios, endpoints} from './util'

export const groupApi = {
  async createGroup(params: {
    groupName: string
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.groups(params.environmentId),
      data: {groupName: params.groupName},
    })
  },

  async editGroup(params: {
    groupId: string
    groupName: string
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()
    
    await axios({
      method: 'patch',
      url: endpoints.group(params.environmentId, params.groupId),
      data: {groupName: params.groupName},
    })
  },

  async deleteGroup(groupId: string, environmentId: string): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.group(environmentId, groupId),
    })
  },

  async getGroups(environmentId: string): Promise<Group[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.groups(environmentId),
    })

    return response.data as Group[]
  },

  async getGroupUsers(groupId: string, environmentId: string): Promise<User[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.groupUsers(environmentId, groupId)
    })

    return response.data as User[]
  },

  async addGroupUsers(
    groupId: string,
    userIds: string[],
    environmentId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.groupUsers(environmentId, groupId),
      data: {userIds},
    })
  },

  async addUserToGroups(
    groupIds: string[],
    userId: string,
    environmentId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.userGroups(environmentId, userId),
      data: {groupIds},
    })
  },

  async removeGroupUser(
    groupId: string,
    userId: string,
    environmentId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.groupUser(environmentId, groupId, userId),
    })
  },
}
