import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getDashboardKey = 'get-dashboard-key'

export function useGetDashboard() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getDashboardKey, environmentId],
    () => reportApi.getDashboard(environmentId),
    {
      enabled: !!environmentId,
    }
  )
}
