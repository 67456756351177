import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { profileApi } from '../../api-interface'
import { Profile } from '../../models'
import { environmentAtom } from '../../state'
import { getAppRegistrationsKey } from '../queries'

type UpdateProfileContext = {
  previousProfile: Profile[]
}

type UpdateProfileProps = {
  profile: Profile
  appRegistrationNodeId: string
}

export function useUpdateProfile() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: UpdateProfileProps) => {
      //If secret in payload is the placeholder value, remove it from the payload

      return profileApi.updateProfile(
        payload.profile,
        payload.appRegistrationNodeId,
        environmentId
      )
    },
    {
      async onMutate(payload): Promise<UpdateProfileContext> {
        await queryClient.cancelQueries([getAppRegistrationsKey, environmentId])

        let previousProfile: Profile[] = queryClient.getQueryData([
          getAppRegistrationsKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getAppRegistrationsKey, environmentId],
          (old: Profile[]) => {
            if (!old) return []
            return old?.map(profile => {
              if (profile.id === payload.profile.id) {
                return {
                  id: payload.profile.id,
                  name: payload.profile.name,
                  description: payload.profile.description,
                }
              }
              return profile
            })
          }
        )

        return { previousProfile }
      },
      onError(_err, _updatedUser, context: UpdateProfileContext) {
        queryClient.setQueryData(
          [getAppRegistrationsKey, environmentId],
          context.previousProfile
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAppRegistrationsKey, environmentId])
      },
    }
  )
}
