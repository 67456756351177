import { useMutation, useQueryClient } from 'react-query'
import { environmentApi } from '../../api-interface'

import { getEnvironmentQueryKey, getAdminEnvironments } from '../queries'

type UpdateEnvironmentPayload = {
  environmentId: string
  environmentName: string
}

export function useUpdateEnvironment() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: UpdateEnvironmentPayload) =>
      environmentApi.updateEnvironment(payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries([getEnvironmentQueryKey])
        queryClient.invalidateQueries([getAdminEnvironments])
      },
    }
  )
}
