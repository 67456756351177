import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder } from '../../models'
import { environmentAtom } from '../../state'
import { getDashboardKey, getFoldersKey } from '../queries'

type DeleteFolderContext = {
  previousFolders: Folder[]
}

export type RemoveFolderPayload = {
  folderId: string
  reportGroupId?: string
}

export function useDeleteFolder() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (payload: RemoveFolderPayload) =>
      folderApi.deleteFolder(payload.folderId, environmentId),
    {
      async onMutate(
        payload: RemoveFolderPayload
      ): Promise<DeleteFolderContext> {
        await queryClient.cancelQueries([
          getFoldersKey,
          environmentId,
          payload.reportGroupId,
        ])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          environmentId,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getFoldersKey, environmentId, payload.reportGroupId],
          (old: Folder[]) =>
            old?.filter((folder: Folder) => folder.id !== payload.folderId)
        )

        return { previousFolders }
      },
      onError(_err, _deletedFolder, context: DeleteFolderContext) {
        queryClient.setQueryData(
          [getFoldersKey, environmentId, _deletedFolder.folderId],
          context?.previousFolders
        )
      },
      onSettled(_err, _other, _deletedFolder) {
        queryClient.invalidateQueries([
          getFoldersKey,
          environmentId,
          _deletedFolder.reportGroupId,
        ])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
