import {FC} from 'react'
import {
  useAddViewersToReport,
  useGetOtherUsersOnEnvironment,
  useGetReportViewers,
} from '../../../../hooks'
import {ReportData} from '../../../../models'
import {LinkItemsMenu} from '../../shared'

type LinkViewersMenuProps = {
  report: ReportData
}

export const LinkViewersMenu: FC<LinkViewersMenuProps> = (
  props: LinkViewersMenuProps
) => {
  const {report} = props
  const {data: users} = useGetOtherUsersOnEnvironment()
  const {data: {users: linkedViewers} = {users: []}} = useGetReportViewers(
    report.id
  )
  const {mutateAsync: addViewers} = useAddViewersToReport()

  const linkedViewerIds = linkedViewers && linkedViewers.map(v => v.id)
  const usersAvailableToAdd =
    users &&
    linkedViewerIds &&
    users.filter(u => !linkedViewerIds.includes(u.id))

  const linkViewers = async (viewerIds: string[]) => {
    let linkedViewerIds = linkedViewers.map(v => v.id)
    let viewersToAdd = viewerIds.filter(v => !linkedViewerIds.includes(v))

    if (!viewersToAdd.length) return

    await addViewers({
      reportId: report.id,
      viewerIds: viewersToAdd,
      viewerType: 'user',
    })
  }

  return (
    <LinkItemsMenu
      onConfirm={linkViewers}
      addButtonLabel='Add Viewers'
      placeHolderText='Search users'
      availableItems={
        usersAvailableToAdd &&
        usersAvailableToAdd.map(u => {
          return {
            id: u.email,
            name: `${u.firstName} ${u.lastName}`,
            username: u.email,
          }
        })
      }
    />
  )
}
