import {AppRegistration, AppRegistrationState, AppRegItem} from '../models'
import {getAxios, endpoints} from './util'

export const appRegApi = {
  async createAppRegistration(appReg: AppRegistration) {
    let axios = await getAxios()
    const {environmentNodeId, ...appRegistration} = appReg

    const response = await axios({
      method: 'post',
      url: endpoints.appRegistrations(appReg.environmentNodeId),
      data: appRegistration,
    })
    return response.data.appRegistrationNodeId
  },

  async getAppRegistrations(environmentId: string) {
    let axios = await getAxios()

    const url = endpoints.appRegistrations(environmentId)
    const response = await axios({
      method: 'get',
      url: url,
    })

    const existingAzureRegistrations: AppRegistrationState[] =
      response.data?.map((appReg: AppRegistration) => {
        return {
          id: appReg.id,
          name: appReg.name,
          appRegistrationId: appReg.appRegistrationId,
          tenantId: appReg.tenantId,
          profiles: appReg.profiles,
        }
      })

    return existingAzureRegistrations
  },

  async deleteAppRegistration(environmentId, appRegistrationId: string) {
    
    let axios = await getAxios()
    await axios({
      method: 'delete',
      url: endpoints.appRegistration(environmentId, appRegistrationId),

    })
    // TODO: perhaps we need to return the response or at least look at the response.
    return appRegistrationId
  },

  async updateAppRegistration(appReg: AppRegistration) {
    let axios = await getAxios()
    const {environmentNodeId, ...appRegistration} = appReg
    await axios({
      method: 'patch',
      url: endpoints.appRegistration(environmentNodeId, appRegistration.id),
      data: appRegistration,
    })
  },
    async getAppRegistrationItems(environmentId: string, appRegId: string):Promise<AppRegItem[]> {
    let axios = await getAxios()

    const url = endpoints.appRegistrationItems(environmentId, appRegId)
    const response = await axios({
      method: 'get',
      url: url,
    })

    return response.data
    
  },

  //todo: This is not used today
  async getLinkedReportsByAppReg(environmentId:string, appRegId: string) {
    let axios = await getAxios()
    const url = endpoints.appRegistrationReports(environmentId, appRegId)

    let response = await axios({
      method: 'get',
      url
    })

    let reports: Array<{id: string; name: string}> = response.data
    return reports.map(r => r.id)
  },

  // todo: this is not used today
  async linkReportsToAppReg(
    appRegId: string,
    reportIds: string[],
    profileId: string,
    environmentId: string
  ): Promise<void> {
    let axios = await getAxios()

    const url = endpoints.appRegistrationReports(environmentId, appRegId)
    let response = await axios({
      method: 'post',
      url,
      data: {
        reportIds: reportIds,
        profileId: profileId,
      },
    })

    const {success, errorMessage} = response.data
    if (!success) throw new Error(errorMessage)
  },

  
  async unlinkReportFromAppReg(
    environmentId: string,
    appRegId: string,
    reportId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.appRegistrationReports(environmentId, appRegId),
      data: {
        reportId: reportId,
      },
    })

    const {success, errorMessage} = response.data
    if (!success) throw new Error(errorMessage)
  },
}
