import { useQuery, UseQueryOptions } from 'react-query'
import { useRecoilValue } from 'recoil'
import { themeApi } from '../../api-interface'
import { environmentAtom } from '../../state'
import { AppTheme } from '../../models'

export const getThemeKey = 'get-theme'

export function useGetTheme(options: UseQueryOptions<AppTheme> = {}) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getThemeKey, environmentId],
    async () => {
      try {
        const themeOptions = await themeApi.getTheme(environmentId)
        return themeOptions
      } catch (error) {
        if (error.response?.status === 404) {
          return null // Return null if 404 error occurs
        }
        throw error // Re-throw other errors
      }
    },
    {
      ...options,
      enabled: !!environmentId
    }
  )
}
