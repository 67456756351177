import { useQuery } from 'react-query'
import { reportGroupApi } from '../../api-interface'

export const getReportGroupReportsKey = 'get-report-group-reports'

export function useGetReportGroupReports(environmentId: string, reportGroupId: string) {
  return useQuery(
    [getReportGroupReportsKey, reportGroupId],
    () => reportGroupApi.getReportGroupReports(environmentId, reportGroupId),
    {
      enabled: !!reportGroupId,
    }
  )
}
