import {useQuery} from 'react-query'
import {workspacesApi} from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

export const getWorkspacesKey = 'get-workspaces'

export function useGetWorkspaces(
) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getWorkspacesKey, environmentId],
    () => workspacesApi.getWorkspaces(environmentId),

  )
}
