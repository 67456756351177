import { useMutation, useQueryClient } from 'react-query'
import { ReportData } from '../../models'
import { environmentAtom } from '../../state'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'

type UpdatePaginatedReportContext = {
  previousReport: ReportData
}

const postPaginatedReportKey = 'post-paginated-report'

export function useUpdatePaginatedReport() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(reportApi.updatePaginatedReport, {
    async onMutate(newPaginatedReport): Promise<UpdatePaginatedReportContext> {
      await queryClient.cancelQueries([postPaginatedReportKey, environmentId])

      let previousPaginatedReport: any = queryClient.getQueryData([
        postPaginatedReportKey,
        environmentId,
      ])

      queryClient.setQueryData(
        [postPaginatedReportKey, environmentId],
        newPaginatedReport
      )

      // Return the context passed on onError
      return { previousReport: previousPaginatedReport }
    },
  })
}
