import { Stack } from '@mui/material'
import { useState } from 'react'

import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../../../state'
import { useGetAdminEnvironments } from '../../../../hooks/queries/useGetEnvironments'
import { Environment } from '../../../../models'
import { EnvironmentDialog } from './dialogs/environment'
import { RemoveEnvironmentDialog } from './dialogs/remove-environment'
import { ExistingItem } from '../../shared'

export function EnvironmentList() {
  const { data: environments } = useGetAdminEnvironments()

  const currentenvironmentId = useRecoilValue(environmentAtom)
  const [dialogState, setDialogState] = useState({
    editDialog: false,
    removeEnvironment: false,
  })
  const [environmentToEdit, setEnvironmentToEdit] = useState<Environment>(null)
  const handleDialogClose = (dialog: keyof typeof dialogState) => {
    setDialogState(prev => ({ ...prev, [dialog]: false }))
  }

  const handleDialogOpen = (dialog: keyof typeof dialogState) => {
    setDialogState(prev => ({ ...prev, [dialog]: true }))
  }
  const handleRemoveEnvironmentDialogOpen = (environment: Environment) => {
    setEnvironmentToEdit(environment)
    handleDialogOpen('removeEnvironment')
  }

  return (
    <>
      <Stack justifyContent='flex-start' direction={'column'}>
        {environments?.map(env => (
          <ExistingItem
            key={env.id}
            name={env.name}
            editButtonName='Edit'
            onEdit={() => {
              setEnvironmentToEdit(env)
              handleDialogOpen('editDialog')
            }}
            deleteButtonName={'Remove'}
            onDelete={
              env.id !== currentenvironmentId
                ? () => handleRemoveEnvironmentDialogOpen(env)
                : null
            }
            description={`${env.currentUserCount} User${
              env.currentUserCount > 1 ? 's' : ''
            }`}

            // onClick={() => {
            //   setCurrentEnvironment(env.id)
            //   updateDefaultEnvironment(env.id)
            // }}
          />
          // <EnvironmentItem
          //   key={env.id}
          //   environment={env}
          //   onRemoveEnvironment={() => {
          //     setEnvironmentToEdit(env)
          //     handleDialogOpen('removeEnvironment')
          //   }}
          //   onOpenEditEnvironment={() => {
          //     setEnvironmentToEdit(env)
          //     handleDialogOpen('editDialog')
          //   }}
          //   allowRemove={env.id !== currentenvironmentId}
          // />
        ))}
        {/*         
        <Paper
          key={env.id}
          elevation={2}
          sx={{
            p: 2,
            mb: 2,
            bgcolor: '#fff',
            borderRadius: 2,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{}}
          >
            <Box>
              <Typography variant='h6' sx={{ fontWeight: 500, mb: 0 }}>
                {env.name}
              </Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                Total Users: {env.currentUserCount}
              </Typography>
            </Box>
            {env.id !== currentenvironmentId && (
              <Button
                variant='text'
                onClick={() => {
                  onRemove(env)
                }}
              >
                Remove
              </Button>
            )}
          </Stack>
        </Paper> */}
        {/* ))} */}
      </Stack>
      <EnvironmentDialog
        open={dialogState.editDialog}
        onClose={() => handleDialogClose('editDialog')}
        environment={environmentToEdit}
        onSave={() => {
          handleDialogClose('editDialog')
        }}
      />
      <RemoveEnvironmentDialog
        open={dialogState.removeEnvironment}
        onClose={() => handleDialogClose('removeEnvironment')}
        environment={environmentToEdit}
        onRemove={() => {
          handleDialogClose('removeEnvironment')
        }}
      />
    </>
  )
}
