import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useCurrentUserInfo, useGetEnvironment} from './hooks'
import {getLicenseState} from './models'
import {routes} from './routes'

export const useLicenseRouting = (): void => {
  const history = useHistory()
  const {isFetched: userInfoFetched} = useCurrentUserInfo()
  const {data: environment, isFetched: isEnvironmentFetched} = useGetEnvironment()

  useEffect(() => {
    if (userInfoFetched && isEnvironmentFetched && environment) {
      const environmentState = getLicenseState(environment)

      // Ensure User is re-directed to Subscription Page if their Environment is no longer valid
      if (!environmentState.valid) {
        if (environmentState.status === 'SUSPENDED') {
          history.replace(routes.admin.subscription)
        } else if (environmentState.status === 'CANCELLED') {
          history.replace(routes.reports.index)
        }
      }

      // If User has an invalid Environment, re-direct them to Subscriptions page to handle Environment
      return history.listen(() => {
        if (history.action !== 'REPLACE' && !environmentState.valid) {
          if (environmentState.status === 'SUSPENDED') {
            history.replace(routes.admin.subscription)
          } else if (environmentState.status === 'CANCELLED') {
            history.replace(routes.reports.index)
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoFetched, isEnvironmentFetched, environment])
}
