// import { useRecoilValue } from 'recoil'
import { User } from '../../models'
// import { environmentAtom } from '../../state'
import { reportGroupApi } from '../../api-interface'
import { useMutation, useQueryClient } from 'react-query'
import { getDashboardKey, getReportGroupUsersKey } from '../queries'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

type AddReportGroupUserPayload = {
  reportGroupId: string
  userIds: string[]
  isKeyReport?: boolean
}

export function useAddReportGroupUsers() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddReportGroupUserPayload) =>
      reportGroupApi.addReportGroupUsers(
        environmentId,
        payload.reportGroupId,
        payload.userIds
      ),
    {
      async onMutate(payload: AddReportGroupUserPayload): Promise<User[]> {
        await queryClient.cancelQueries([
          getReportGroupUsersKey,
          payload.reportGroupId,
        ])

        const previousUsers: User[] = queryClient.getQueryData([
          getReportGroupUsersKey,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getReportGroupUsersKey, payload.reportGroupId],
          (old: User[]) => {
            const reportsBeingAdded = previousUsers?.filter((u: User) =>
              payload.userIds.includes(u.id)
            )

            if (!old || old.length === 0) {
              return reportsBeingAdded
            }

            const reportsToSet = [...old, ...reportsBeingAdded]

            return reportsToSet
          }
        )

        return previousUsers || []
      },
      onSettled(data, error, variables, context) {
        queryClient.invalidateQueries([ getReportGroupUsersKey, variables.reportGroupId ])
        queryClient.invalidateQueries([ getDashboardKey, environmentId ])
    
      },
    }
  )
}
