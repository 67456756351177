import React, { useEffect, useState } from 'react'

import { Box, TextField } from '@mui/material'
import CustomDialog from '../../../shared/dialog/dialog'
import { Environment } from '../../../../../models'
import {
  useCreateEnvironment,
  useUpdateEnvironment,
} from '../../../../../hooks'

type AddEnvironmentDialogProps = {
  open: boolean
  onClose: () => void
  environment?: Environment
  onSave: () => void
}

export const EnvironmentDialog: React.FC<AddEnvironmentDialogProps> = ({
  open,
  onClose,
  environment,
  onSave,
}) => {
  const [name, setName] = useState<string>(environment?.name || '')
  const { mutate: createEnvironment } = useCreateEnvironment()
  const { mutate: updateEnvironment } = useUpdateEnvironment()

  const isEdit = !!environment

  const title = isEdit ? 'Edit Environment' : 'Add Environment'
  const buttonTitle = isEdit ? 'Save' : 'Add'

  useEffect(() => {
    if (environment) {
      setName(environment.name)
    }
  }, [environment])
  const handleSave = () => {
    if (isEdit) {
      // Update environment logic here
      updateEnvironment({
        environmentId: environment?.id,
        environmentName: name,
      })
    } else {
      // Create new environment logic here
      createEnvironment({
        environmentName: name,
      })
    }
    onSave()
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={title}
      primaryButton
      primaryButtonProps={{
        children: buttonTitle,
        onClick: handleSave,
      }}
    >
      <Box sx={{ p: 1 }}>
        <TextField
          fullWidth
          size='small'
          label='Environment Name'
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </Box>
    </CustomDialog>
  )
}
