import { useQuery } from 'react-query'
import { workspacesApi } from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

export const getProfileWorkspacesKey = 'get-profile-workspaces'

export function useGetProfileWorkspaces(
  appRegNodeId: string,

) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery([getProfileWorkspacesKey, environmentId], () =>
    workspacesApi.getWorkspacesForAppReg(
      appRegNodeId,
      environmentId
    )
  )
}
