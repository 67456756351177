
import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {themeApi} from '../../api-interface'
import {AppTheme} from '../../models'
import {environmentAtom} from '../../state'
import {getThemeKey} from '../queries'

type CreateThemeContext = {
  previousTheme: AppTheme
}

export function useCreateTheme() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation((theme:AppTheme)=>themeApi.createTheme(environmentId, theme), {
    async onMutate(newTheme): Promise<CreateThemeContext> {
      await queryClient.cancelQueries([getThemeKey, environmentId])

      let previousTheme: AppTheme = queryClient.getQueryData([
        getThemeKey,
        environmentId,
      ])

      queryClient.setQueryData([getThemeKey, environmentId], () =>
        newTheme
      )

      // Return the context passed on onError
      return {previousTheme}
    },
    onError(_err, _newTheme, context: CreateThemeContext) {
      queryClient.setQueryData([getThemeKey, environmentId], context.previousTheme)
    },
    onSettled() {
      queryClient.invalidateQueries([getThemeKey, environmentId])
      
    },
  })
}
