import { useState, FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Workspace } from '../../../../models'
import { TabLayoutModal } from '../../shared'
import { WorkspaceForm } from './workspace-form'

import { useDeleteWorkspace } from '../../../../hooks'
import { LinkedUsers, LinkUsersMenu } from '.'
import { ExistingItem } from './existing-workspace-item'
import DeleteWorkspaceDialog from '../users/dialogs/delete-workspace-dialog'

type ExistingWorkspaceProps = {
  workspace: Workspace
  handleShareWithEnvironmentdUsersChange: (value: boolean) => void
  isSelected?: boolean
}

export const ExistingWorkspace: FC<ExistingWorkspaceProps> = (
  props: ExistingWorkspaceProps
) => {
  const { workspace, handleShareWithEnvironmentdUsersChange, isSelected } = props

  const [sharedWithEnvironment, setSharedWithEnvironment] = useState(
    workspace?.sharedWithEnvironment
  )
  const [openDeleteWorkspaceDialog, setOpenDeleteWorkspaceDialog] =
    useState(false)

  const [isOpen, setIsOpen] = useState(isSelected)

  const { mutateAsync: deleteWorkspace, isLoading: isDeletingWorkspace } =
    useDeleteWorkspace()
  const [editModalOpen, setEditModalOpen] = useState(false)

  const handleShareClicked = (value: boolean) => {
    setSharedWithEnvironment(value)
    handleShareWithEnvironmentdUsersChange(value)
  }

  return (
    <>
      <DeleteWorkspaceDialog
        open={openDeleteWorkspaceDialog}
        onClose={() => setOpenDeleteWorkspaceDialog(false)}
        workspace={workspace}
        handleDelete={() => deleteWorkspace(workspace.id)}
        isDeleting={isDeletingWorkspace}
      />
      <ExistingItem
        key={`${workspace.id}_${uuidv4()}`}
        name={workspace.name}
        onDelete={() => setOpenDeleteWorkspaceDialog(true)}
        onEdit={() => setEditModalOpen(true)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <>
          <LinkUsersMenu
            workspace={workspace}
            shareWithEnvironmentdUsers={sharedWithEnvironment}
            onShareWithEnvironmentdUsersChange={handleShareClicked}
          />
          {!sharedWithEnvironment && <LinkedUsers workspace={workspace} />}
        </>
      </ExistingItem>
      <TabLayoutModal
        title='Edit Workspace'
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      >
        <WorkspaceForm
          onClose={() => setEditModalOpen(false)}
          workspace={workspace}
          editMode={true}
        />
      </TabLayoutModal>
    </>
  )
}
