import {
  Stack,
  Box,
  IconButton,
  Tooltip,
  Paper,
  alpha,
  Popover,
  Icon,
  TextField,
  Divider,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { FolderItem } from '../../../../models'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { FolderItemTypeToIcon } from '../util'
import { useAddToFolder } from '../../../../hooks/useAddToFolder'
import { useFavorites } from '../../../../hooks/useFavorites'

export function ReportTile({
  title,
  description,
  type,
  imageUrl,
  id,
}: ReportTileProps) {
  const theme = useTheme()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const item: FolderItem = { id, type: type || 'report', name: title }
  const { isFavorite, isLoadingFavorites, toggleFavorite } = useFavorites(item)

  const {
    addToFolder,
    availableFolders,

    error,
  } = useAddToFolder(item)

  const handleFolderClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handleFolderClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined

  const handleAddToFolder = (folderId: string, newFolderName?: string) =>
    addToFolder(folderId, newFolderName).catch(console.error)

  const bgColor = theme.palette.secondary.main

  const handleClick = () => {
    history.push(`/reports/${id}`)
  }

  function ReportTileCircle() {
    return (
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: alpha(bgColor, 0.2),
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minWidth: '80px',
          width: '80px',
          height: '80px',
        }}
      >
        <FolderItemTypeToIcon type={type} />
      </Box>
    )
  }

  function AddToFolderOptions() {
    const [isAddingFolder, setIsAddingFolder] = useState(false)
    const [newFolderName, setNewFolderName] = useState('')

    return (
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
        spacing={1}
        sx={{ p: 1, maxHeight: '280px', overflowY: 'auto', minWidth: '240px' }}
        onClick={e => e.stopPropagation()}
      >
        <Typography variant='body2' fontWeight='bold' sx={{ pl: 1 }}>
          Add to Folder
        </Typography>
        <Divider sx={{ width: '100%' }} />
        <Stack
          onClick={e => {
            e.stopPropagation()
            setIsAddingFolder(true)
          }}
          direction='row'
          justifyContent='flex-start'
          sx={{
            cursor: 'pointer',
            borderRadius: '8px',
            width: '100%',
            p: 1,
            '&:hover': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
            },
            border: theme =>
              !isAddingFolder
                ? `1px dashed ${theme.palette.primary.main}`
                : 'none',
          }}
        >
          {isAddingFolder ? (
            <TextField
              autoFocus
              label='Folder Name'
              variant='standard'
              size='small'
              sx={{ width: '100%' }}
              value={newFolderName}
              onChange={e => setNewFolderName(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsAddingFolder(false)
                  handleAddToFolder('', newFolderName)
                  handleFolderClose()
                }
              }}
              InputProps={{
                endAdornment: (
                  <Typography
                    onClick={() => {
                      setIsAddingFolder(false)
                      handleAddToFolder('', newFolderName)
                      handleFolderClose()
                    }}
                    sx={{
                      fontSize: '14px',
                      mb: 1,
                      cursor: 'pointer',
                      color: theme => theme.palette.primary.main,
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Confirm
                  </Typography>
                ),
              }}
            />
          ) : (
            <>
              <Typography fontStyle='italic' sx={{ cursor: 'pointer' }}>
                Create New
              </Typography>
              <Icon>
                <AddOutlinedIcon />
              </Icon>
            </>
          )}
        </Stack>

        {availableFolders?.map((folder, index) => (
          <Box
            key={index}
            onClick={e => {
              e.stopPropagation()
              handleAddToFolder(folder.id)
              handleFolderClose()
            }}
            sx={{
              cursor: 'pointer',
              width: '100%',
              borderRadius: '8px',
              p: 1,
              '&:hover': {
                backgroundColor: theme =>
                  alpha(theme.palette.primary.main, 0.2),
              },
            }}
          >
            <Typography>{folder.name}</Typography>
          </Box>
        ))}
        {error && <Typography color='error'>{error}</Typography>}
      </Stack>
    )
  }
  return (
    <Paper
      onClick={e => {
        e.stopPropagation()
        handleClick()
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: theme.palette.grey[100],
        p: 2,
        alignItems: 'flex-start',
        height: '100%',
        pr: 1,
        '&:hover': {
          '& .reportTileButtons': {
            visibility: 'visible',
            opacity: 1,
          },
          '& .overlay': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          },
        },
      }}
    >
      <Box
        className='overlay'
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          zIndex: 1,
          transition: 'background-color 0.3s',
          borderRadius: '8px',
        }}
      />
      <Stack
        direction='row'
        className='reportTileButtons'
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          p: '4px',
          zIndex: 2,
          backgroundColor: alpha(theme.palette.grey[100], 0.9),
          visibility: 'hidden',
          opacity: 0,
          borderRadius: '0px 0px 0px 8px',
          transition: 'opacity 0.3s',
        }}
      >
        <Tooltip title={isFavorite ? 'Unfavorite' : 'Favorite'} placement='top'>
          {isLoadingFavorites ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
              }}
            >
              <CircularProgress size={20} sx={{ width: '20px' }} />
            </Box>
          ) : (
            <IconButton
              aria-label='favorite'
              size='small'
              onClick={e => {
                e.stopPropagation()
                toggleFavorite()
              }}
            >
              {isFavorite ? (
                <FavoriteIcon sx={{ fontSize: '20px' }} />
              ) : (
                <FavoriteBorderIcon sx={{ fontSize: '20px' }} />
              )}
            </IconButton>
          )}
        </Tooltip>

        <Tooltip title='Add To Folder' placement='top'>
          <IconButton
            aria-label='add-to-folder'
            size='small'
            onClick={e => {
              e.stopPropagation()
              handleFolderClick(e)
            }}
          >
            <FolderOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </Tooltip>

        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleFolderClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClick={e => e.stopPropagation()}
          slotProps={{ paper: { sx: { borderRadius: '8px' } } }}
        >
          <AddToFolderOptions />
        </Popover>
      </Stack>

      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={2}
      >
        <ReportTileCircle />
        <Stack direction='column' justifyContent='center'>
          <Typography
            gutterBottom
            variant='body2'
            fontWeight='bold'
            component='h2'
            display='inline'
          >
            {title}
          </Typography>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography variant='body2' color='textSecondary' component='p'>
              {description}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}

type ReportTileProps = {
  title: string
  description: string
  id: string
  type?:
    | 'report'
    | 'paginated-report'
    | 'dataset'
    | 'custom-paginated'
    | 'bookmark'
  imageUrl?: string
}
