import {useMutation, useQueryClient} from 'react-query'
import {appRegApi} from '../../api-interface'
import {getAppRegistrationsKey} from '../queries'
import {AppRegistrationState} from '../../models'
import {useRecoilValue} from 'recoil'
import {environmentAtom} from '../../state'

type DeleteAppRegContext = {
  previousAppRegs: AppRegistrationState[]
}

export function useDeleteAppReg() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (appRegId: string) =>
      appRegApi.deleteAppRegistration(environmentId, appRegId),
    
    {
    async onMutate(newAppRegistrationId: string): Promise<DeleteAppRegContext> {
      await queryClient.cancelQueries([getAppRegistrationsKey, environmentId])

      let previousAppRegs: AppRegistrationState[] = queryClient.getQueryData([
        getAppRegistrationsKey,
        environmentId,
      ])
      queryClient.setQueryData(
        [getAppRegistrationsKey, environmentId],
        (old: AppRegistrationState[]) =>
          old.filter(appReg => appReg.id !== newAppRegistrationId)
      )

      return {previousAppRegs}
    },
    onError(_err, _newAppRegId, context: DeleteAppRegContext) {
      queryClient.setQueryData(
        [getAppRegistrationsKey, environmentId],
        context.previousAppRegs
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getAppRegistrationsKey, environmentId])
    },
  })
}
