import { useQuery } from 'react-query'
import { environmentApi } from '../../api-interface'


export const getAdminEnvironments = 'get-environments'

export function useGetAdminEnvironments() {

  return useQuery(
    [getAdminEnvironments],
    () => environmentApi.getAdminEnvironments(),
    
  )
}
