import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {Environment, NewUser, User} from '../../models'
import {getCurrentUserEmail} from '../../components/auth/util'
import {environmentAtom} from '../../state'
import {getEnvironmentQueryKey, getOtherUsersOnEnvironmentKey} from '../queries'

type CreateUserContext = {
  previousUsers: User[]
}

type CreateUsersPayload = {
  users: NewUser[]
  reportIds?: string[]
}

export function useCreateUser() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: CreateUsersPayload) =>
      userApi.createUsers(payload.users, environmentId, payload.reportIds || []),
    {
      async onMutate(newUser: CreateUsersPayload): Promise<CreateUserContext> {
        await queryClient.cancelQueries([getOtherUsersOnEnvironmentKey, environmentId])

        let currentUserEmail = await getCurrentUserEmail()
        let previousUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnEnvironmentKey,
          environmentId,
        ])

        const currentDate = new Date().toISOString()

        let newUsers =
          newUser.users?.map((user: NewUser) => ({
            ...user,
            id: user.email,
            adminEmail: currentUserEmail,
            createdDate: currentDate,
          })) || []

        queryClient.setQueryData(
          [getOtherUsersOnEnvironmentKey, environmentId],
          (old: User[]) => [
            ...old,
            ...newUsers.filter((user: NewUser) => {
              return !old?.some((u: User) => u.email === user.email)
            }),
          ]
        )

        queryClient.setQueryData(
          [getEnvironmentQueryKey, environmentId],
          (old: Environment) => ({
            ...old,
            currentUserCount: old?.currentUserCount + 1 || 1,
          })
        )

        return {previousUsers}
      },
      onError(_err, _newUser, context: CreateUserContext) {
        queryClient.setQueryData(
          [getOtherUsersOnEnvironmentKey, environmentId],
          context.previousUsers
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getEnvironmentQueryKey, environmentId])
        queryClient.invalidateQueries([getOtherUsersOnEnvironmentKey, environmentId])
      },
    }
  )
}
