import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getReportsByUserKey = 'get-reports-by-user'

export function useGetReportsByUser(userId: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getReportsByUserKey, userId],
    () => reportApi.getReportsByUser(environmentId, userId),
    {
      enabled: !!environmentId,
    }
  )
}
