import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { environmentAtom } from '../../state'

export const getFavoritesFolder = 'get-favorites-folder'

export function useGetFavorites() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getFavoritesFolder, environmentId],
    () => folderApi.getFavoritesFolder(environmentId),
    {
      enabled: !!environmentId,
    }
  )
}
