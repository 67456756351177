import { Bookmark, NewBookmark } from '../models'
import { parseBoolean } from '../utils/parseBoolean'
import { getAxios, endpoints } from './util'

type BookmarkResponse = {
  environmentBookmarks: Bookmark[]
  sharedBookmarks: Bookmark[]
  ownedBookmarks: Bookmark[]
}

export const bookmarkApi = {
  async getBookmarks(
    environmentId: string,
    reportId: string
  ): Promise<BookmarkResponse> {
    let axios = await getAxios()

    const url = endpoints.reportBookmarks(environmentId, reportId)

    const response = await axios({
      method: 'get',
      url: url,
    })

    //const { environmentBookmarks, ownedBookmarks, sharedBookmarks} = response.data
    // Combine all bookmark arrays into one array
    //const allBookmarks = [...environmentBookmarks, ...ownedBookmarks, ...sharedBookmarks];

    return response.data
  },

  async createBookmark(bookmark: NewBookmark) {
    let axios = await getAxios()

    let {reportId, environmentNodeId, ...newBookmark} = bookmark
    const response = await axios({
      method: 'post',
      url: endpoints.reportBookmarks(bookmark.environmentNodeId, bookmark.reportId),
      data: newBookmark
    })

    return response.data
  },

  async deleteBookmark(environmentId:string, reportId:string, bookmarkId: string) {

    let axios = await getAxios()
    const url = endpoints.reportBookmark(environmentId, reportId, bookmarkId)
    await axios({
      method: 'delete',
      url: url,
    })
    // TODO: perhaps we need to return the response or at least look at the response.
    return bookmarkId
  },

  async updateBookmark(bookmark: NewBookmark) {
    let axios = await getAxios()
    const {reportId, environmentNodeId, ...newBookmark} = bookmark
    await axios({
      method: 'patch',
      url: endpoints.reportBookmark(environmentNodeId, reportId, bookmark.id),
      data: newBookmark,
    })
  },

  

  // async addBookmarkViewers(
  //   bookmarkId: string,
  //   viewerIds: string[],
  //   environmentId: string
  // ) {
  //   let axios = await getAxios()

  //   await axios({
  //     method: 'post',
  //     url: endpoints.bookmarkViewers,
  //     data: { bookmarkId: bookmarkId, viewers: viewerIds },
  //   })
  // },

  // async removeBookmarkViewer(
  //   bookmarkId: string,
  //   viewerId: string,
  //   environmentId: string
  // ) {
  //   let axios = await getAxios()

  //   await axios({
  //     method: 'delete',
  //     url: endpoints.bookmarkViewers,
  //     data: { bookmarkId: bookmarkId, viewer: viewerId },
  //   })
  // },

  async addDefaultBookmark(environmentId:string, reportId:string, bookmarkId: string) {
    let axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.reportBookmarkDefault(environmentId, reportId, bookmarkId),
      
    })
  },

  async removeBookmarkMember(environmentId:string, reportId:string, bookmarkId: string) {
    let axios = await getAxios()

    const url = endpoints.reportBookmarkDefault(environmentId, reportId, bookmarkId)
    await axios({
      method: 'delete',
      url
      
    })
  },

  async getBookmarkMember(environmentId:string, reportId:string, bookmarkId:string): Promise<boolean> {
    let axios = await getAxios()
    const url = endpoints.reportBookmarkDefault(environmentId, reportId, bookmarkId)

    const response = await axios({
      method: 'get',
      url: url,
    })

    return parseBoolean(response?.data?.payload)
  },
}
