import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {paypalApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getPaypalSubscriptionPlanKey = 'get-paypal-subscription-plan'

export function useGetPaypalSubscriptionPlan() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getPaypalSubscriptionPlanKey, environmentId],
    paypalApi.getPaypalSubscriptionPlan
  )
}
