import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder, FolderItem } from '../../models'
import { environmentAtom } from '../../state'
import { getDashboardKey, getFoldersKey } from '../queries'
import { getFavoritesFolder } from '../queries/useGetFavorites'

type CreateFolderContext = {
  previousFolders: Folder[]
}

type AddItemToFavoritesPayload = {
  item: FolderItem
}

export function useAddItemsToFavorites() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: AddItemToFavoritesPayload) =>
      folderApi.addItemToFavoritesFolder({ ...payload, environmentId }),

    {
      async onMutate(
        newFolder: AddItemToFavoritesPayload
      ): Promise<CreateFolderContext> {
        await queryClient.cancelQueries([getFoldersKey, environmentId])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getFoldersKey, environmentId],
          (old: Folder[]) => {
            if (old) {
              let oldFavorites = old.find(folder => folder.isFavorites)
              if (!oldFavorites) {
                oldFavorites = {
                  id: 'favorites',
                  name: 'Favorites',
                  isFavorites: true,
                  items: [],
                }
              }
              const newFavorites = {
                ...oldFavorites,
                items: [...oldFavorites.items, newFolder.item],
              }
              return [
                ...old.filter(folder => !folder.isFavorites),
                newFavorites,
              ]
            }
          }
        )

        return { previousFolders }
      },

      onSettled: () => {
        queryClient.invalidateQueries([getFavoritesFolder, environmentId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
