import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { userApi } from '../../api-interface'
import { NewUser, User } from '../../models'
import { environmentAtom } from '../../state'
import { getOtherUsersOnEnvironmentKey } from '../queries'

type UpdateUserContext = {
  previousUsers: User[]
}

type UpdateUserPayload = {
  user: NewUser
}

export function useUpdateUser() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: UpdateUserPayload) => userApi.updateUser(payload.user, environmentId),
    {
      async onMutate(payload): Promise<UpdateUserContext> {
        await queryClient.cancelQueries([getOtherUsersOnEnvironmentKey, environmentId])

        let previousUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnEnvironmentKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getOtherUsersOnEnvironmentKey, environmentId],
          (old: User[]) =>
            old?.map(user => {
              if (user.email === payload.user.email) {
                return payload.user
              }
              return user
            })
        )

        return { previousUsers }
      },
      onError(_err, _updatedUser, context: UpdateUserContext) {
        queryClient.setQueryData(
          [getOtherUsersOnEnvironmentKey, environmentId],
          context.previousUsers
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getOtherUsersOnEnvironmentKey, environmentId])
      },
    }
  )
}
