import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Theme,
  Typography,
  Box,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ExistingItem } from '../../shared'
import { ApiReport, REPORT_TYPES } from '../../../../models'
import { LinkedViewers } from './linked-items'
import {
  useDeleteReport,
  useAddDefaultReport,
  useRemoveDefaultReport,
  useGetAppSettings,
} from '../../../../hooks'
import { useState } from 'react'
import { LinkViewersMenu } from './link-viewers-menu'
import { Home } from '@mui/icons-material'
import { LinkGroupsMenu } from './link-groups-menu'
import { AddReportForm } from './add-report-form/add-report-form'
import { FolderItemTypeToIcon } from '../../../reports/report-dashboard/util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(0),
    },
    linkedViewersTitle: {
      marginRight: theme.spacing(1),
    },
    addMenu: {
      minWidth: '240px',
    },
    confirmLinkButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: theme.spacing(1),
      paddingBottom: 0,
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export function ExistingReport(props: ExistingReportProps) {
  const { report, isDisabled } = props
  const classes = useStyles()

  const { data: appSettings } = useGetAppSettings()
  const { mutate: deleteReport, isLoading: isDeleting } = useDeleteReport()
  const { mutateAsync: addDefault, isLoading: addDefaultLoading } =
    useAddDefaultReport()
  const { mutateAsync: removeDefault, isLoading: removeDefaultLoading } =
    useRemoveDefaultReport()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editState, setEditState] = useState<'connection' | 'details'>(
    'connection'
  )

  let icon
  if (report?.showOnHome) icon = <Home style={{ fontSize: '16px' }} />

  function handleDelete() {
    deleteReport(report.id)
  }

  function DefaultButton() {
    return (
      <FormControlLabel
        control={
          addDefaultLoading || removeDefaultLoading ? (
            <Box
              sx={{
                height: '36px',
                width: '36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size='16px' thickness={6} />
            </Box>
          ) : (
            <Checkbox
              checked={report.default}
              onClick={event => {
                event.stopPropagation()
                let data = {
                  reportId: report.id,
                }
                report.default = !report.default
                report.default ? addDefault(data) : removeDefault(data)
              }}
            />
          )
        }
        label={
          <Typography
            sx={{ mx: 0.5 }}
            color={report.default ? 'primary' : 'gray'}
          >
            Default
          </Typography>
        }
      />
    )
  }

  return (
    <>
      <ExistingItem
        key={report.id + report.name}
        secondaryLabel={report.description}
        name={report.name}
        loading={isDeleting}
        onDelete={isDisabled ? null : handleDelete}
        onEdit={
          isDisabled
            ? undefined
            : () => {
                setEditState('details')
                setIsModalOpen(true)
              }
        }
        editButtonName='Edit Details'
        thirdButtonName='Edit Connection'
        //if report type is not custom_paginated then show third button
        onThirdButton={
          report.type !== REPORT_TYPES.CUSTOM_PAGINATED
            ? () => {
                setEditState('connection')
                setIsModalOpen(true)
              }
            : undefined
        }
        nameIcon={<FolderItemTypeToIcon type={report.type} />}
        icon={icon}
      >
        {!isDisabled && (
          <>
            <Typography className={classes.description} variant='body2'>
              {report.description}
            </Typography>
            <DefaultButton />
            <Typography
              variant='body1'
              display='inline'
              className={classes.linkedViewersTitle}
            >
              Viewers
            </Typography>

            <LinkViewersMenu report={report} />
            {appSettings?.groupsEnabled && <LinkGroupsMenu report={report} />}

            <LinkedViewers report={report} />
          </>
        )}
      </ExistingItem>

      {isModalOpen && (
        <AddReportForm
          onClose={() => setIsModalOpen(false)}
          onSave={() => setIsModalOpen(false)}
          isEditingConnection={editState === 'connection'}
          isEditingDetails={editState === 'details'}
          reportToEdit={report}
          open={isModalOpen}
        />
      )}
    </>
  )
}

export type ExistingReportProps = {
  report: ApiReport
  isDisabled?: boolean
}

export type RelationshipQuery = {
  sources: string
  relationships: string
  targetTypes: string
  direction: string
}
