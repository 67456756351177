import { CreateWorkspacePayload, PostDaxQueryPayload } from '../hooks'
import {
  CustomReportItemsInfo,
  GetCustomReportItemsParams,
  WorkspaceDatasetInfo,
  WorkspaceInfo,
  WorkspaceReportInfo,
} from '../models/workspaces'
import { getAxios, endpoints, buildQueryUrl } from './util'

export const workspacesApi = {
  async getWorkspaceReports(
    environmentId: string,
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    workspaceId: string,
    workspaceName?: string
  ): Promise<WorkspaceReportInfo[]> {
    if (!appRegNodeId || !workspaceId) {
      return []
    }

    const axios = await getAxios()

    const url = buildQueryUrl(
      endpoints.pbiWorkspaceReports(environmentId, workspaceId),
      {
        appRegNodeId,
        workspaceId,
        workspaceName,
        appClientId,
        appRegTenantId,
      }
    )
    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async getPerspectives(
    params: GetCustomReportItemsParams,
    environmentId: string
  ): Promise<string[]> {
    const { reportId, appRegNodeId, datasetId, workspaceId } = params

    if (!appRegNodeId || !datasetId || !workspaceId) {
      return []
    }

    const axios = await getAxios()

    const url = buildQueryUrl(endpoints.reportPerspectives(environmentId), {
      appRegNodeId,
      datasetId,
      workspaceId,
      reportId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async getCapacities(
    environmentId: string,
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    profileId: string
  ): Promise<any> {
    if (
      !environmentId ||
      !appRegNodeId ||
      !appClientId ||
      !appRegTenantId ||
      !profileId
    ) {
      return []
    }

    const axios = await getAxios()

    const url = buildQueryUrl(
      endpoints.appRegistrationCapacities(environmentId, appRegNodeId),
      {
        appRegNodeId,
        appClientId,
        appRegTenantId,
        profileId,
        environmentId,
      }
    )

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async editWorkspace(params: {
    workspaceId: string
    name: string
    environmentId: string
    description?: string
    sharedWithEnvironment?: boolean
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.workspace(params.environmentId, params.workspaceId),
      data: params,
    })
  },

  async deleteWorkspace(
    workspaceId: string,
    environmentId: string
  ): Promise<any> {
    if (!workspaceId || !environmentId) {
      return null
    }

    const axios = await getAxios()

    const response = await axios({
      method: 'delete',
      url: endpoints.workspace(environmentId, workspaceId),
    })

    return response
  },

  async getWorkspaceUsers(
    workspaceId: string,
    environmentId: string
  ): Promise<any> {
    if (!workspaceId || !environmentId) {
      return []
    }

    const axios = await getAxios()

    const url = endpoints.workspaceUsers(environmentId, workspaceId)

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async addWorkspaceUsers(
    workspaceId: string,
    userIds: string[],
    environmentId: string
  ): Promise<any> {
    if (!workspaceId || !environmentId) {
      return []
    }

    const axios = await getAxios()

    const data = {
      workspaceId,
      environmentId,
      userIds,
    }

    const response = await axios({
      method: 'post',
      url: endpoints.workspaceUsers(environmentId, workspaceId),
      data,
    })

    return response?.data
  },

  async removeWorkspaceUser(
    workspaceId: string,
    userId: string,
    environmentId: string
  ): Promise<any> {
    if (!workspaceId || !userId || !environmentId) {
      return null
    }

    const axios = await getAxios()

    const url = endpoints.workspaceUser(environmentId, workspaceId, userId)

    const response = await axios({
      method: 'delete',
      url,
    })

    return response
  },

  // Update to add environmentId
  async getWorkspaceDatasets(
    environmentId: string,
    appRegNodeId: string,
    profileId: string,
    workspaceId: string
  ): Promise<WorkspaceDatasetInfo[]> {
    if (!appRegNodeId || !workspaceId) {
      return []
    }

    const axios = await getAxios()

    const url = buildQueryUrl(
      endpoints.pbiWorkspaceDatasets(environmentId, workspaceId),
      {
        appRegNodeId,
        profileId,
      }
    )

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async postDaxQuery(params: PostDaxQueryPayload, environmentId: string) {
    const { appRegNodeId, datasetId, groupId, reportId, query } = params

    const axios = await getAxios()

    const response = await axios({
      method: 'post',
      data: {
        appRegNodeId,
        datasetId,
        workspaceId: groupId,
        reportId,
        query,
        environmentId,
      },
      url: endpoints.reportQuery(environmentId, reportId),
    })

    return response.data.value
  },

  async getCustomReportItems(
    environmentId: string,
    reportId: string
  ): Promise<CustomReportItemsInfo> {
    const axios = await getAxios()

    const url = endpoints.reportItems(environmentId, reportId)

    try {
      const response = await axios({
        method: 'get',
        url,
      })

      return response?.data
    } catch (e) {
      return null
    }
  },

  async createWorkspace(
    payload: CreateWorkspacePayload,
    environmentId: string
  ) {
    const axios = await getAxios()
    const response = await axios.post(
      endpoints.workspaces(environmentId),
      payload
    )
    return response.data
  },

  async getWorkspaces(environmentId: string): Promise<WorkspaceInfo[]> {
    if (!environmentId) {
      return []
    }
    const axios = await getAxios()

    const url = endpoints.workspaces(environmentId)

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async getAdminWorkspaces(environmentId: string): Promise<WorkspaceInfo[]> {
    if (!environmentId) {
      return []
    }
    const axios = await getAxios()

    const url = endpoints.adminWorkspaces(environmentId)

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async getWorkspacesForAppReg(
    appRegNodeId: string,
    environmentId: string
  ): Promise<WorkspaceInfo[]> {
    if (!appRegNodeId) {
      return []
    }
    const axios = await getAxios()

    const url = endpoints.appRegistrationWorkspaces(environmentId, appRegNodeId)

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async getWorkspaceById(
    environmentId: string,
    appRegNodeId: string,
    workspaceId: string
  ): Promise<WorkspaceInfo> {
    if (!appRegNodeId || !workspaceId) {
      return null
    }

    const axios = await getAxios()

    const url = buildQueryUrl(endpoints.workspace(environmentId, workspaceId), {
      appRegNodeId,
      workspaceId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.[0]
  },
}
