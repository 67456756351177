import {
  Stack,
  Box,
  Paper,
  alpha,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ReportGroupSVG } from '../../../../assets/report-group.svg'
import { useRoutes } from '../../../../hooks'

export function ReportGroupTile({
  title,
  description,
  imageUrl,
  id,
}: ReportGroupTileProps) {
  const theme = useTheme()
  const history = useHistory()
  const routes = useRoutes()
  const bgColor = theme.palette.primary.main

  function ReportGroupTileCircle() {
    return (
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: alpha(bgColor, 0.2),
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minWidth: '80px',
          width: '80px',
          height: '80px',
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt=''
            width='100%'
            height='100%'
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
        ) : (
          <SvgIcon
            sx={{ width: '32px', height: '32px' }}
            component={ReportGroupSVG}
          />
        )}
      </Box>
    )
  }

  return (
    <Paper
      onClick={() => history.push(routes.reportGroups.group({ id: id }))}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: theme.palette.grey[100],
        p: 2,
        '&:hover': {
          '& .overlay': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          },
        },
        alignItems: 'flex-start',
        height: '100%',
        pr: 1,
      }}
    >
      <Box
        className='overlay'
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          zIndex: 1,
          transition: 'background-color 0.3s',
          borderRadius: '8px',
        }}
      />
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={2}
      >
        <ReportGroupTileCircle />
        <Stack direction='column' justifyContent='center'>
          <Typography
            gutterBottom
            variant='body2'
            fontWeight='bold'
            component='h2'
            display='inline'
          >
            {title}
          </Typography>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography variant='body2' color='textSecondary' component='p'>
              {description}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}

type ReportGroupTileProps = {
  title: string
  description: string
  id: string
  imageUrl?: string
}
