import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {Group} from '../../models'
import {environmentAtom} from '../../state'
import {getDashboardKey, getGroupsKey} from '../queries'

type DeleteGroupContext = {
  previousGroups: Group[]
}

export function useDeleteGroup() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (groupId: string) => groupApi.deleteGroup(groupId, environmentId),
    {
      async onMutate(groupId: string): Promise<DeleteGroupContext> {
        await queryClient.cancelQueries([getGroupsKey, environmentId])

        let previousGroups: Group[] = queryClient.getQueryData([
          getGroupsKey,
          environmentId,
        ])

        queryClient.setQueryData([getGroupsKey, environmentId], (old: Group[]) =>
          old.filter((group: Group) => group.id !== groupId)
        )

        return {previousGroups}
      },
      onError(_err, _deletedGroup, context: DeleteGroupContext) {
        queryClient.setQueryData(
          [getGroupsKey, environmentId],
          context.previousGroups
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getGroupsKey, environmentId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])

      },
    }
  )
}
