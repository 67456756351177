import { AppLicense } from '../models'
import {getAxios, endpoints} from './util'

export const licenseApi = {
  async getLicense(): Promise<AppLicense> {
    const axios = await getAxios()

    const url = endpoints.license
    // const url = getUrl(endpoints.environment, {environmentId: environmentId})

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },
  async updateLicense(licenseSku:'basic'|'enterprise'): Promise<AppLicense> {
    const axios = await getAxios()

    const url = endpoints.license
    // const url = getUrl(endpoints.environment, {environmentId: environmentId})

    const response = await axios({
      method: 'post',
      url,
      data: {licenseSku},
    })

    return response?.data
  }
    

}
