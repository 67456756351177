import { AxiosError } from 'axios'
import {
  ReportToCreate,
  ReportConnectionStatus,
  ApiReport,
  GetReportViewersResponse,
  ApiGroupReport,
  ReportSettingsToUpdate,
  ReportPage,
  NewReportEmbedRequest,
  PaginatedReportToCreate,
  ReportToUpdate,
  HomepageCards,
  Folder,
  ReportGroupWithDetails,
  GetReportResponse,
} from '../models'
import { getAxios, endpoints, buildQueryUrl, formatReports } from './util'

export const reportApi = {
  async createReport(report: ReportToCreate) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.reports(report.environmentNodeId),
      data: report,
    })

    return response.data
  },

  // async getNavMenuItems(environmentId: string): Promise<GetNavMenuItemsType> {
  //   let axios = await getAxios()
  //   //TODO: Fix this, maybe combine the dashboard
  //   // let response = await axios({
  //   //   method: 'get',
  //   //   url: endpoints.reportNavMenuItems(environmentId),
  //   // })

  //   return null //response.data
  // },

  async embedAccess(request: NewReportEmbedRequest) {
    let axios = await getAxios()
    let response = await axios({
      method: 'post',
      url: endpoints.reportPreview(request.environmentId),

      data: {
        pbiReportId: request.pbiReportId,
        appRegNodeId: request.appRegNodeId,
        appClientId: request.appClientId,
        appRegTenantId: request.appRegTenantId,
        pbiWorkspaceId: request.workspaceId,
        profileId: request.profileId,
        rlsRoles: request.rlsRoles,
        username: request.username,
      },
    })

    return response?.data
  },

  async createPaginatedReport(report: PaginatedReportToCreate) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.reports(report.environmentId),
      data: report,
    })

    return response.data
  },

  async updatePaginatedReport(report: PaginatedReportToCreate) {
    let axios = await getAxios()

    const response = await axios({
      method: 'patch',
      url: endpoints.report(report.environmentId, report.id),
      data: report,
    })

    return response.data
  },

  async getReportPages(
    environmentId: string,
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    reportId: string,
    groupId: string
  ): Promise<ReportPage[]> {
    if (!environmentId || !appRegNodeId || !reportId || !groupId) return []
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: buildQueryUrl(endpoints.reportPages(environmentId, reportId), {
        environmentId,
        appRegNodeId,
        appClientId,
        appRegTenantId,
        reportId,
        groupId,
      }),
    })

    return response.data || []
  },

  async updateReportSettings(reportSettings: ReportSettingsToUpdate) {
    const {
      id,
      environmentNodeId,
      displayName,
      filterPaneEnabled,
      showPageNavigation,
    } = reportSettings
    let axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.reportSettings(environmentNodeId, id),
      data: {
        id,
        displayName,
        filterPaneEnabled,
        showPageNavigation,
        environmentNodeId,
      },
    })
  },

  async updateReport(report: ReportToUpdate) {
    let axios = await getAxios()
    const { id, environmentNodeId, ...reportData } = report
    await axios({
      method: 'patch',
      url: endpoints.report(environmentNodeId, id),
      data: reportData,
    })
  },

  async getReport(
    environmentId: string,
    reportId: string
  ): Promise<GetReportResponse> {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: endpoints.report(environmentId, reportId),
    })

    //rls roles currently looks like this: "[\"User\"]", i want it to look like ["User"]
    const formattedReport = {
      permissions: response.data.permissions,
      report: {
        ...response.data.report,
        // rlsRoles: JSON.parse(response.data.report.rlsRoles),
        // filters: response.data.filters ? JSON.parse(response.data.report.filters.replace(/\\/g, '')) : null,
        // selectedColumns: response.data.report.selectedColumns ? JSON.parse(response.data.report?.selectedColumns?.replace(/\\/g, '')) : null,
      },
    }

    return formattedReport
  },

  async getReports(environmentId: string) {
    let axios = await getAxios()

    // Note: If we want to request multiple users, the ids have to be sent in comma separated.

    let response
    try {
      response = await axios({
        method: 'get',
        url: endpoints.reports(environmentId),
      })
    } catch (e) {
      let error: AxiosError = e

      if (error.response) {
      }

      return {
        ownedReports: [],
        sharedReports: [],
        groupedReports: [],
        defaultReports: [],
      }
    }

    const ownedReports: ApiReport[] = formatReports(response.data.ownedReports)
    const sharedReports: ApiReport[] = formatReports(
      response.data.sharedReports
    )
    const groupedReports: ApiReport[] = formatReports(
      response.data.groupedReports
    )
    const defaultReports: ApiReport[] = formatReports(
      response.data.defaultReports
    )
    const environmentReports: ApiReport[] = formatReports(
      response.data.environmentReports
    )
    return {
      ownedReports,
      sharedReports,
      groupedReports,
      defaultReports,
      environmentReports,
    }
  },

  // async getReport(
  //   environmentId: string,
  //   reportId: string
  // ): Promise<ApiReport> {
  //   let axios = await getAxios()
  //   let response = await axios({
  //     method: 'get',
  //     url: endpoints.report(environmentId, reportId),
  //   })

  //   return response.data
  // },

  async getEnvironmentReports(environmentId: string): Promise<ApiReport[]> {
    let axios = await getAxios()
    let response

    try {
      response = await axios({
        method: 'get',
        url: endpoints.adminReports(environmentId),
      })

      return response?.data
    } catch (e) {
      return []
    }
  },

  async getReportsByUser(
    environmentId: string,
    userId: string
  ): Promise<{
    groupReports: ApiGroupReport[]
    ownedReports: ApiReport[]
    sharedReports: ApiReport[]
  }> {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: endpoints.userReports(environmentId, userId),
    })

    const allReportsResponse = response.data

    return {
      groupReports: allReportsResponse?.groupReports || [],
      ownedReports: allReportsResponse?.ownedReports || [],
      sharedReports: allReportsResponse?.sharedReports || [],
    }
  },

  async deleteReport(reportId: string, environmentId: string) {
    let axios = await getAxios()
    await axios({
      method: 'delete',
      url: endpoints.report(environmentId, reportId),
    })
    return reportId
  },

  async cloneReport({
    sourceReportId,
    newName,
    environmentNodeId,
    description,
    workspaceId,
    profileId,
  }): Promise<ReportToCreate> {
    let cloneData = {
      sourceReportId,
      profileId,
      newName,
      description,
      environmentNodeId,
      workspaceId,
    }
    //TODO: NEED TO FIX THIS WHEN CLONE REPORT IS READY
    let axios = await getAxios()
    let response = await axios({
      method: 'post',
      url: endpoints.reportClone(environmentNodeId, sourceReportId),
      data: cloneData,
    })
    return response.data
  },

  async testReportConnection(
    environmentId: string,
    pbiWorkspaceId: string,
    itemId: string,
    appRegistrationNodeId: string,
    profileId: string,
    type: string
  ): Promise<ReportConnectionStatus> {
    let axios = await getAxios()

    try {
      let response = await axios({
        method: 'post',
        url: endpoints.appRegistrationTestReportConnection(
          environmentId,
          appRegistrationNodeId
        ),
        data: {
          pbiWorkspaceId,
          itemId,
          profileId,
          type,
        },
      })

      if (
        response.status === 200 &&
        (response.data.isEffectiveIdentityRequired ||
          response.data.isEffectiveIdentityRolesRequired)
      ) {
        return ReportConnectionStatus.RowLevelSecurity
      }

      return response.status
    } catch (e) {
      return e.response.status
    }
  },

  async getViewers(
    reportId: string,
    environmentId: string
  ): Promise<GetReportViewersResponse> {
    const axios = await getAxios()

    const response = await axios.get<GetReportViewersResponse>(
      endpoints.reportViewers(environmentId, reportId)
    )

    return response.data
  },

  async addViewers(
    reportId: string,
    viewerIds: string[],
    environmentId: string
  ): Promise<void> {
    let axios = await getAxios()
    let response = await axios({
      method: 'post',
      url: endpoints.reportViewers(environmentId, reportId),
      data: {
        viewerIds,
      },
    })

    if (response.status !== 200) {
      throw new Error(response.data?.errorMessage)
    }
  },

  async addViewerToReports(
    reportIds: string[],
    viewerId: string,
    environmentId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.userReports(environmentId, viewerId),
      data: [...reportIds],
    })

    if (response.status !== 200) {
      throw new Error(response.data?.errorMessage)
    }
  },

  async removeViewers(
    reportId: string,
    viewerId: string,
    environmentId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.reportViewer(environmentId, reportId, viewerId),
    })

    if (response.status !== 200) {
      throw new Error(response.data?.errorMessage)
    }
  },

  async addDefault(reportId: string, environmentId: string): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.defaultEnvironmentReport(environmentId, reportId),
    })

    if (response.status !== 200) {
      throw new Error(response.data?.errorMessage)
    }
  },
  async removeDefault(reportId: string, environmentId: string): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.defaultEnvironmentReport(environmentId, reportId),
    })

    if (response.status !== 200) {
      throw new Error(response.data?.errorMessage)
    }
  },

  async getDashboard(environmentId: string): Promise<HomepageCards> {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: endpoints.dashboard(environmentId),
    })

    let reportGroups: ReportGroupWithDetails[] =
      response?.data?.reportGroups || []

    const folders: Folder[] = response.data.folders?.map(folder => {
      return {
        ...folder,
        items: formatFolderItems(folder.items),
      }
    })

    reportGroups = response.data.reportGroups?.map(group => {
      return {
        ...group,
        folders: group.folders.map(folder => {
          return {
            ...folder,
            items: formatFolderItems(folder.items),
          }
        }),
      }
    })

    function formatFolderItems(items: any) {
      const parsedItems = items ? JSON.parse(items) : []

      //Go through each item and format it and make sure the prop names are camel case by ensuring the first letter is lowercase
      return parsedItems.map(item => {
        const formattedItem = Object.keys(item).reduce((acc, key) => {
          const formattedKey = key.charAt(0).toLowerCase() + key.slice(1)
          acc[formattedKey] = item[key]
          return acc
        }, {})
        return formattedItem
      })
    }

    const ownedReports: ApiReport[] =
      response.data.reports?.ownedReports?.map(report => {
        return {
          ...report,
        }
      }) || []

    const sharedReports: ApiReport[] =
      response.data.reports?.sharedReports?.map(report => {
        return {
          ...report,
        }
      }) || []

    const defaultReports: ApiReport[] =
      response.data.reports?.defaultReports?.map(report => {
        return {
          ...report,
        }
      }) || []

    const environmentReports: ApiReport[] =
      response.data.reports?.environmentReports?.map(report => {
        return {
          ...report,
        }
      }) || []

    const allReportIds = [
      ...ownedReports.map(report => report.id),
      ...sharedReports.map(report => report.id),
      ...defaultReports.map(report => report.id),
      ...environmentReports.map(report => report.id),
      ...reportGroups
        .map(group => group.reports)
        .flat()
        .map(report => report.id),
    ]

    folders.forEach(folder => {
      folder.items.forEach(item => {
        const isBookmark = item.type === 'bookmark'
        const hasAccess = isBookmark
          ? allReportIds.includes(item.reportId as string)
          : allReportIds.includes(item.id as string)
        item.accessDenied = !hasAccess
      })
    })

    //Do the same for report group folders
    reportGroups.forEach(group => {
      group.folders.forEach(folder => {
        folder.items.forEach(item => {
          const isBookmark = item.type === 'bookmark'
          const hasAccess = isBookmark
            ? allReportIds.includes(item.reportId as string)
            : allReportIds.includes(item.id as string)
          item.accessDenied = !hasAccess
        })
      })
    })

    const retObj = {
      reportGroups,
      reports: {
        ownedReports,
        sharedReports,
        defaultReports,
        environmentReports,
      },
      folders,
    }
    
    return retObj
  },
}
