import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { DashboardView } from '../reports/report-dashboard'

export function ReportGroupsPage() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <DashboardView isReportGroup />
      </Route>
    </Switch>
  )
}
