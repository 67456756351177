import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Box,
  Divider,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme as MuiTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { getClientPrincipal } from '../../components/auth/util'
import { useUpdateUserName } from '../../hooks/mutations/useUpdateUserName'
import {
  useCreateEnvironment,
  useCurrentUserInfo,
  useGetLicense,
} from '../../hooks'
import LogoutIcon from '@mui/icons-material/Logout'

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '500px',
      width: '100%',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    section: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
)

export const InitialSetup: React.FC = () => {
  const classes = useStyles()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [environment, setEnvironment] = useState('')

  const { mutate: updateUserName, isLoading: updateUserLoading } =
    useUpdateUserName()
  const {
    mutate: createEnvironment,
    isLoading: createEnvLoading,
    data: createEnvData,
    isError,
    isSuccess,
  } = useCreateEnvironment()
  const { refetch: refetchUserInfo } = useCurrentUserInfo()
  const { refetch: refetchLicense } = useGetLicense()

  const isLoading = updateUserLoading || createEnvLoading
  const [email, setEmail] = useState('')
  const history = useHistory()
  useEffect(() => {
    const fetchClientPrincipal = async () => {
      const user = await getClientPrincipal()
      setEmail(user.userDetails)
    }
    fetchClientPrincipal()
  }, [])

  useEffect(() => {
    if (isSuccess) {
      console.log('createEnvData', createEnvData)
      refetchLicense()
      // refetchUserInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    await updateUserName({
      userName: {
        firstName,
        lastName,
      },
    })
    // Send update for environment information.
    await createEnvironment({
      environmentName: environment,
    })

    // Refetch user info to ensure the environment is attached
    await refetchUserInfo()

    // Navigate to the next page or perform any other actions
    history.push('/')
    // load the page
  }

  const handleLogout = () => {
    // Implement your logout logic here
    window.location.href = '/.auth/logout'
  }

  return (
    <Container component='main' maxWidth='xs' className={classes.root}>
      <Tooltip title='Logout'>
        <Button
          variant='text'
          style={{ position: 'absolute', top: 10, right: 10 }}
          onClick={handleLogout}
        >
          {isError && <Typography sx={{ mr: 1, pl: 1 }}>Logout</Typography>}
          <LogoutIcon />
        </Button>
      </Tooltip>
      <Paper className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Welcome to Entelexos
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          We need a few details to get started.
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.section}>
            <Typography variant='h6'>User Information</Typography>
            <TextField
              variant='outlined'
              margin='normal'
              size='small'
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={email}
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              size='small'
              fullWidth
              id='firstName'
              label='First Name'
              name='firstName'
              autoComplete='fname'
              autoFocus
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              size='small'
              id='lastName'
              label='Last Name'
              name='lastName'
              autoComplete='lname'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Box>
          <Divider />
          <Box className={classes.section}>
            <Typography variant='h6'>Environment Information</Typography>
            <Typography variant='subtitle2' gutterBottom>
              Name your initial envirionment. This is your environment you will
              add reports and users to.
            </Typography>
            <TextField
              variant='outlined'
              margin='normal'
              required
              size='small'
              fullWidth
              id='environment'
              label='Environment Name'
              name='environment'
              autoComplete='environment'
              value={environment}
              onChange={e => setEnvironment(e.target.value)}
            />
          </Box>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
          {isError && (
            <Typography color='error'>
              There was an error creating your environment. Make sure you are
              the logged in as the license owner.
            </Typography>
          )}
        </form>
      </Paper>
    </Container>
  )
}

export default InitialSetup
