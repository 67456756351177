import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {workspacesApi} from '../../api-interface'
import {Workspace} from '../../models'
import {environmentAtom} from '../../state'
import {getAdminWorkspacesKey, getWorkspacesKey} from '../queries'

type DeleteWorkspaceContext = {
  previousWorkspaces: Workspace[]
}

export function useDeleteWorkspace() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (workspaceId: string) => workspacesApi.deleteWorkspace(workspaceId, environmentId),
    {
      async onMutate(workspaceId: string): Promise<DeleteWorkspaceContext> {
        await queryClient.cancelQueries([getAdminWorkspacesKey, environmentId])

        let previousWorkspaces: Workspace[] = queryClient.getQueryData([
          getAdminWorkspacesKey,
          environmentId,
        ])

        queryClient.setQueryData([getAdminWorkspacesKey, environmentId], (old: Workspace[]) =>
          old?.filter((workspace: Workspace) => workspace.id !== workspaceId)
        )

        return {previousWorkspaces}
      },
      onError(_err, _deletedWorkspace, context: DeleteWorkspaceContext) {
        queryClient.setQueryData(
          [getAdminWorkspacesKey, environmentId],
          context.previousWorkspaces
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAdminWorkspacesKey, environmentId])
        queryClient.invalidateQueries([getWorkspacesKey, environmentId])
      },
    }
  )
}
