import {useMutation, useQueryClient} from 'react-query'
import {userApi} from '../../api-interface'
import {GetUserInfoResponse} from '../../models'
import {currentUserInfoKey} from '../queries'

export function useUpdateDefaultEnvironment() {
  const queryClient = useQueryClient()

  return useMutation(
    (environmentId: string) => userApi.updateDefaultEnvironmentId(environmentId),
    {
      async onMutate(environmentId): Promise<GetUserInfoResponse> {
        await queryClient.cancelQueries(currentUserInfoKey)

        const previousResponse: GetUserInfoResponse =
          queryClient.getQueryData(currentUserInfoKey)

        queryClient.setQueryData(
          currentUserInfoKey,
          (previous: GetUserInfoResponse) => {
            previous.environments.forEach(environment => (environment.default = false))
            const environmentToUpdate = previous.environments.find(
              environment => environment.id === environmentId
            )
            const index = previous.environments.indexOf(environmentToUpdate)
            previous.environments[index].default = true

            return previous
          }
        )

        return previousResponse
      },
      onError(_err, _updatedInfo, context: GetUserInfoResponse) {
        queryClient.setQueryData(currentUserInfoKey, context)
      },
      onSettled() {
        queryClient.invalidateQueries(currentUserInfoKey)
      },
    }
  )
}
