import { useMutation, UseMutationOptions } from 'react-query'
import { licenseApi } from '../../api-interface'
import { AppLicense } from '../../models'

interface UpdateLicensePayload {
  licenseSku: 'basic' | 'enterprise'
}

export function useUpdateLicense(options: UseMutationOptions<AppLicense, Error, UpdateLicensePayload> = {}) {
  return useMutation(
    async (payload: UpdateLicensePayload) => {

      const updatedLicense = await licenseApi.updateLicense(payload.licenseSku)
      return updatedLicense
    },
    options
  )
}
