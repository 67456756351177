import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import { environmentApi } from '../../api-interface'
import { environmentAtom } from '../../state'

export const getEnvironmentQueryKey = 'get-environment'

export function useGetEnvironment() {
  const [environmentId, setEnvironmentId] = useRecoilState(environmentAtom)

  const query = useQuery([getEnvironmentQueryKey, environmentId], async () => {
    try {
      return await environmentApi.getEnvironment(environmentId)
    } catch (error) {
      // Unset the recoil state
      setEnvironmentId(null)
    }
  })

  return query
}
