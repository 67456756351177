import { Stack, Grid, Skeleton } from '@mui/material'

export function ReportTilesSkeleton({ numReports }: { numReports: number }) {
  return (
    <Stack sx={{ width: '100%', maxWidth: '1764px', mt: 5.5 }}>
      <Grid
        container
        spacing={6}
        columns={{ xs: 2, md: 3, lg: 6 }}
        alignContent='center'
      >
        {Array.from({ length: numReports }, (_, index) => (
          <Grid item xs={2} md={3} lg={2} key={index}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: '#f5f3f3',
                borderRadius: '8px',
                p: 2,
                alignItems: 'flex-start',
                height: '100%',
                pr: 1,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                gap={2}
              >
                <Skeleton
                  variant='circular'
                  sx={{ width: '80px !important', height: '80px !important' }}
                />
                <Stack direction='column' justifyContent='center'>
                  <Skeleton width='200px' height='32px' />
                  <Skeleton width='180px' height='20px' />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
