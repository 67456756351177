import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {workspacesApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getWorkspaceUsersKey = 'get-workspace-users'

export function useGetWorkspaceUsers(workspaceId: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery([getWorkspaceUsersKey, workspaceId], () =>
    workspacesApi.getWorkspaceUsers(workspaceId, environmentId)
  )
}
