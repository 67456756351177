import { FC, useEffect, useState } from 'react'
import { useCreateWorkspace } from '../../../../hooks/mutations/useCreateWorkspace'
import { StatusMessageInfo, Workspace } from '../../../../models'
import { FormField, StatusMessage } from '../../shared'
import {
  useGetAdminWorkspaces,
  useGetAppRegs,
  useGetCapacities,
  useUpdateWorkspace,
} from '../../../../hooks'
import { Capacity, ProfileAndAppReg } from '../reports/add-report-form/types'
import { SkeletonFormField } from '../reports'
import { Stack } from '@mui/material'
import CustomDialog from '../../shared/dialog/dialog'

export type WorkspaceFormProps = {
  onClose: () => void
  editMode?: boolean
  workspace?: Workspace
}

const illegalCharsRegex = /[/\\?%*:|"<>.']/g

export const WorkspaceForm: FC<WorkspaceFormProps> = (
  props: WorkspaceFormProps
) => {
  const { onClose, workspace, editMode } = props

  const { mutateAsync: createWorkspace, isLoading: isSaving } =
    useCreateWorkspace()

  const { mutateAsync: updateWorkspace, isLoading: isUpdating } =
    useUpdateWorkspace()

  const { data: existingAppRegistrations, isLoading: loadingAppRegs } =
    useGetAppRegs()

  const [profiles, setProfiles] = useState<ProfileAndAppReg[]>([])
  const [profile, setProfile] = useState<ProfileAndAppReg | null>(null)
  const [capacity, setCapacity] = useState<Capacity | null>(null)

  const { data: currentWorkspaces } = useGetAdminWorkspaces()
  const {
    data: capacities,
    isLoading: loadingCapacities,
    refetch: refetchCapacities,
    isRefetching: isRefetchingCapacities,
  } = useGetCapacities(
    profile?.appRegNodeId,
    profile?.appRegId,
    profile?.tenantId,
    profile?.id
  )

  useEffect(() => {
    if (editMode && workspace && capacities && capacities.length > 0) {
      const matchingCapacity = capacities.find(
        (cap: Capacity) => cap.id === workspace.capacityId
      )

      if (matchingCapacity) setCapacity(matchingCapacity)
      else setCapacity(null)
    }
  }, [editMode, workspace, capacities])

  const [workspaceName, setWorkspaceName] = useState<string>(
    workspace?.name || ''
  )

  const [formError, setFormError] = useState<string>('')
  const [status, setStatus] = useState<StatusMessageInfo | null>(null)
  const workspaceNames = currentWorkspaces?.map(g => g.name.toLowerCase())

  useEffect(() => {
    if (profiles?.length === 1) {
      setProfile(profiles[0])
    }
  }, [profiles])

  useEffect(() => {
    if (capacities?.length === 1) {
      setCapacity(capacities[0])
    }
  }, [capacities])

  useEffect(() => {
    let tempProfiles: ProfileAndAppReg[] = []
    if (existingAppRegistrations) {
      existingAppRegistrations.forEach(appReg => {
        if (appReg.profiles) {
          appReg.profiles.forEach(profile => {
            tempProfiles.push({
              ...profile,
              appRegId: appReg.appRegistrationId,
              appRegName: appReg.name,
              appRegNodeId: appReg.id,
              tenantId: appReg.tenantId,
            })
          })
        }
      })
      setProfiles(tempProfiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAppRegistrations])

  const onWorkspaceNameChange = (name: string) => {
    setWorkspaceName(name)

    //if workspace name contains any illegal characters, set error
    const illegalChars = name.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the following characters: / \\ ? % * : | " \' < > .'
      )
      return
    }

    //if workspace name already exists, set error
    if (workspaceNames?.includes(name.toLowerCase())) {
      setStatus({
        type: 'warning',
        title: 'Name Conflict',
        message: 'Workspace name already exists',
      })
      return
    }

    setFormError('')
    setStatus(null)
  }

  const handleSave = async (): Promise<void> => {
    if (formError) return
    const trimmedWorkspaceName = workspaceName.trim()

    if (!trimmedWorkspaceName) {
      setFormError('Workspace name is required')
      return
    }

    try {
      if (editMode) {
        await updateWorkspace({
          workspaceName: trimmedWorkspaceName,
          workspaceId: workspace.id,
        })
        onClose()
        return
      }
      await createWorkspace({
        name: trimmedWorkspaceName,
        appRegNodeId: profile?.appRegNodeId,
        profileId: profile?.id,
        capacityId: capacity?.id,
        description: '',
      })

      onClose()
    } catch (error) {
      setStatus({
        type: 'error',
        message: `An error occurred while saving the workspace ${error.response.data?.errorMessage}`,
        title: 'Error',
      })
    }
  }

  const ConnectionProfileFormField = () => {
    if (loadingAppRegs) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label={'Connection Profile'}
        value={profile?.name || ''}
        onTextChange={value => {
          let selectedProfile = profiles?.find(
            profile => profile?.name === value
          )
          if (!selectedProfile) return

          setProfile(selectedProfile)
          refetchCapacities()
        }}
        disabled={!profiles || profiles.length === 0 || editMode}
        selectOptions={profiles?.map(profile => profile?.name) || []}
        id='profiles-form-field'
      />
    )
  }
  const CapacitiesFormField = () => {
    if (loadingCapacities || isRefetchingCapacities) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        id='capacities-form-field'
        label={'Capacity'}
        value={capacity?.displayName || ''}
        onTextChange={value => {
          let selectedCapacity: Capacity = capacities?.find(
            capacity => capacity?.displayName === value
          )
          if (!selectedCapacity) return
          setCapacity(selectedCapacity)
        }}
        disabled={!capacities || capacities.length === 0 || editMode}
        selectOptions={capacities?.map(capacity => capacity?.displayName) || []}
      />
    )
  }
  const disableSave =
    formError !== '' ||
    (status && status?.type === 'error') ||
    !profile ||
    !capacity ||
    workspaceName === ''

  return (
    <CustomDialog
      key='workspace-form'
      error={formError}
      title={editMode ? 'Edit Workspace' : 'Add New Workspace'}
      open={true}
      primaryButtonProps={
        editMode
          ? {
              children: 'Update',
              onClick: handleSave,
              disabled: isUpdating || disableSave,
            }
          : {
              children: 'Create',
              onClick: handleSave,
              disabled: isSaving || disableSave,
            }
      }
      onClose={onClose}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: onClose,
      }}
    >
      <Stack
        direction='column'
        sx={{
          height: '100%',
        }}
      >
        <ConnectionProfileFormField />
        <CapacitiesFormField />
        <FormField
          label='Workspace name'
          type='text'
          id='workspace-name'
          onTextChange={onWorkspaceNameChange}
          value={workspaceName}
        ></FormField>
        {/* <FormField
        label='Description'
        onTextChange={onWorkspaceNameChange}
        value={workspaceName}

      ></FormField> */}
        {status && <StatusMessage status={status} />}
      </Stack>
    </CustomDialog>
  )
}
