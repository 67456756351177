import { useState, useMemo } from 'react'

import { FolderItem } from '../models' // Adjust import path to match your models
import { useGetFolders } from './queries'
import { useCreateFolder } from './mutations/useCreateFolder'
import { useUpdateFolder } from './mutations/useUpdateFolder'

/**
 * Custom hook to manage adding items to folders.
 * @param item The item to add (e.g., report, bookmark, dataset)
 * @returns Object containing functions to add to folder, loading state, and available folders
 */
export const useAddToFolder = (item: FolderItem) => {
  const { data: folders, isLoading: isFoldersLoading } = useGetFolders()
  const { mutateAsync: createFolder, isLoading: isCreatingFolder } =
    useCreateFolder()
  const { mutateAsync: updateFolder, isLoading: isUpdatingFolder } =
    useUpdateFolder()

  const [isAdding, setIsAdding] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Filter out folders that already contain this item or are not editable
  const availableFolders = useMemo(() => {
    return (
      folders?.filter(
        folder =>
          !folder.disableEdit &&
          !folder.items?.some(folderItem => folderItem.id === item.id)
      ) || []
    )
  }, [folders, item.id])

  /**
   * Adds the item to a specified folder or creates a new folder if no folderId is provided.
   * @param folderId The ID of the folder to add the item to (empty string for new folder)
   * @param newFolderName Optional name for a new folder
   */
  const addToFolder = async (folderId: string, newFolderName?: string) => {
    setIsAdding(true)
    setError(null)

    try {
      if (newFolderName && folderId === '') {
        // Create a new folder with the item
        await createFolder({
          folderName: newFolderName,
          items: [
            {
              id: item.id,
              type: item.type || 'report', // Default to 'report' if type is undefined
              name: item.name,
            },
          ],
        })
      } else {
        // Add item to an existing folder
        const folder = folders?.find(f => f.id === folderId)
        if (!folder) throw new Error('Folder not found')

        // Use updateFolder to add the item to the existing folder
        await updateFolder({
          folderId: folder.id,
          items: [
            ...(folder.items || []),
            {
              id: item.id,
              type: item.type || 'report',
              name: item.name,
            },
          ],
        })
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Failed to add item to folder'
      )
    } finally {
      setIsAdding(false)
    }
  }

  // Combine all loading states
  const isLoading =
    isFoldersLoading || isCreatingFolder || isUpdatingFolder || isAdding

  return {
    addToFolder,
    availableFolders,
    isLoading,
    error,
  }
}
