import {useMutation, useQueryClient} from 'react-query'
import {bookmarkApi} from '../../api-interface'
import {AllBookmarks} from '../../models'
import {getBookmarksKey} from '../queries'
import {useRecoilValue} from 'recoil'
import {environmentAtom} from '../../state'

type CreateBookmarkContext = {
  previousBookmarks: AllBookmarks
}

export function useUpdateBookmark(reportId) {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(bookmarkApi.updateBookmark, {
    async onMutate(newBookmark): Promise<CreateBookmarkContext> {
      await queryClient.cancelQueries([getBookmarksKey, environmentId, reportId])

      let previousBookmarks: AllBookmarks = await queryClient.getQueryData([
        getBookmarksKey,
        environmentId,
        reportId,
      ])
      //
      // Have to destructure the ApiBookmark out of the BookmarkToCreate
      queryClient.setQueryData(
        [getBookmarksKey, environmentId, reportId],
        (old: AllBookmarks) => {
          if (!old) {
            return {
              ownedBookmarks: [],
              sharedBookmarks: [],
              environmentBookmarks: [],
            }
          }

          const bookmarkIndex = old.ownedBookmarks?.findIndex(
            curBookmark => curBookmark.id === newBookmark.id
          )
          const newOwnedBookmarks = [...old.ownedBookmarks]
          if (bookmarkIndex !== -1) {
            newOwnedBookmarks[bookmarkIndex] = newBookmark
          }

          
          return {
            ownedBookmarks: newOwnedBookmarks,
            sharedBookmarks: old.sharedBookmarks,
            environmentBookmarks: old.environmentBookmarks,
          }
        }
      )

      // Return the context passed on onError
      return {previousBookmarks}
    },
    onError(err, newUser, context: CreateBookmarkContext) {
      queryClient.setQueryData(
        [getBookmarksKey, environmentId, reportId],
        context.previousBookmarks
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getBookmarksKey, environmentId, reportId])
    },
  })
}
