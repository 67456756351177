import { FC } from 'react'
import {
  useAddViewersToReport,
  useGetGroups,
  useGetReportViewers,
} from '../../../../hooks'
import { Group, ReportData } from '../../../../models'
import { LinkItemsMenu } from '../../shared'

type LinkGroupsMenuProps = {
  report: ReportData
}

export const LinkGroupsMenu: FC<LinkGroupsMenuProps> = (
  props: LinkGroupsMenuProps
) => {
  const { report } = props
  const { data: groups } = useGetGroups()
  const { data: { groups: linkedGroups } = { groups: [] } } =
    useGetReportViewers(report.id)
  const { mutateAsync: addViewers } = useAddViewersToReport()

  const linkedGroupIds = linkedGroups && linkedGroups.map((g: Group) => g.id)
  const groupsAvailableToAdd =
    groups &&
    linkedGroupIds &&
    groups.filter((g: Group) => !linkedGroupIds.includes(g.id))

  const linkGroups = async (groupIds: string[]) => {
    const linkedGroupIds = linkedGroups.map((g: Group) => g.id)
    const groupsToAdd = groupIds.filter(v => !linkedGroupIds.includes(v))

    if (!groupsToAdd.length) return

    await addViewers({
      reportId: report.id,
      viewerIds: groupsToAdd,
      viewerType: 'group',
    })
  }

  return (
    <LinkItemsMenu
      onConfirm={linkGroups}
      addButtonLabel='Add Groups'
      placeHolderText='Search groups'
      availableItems={
        groupsAvailableToAdd &&
        groupsAvailableToAdd.map((g: Group) => {
          return {
            id: g.id,
            name: g.name,
          }
        })
      }
    />
  )
}
