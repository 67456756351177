import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { environmentAtom } from '../../state'

export const getFoldersKey = 'get-folders'

export function useGetFolders(reportGroupId?: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getFoldersKey, environmentId, reportGroupId],
    () => folderApi.getFoldersForUser(environmentId, reportGroupId),
    {
      enabled: !!environmentId,
    }
  )
}
