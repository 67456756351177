import { useMutation } from 'react-query'
import { workspacesApi } from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'
import { getColumnValuesDAXQuery } from '../../pages/reports/create-custom-report/generate-rdl-file/generate-dax-query'

export const postColumnValuesQuery = 'post-column-values-query'

export type PostColumnValuesQueryPayload = {
  reportId: string
  columnId: string
  search?: string
}

export function usePostColumnValuesQuery(
    
) {
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(async (payload: PostColumnValuesQueryPayload) => {

    const query = getColumnValuesDAXQuery(payload.columnId, payload.search)
    
    const values = await workspacesApi.postDaxQuery(
      {
        ...payload,
        query,
      },
      environmentId
    )

    const formattedData = formatUniqueRowValues(values, payload.columnId)

    return formattedData
  })
}


  const formatUniqueRowValues = (data: any, colId: string): string[] => {
    //return an array or values. ignore the keys:
    return Object.values(data)
      .map((v: any) => Object.values(v)?.[0] as string)
      ?.filter((v: any) => !!v)
  }
