import { useEffect, useMemo, useState } from 'react'
import { Button, debounce, List, Stack, TextField } from '@mui/material'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import {
  mdiShieldKeyOutline as appRegistrationIcon,
  mdiChartBoxPlusOutline as reportsIcon,
  mdiAccountPlusOutline as usersIcon,
  mdiAccountGroupOutline as groupsIcon,
  mdiPaletteSwatchOutline as palletIcon,
  mdiFolderMultipleOutline as workspacesIcon,
  mdiSelectGroup as reportGroupIcon,
} from '@mdi/js'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import {
  useGetAppSettings,
  useGetDashboard,
  useGetEnvironment,
  useRoutes,
} from '../../../hooks'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import { NavigationLink } from './navigation-link'
import { FolderSection } from './folder-section'
import { NavigationSection } from './navigation-section'
import { FolderNav } from './folder-nav'
import { FolderItem, HomepageCards } from '../../../models'
import { ReportGroupNav } from './report-group-nav'

export function NavigationLinks() {
  const { data: environment } = useGetEnvironment()
  const { data: appSettings } = useGetAppSettings()
  const { data: dashboard } = useGetDashboard()

  const routes = useRoutes()
  const [searchText, setSearchText] = useState('')
  const [filteredNavMenuItems, setFilteredNavMenuItems] =
    useState<HomepageCards>(dashboard)

  const filterItems = (items: any[], searchText: string) => {
    const filteredItems = items?.filter(item =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    )
    return filteredItems
  }

  // Memoization logic inside handleSearch to avoid stale state
  const handleSearch = useMemo(
    () =>
      debounce((text: string) => {
        if (!dashboard) return

        // Filter top-level folders
        const filteredFolders = dashboard.folders
          .map(folder => {
            const folderNameMatches = folder.name
              .toLowerCase()
              .includes(text.toLowerCase())
            const filteredItems = folderNameMatches
              ? folder.items
              : filterItems(folder.items, text)

            return {
              ...folder,
              items: filteredItems,
            }
          })
          .filter(
            folder =>
              folder.items.length > 0 ||
              folder.name.toLowerCase().includes(text.toLowerCase())
          )

        // Filter reports
        const filteredMyReports = filterItems(
          dashboard.reports.ownedReports,
          text
        )
        const filteredSharedReports = filterItems(
          dashboard.reports.sharedReports,
          text
        )

        const filteredReportGroups = dashboard.reportGroups
          ?.map(group => {
            const reportGroupNameMatches = group.name
              ?.toLocaleLowerCase()
              .includes(text.toLowerCase())

            if (reportGroupNameMatches) return group
            const filteredFoldersInGroup = group.folders
              .map(folder => {
                const folderNameMatches = folder.name
                  .toLowerCase()
                  .includes(text.toLowerCase())

                const filteredItems = folderNameMatches
                  ? folder.items
                  : filterItems(folder.items, text)

                return {
                  ...folder,
                  items: filteredItems,
                }
              })
              .filter(
                folder =>
                  folder.items.length > 0 ||
                  folder.name.toLowerCase().includes(text.toLowerCase())
              )

            return {
              ...group,
              reports: filterItems(group.reports, text),
              folders: filteredFoldersInGroup,
            }
          })
          ?.filter(
            group =>
              group.reports.length > 0 ||
              group.folders.length > 0 ||
              group.name.toLowerCase().includes(text.toLowerCase())
          )

        // Update filtered navigation menu items
        setFilteredNavMenuItems({
          folders: filteredFolders,
          reports: {
            ownedReports: filteredMyReports,
            sharedReports: filteredSharedReports,
            // groupedReports: dashboard.reports.groupedReports,
            defaultReports: dashboard.reports.defaultReports,
            environmentReports: [],
          },
          reportGroups: filteredReportGroups,
        })
      }, 650),
    [dashboard]
  )

  // const debouncedHandleSearch = debounce(handleSearch, 300)

  useEffect(() => {
    if (dashboard) {
      setFilteredNavMenuItems(dashboard)
      setSearchText('')
    }
  }, [dashboard])

  const handleClear = () => {
    setSearchText('')
    setFilteredNavMenuItems(dashboard)
  }

  const subscriptionNavLinkTitle = 'License & Subscription'

  const memoizedMyReports = useMemo(() => {
    const { ownedReports } = filteredNavMenuItems?.reports || {}
    const myReports = ownedReports

    if (!myReports || myReports.length === 0) return null
    const ownedReportsFolderItems: FolderItem[] = myReports.map(report => ({
      id: report.id,
      name: report.name,
      type: 'report',
    }))

    return (
      <FolderNav
        title='My Reports'
        items={ownedReportsFolderItems}
        icon={<FormatListBulletedOutlinedIcon />}
        searchText={searchText}
      />
    )
  }, [filteredNavMenuItems, searchText])

  const memoizedFolders = useMemo(() => {
    const foldersToRender = filteredNavMenuItems?.folders
    return foldersToRender?.length > 0 ? (
      <Stack direction='column' gap={1}>
        <FolderSection
          folders={foldersToRender}
          sectionName='Folders'
          searchText={searchText}
        />
      </Stack>
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredNavMenuItems])

  const AnalyticSection = () => (
    <NavigationSection title='Analytics' collapsible>
      <List>
        <NavigationLink path={'/'} icon={AssessmentOutlinedIcon}>
          Dashboard
        </NavigationLink>
        {filteredNavMenuItems?.reportGroups?.length > 0 && (
          <Stack direction='column' gap={1}>
            {filteredNavMenuItems?.reportGroups.map(group => (
              <ReportGroupNav
                key={group.id}
                reportGroup={group}
                searchText={searchText}
              />
            ))}
          </Stack>
        )}
        {memoizedMyReports}
        {memoizedFolders}
      </List>
    </NavigationSection>
  )

  const AdminSection = () => (
    <NavigationSection title='Admin' collapsible>
      <List sx={{ fontSize: '14px' }}>
        <NavigationLink
          path={routes.admin.appReg}
          iconPath={appRegistrationIcon}
        >
          Connection Profiles
        </NavigationLink>
        <NavigationLink path={routes.admin.reports} iconPath={reportsIcon}>
          Reports
        </NavigationLink>
        <NavigationLink
          path={routes.admin.reportGroups}
          iconPath={reportGroupIcon}
        >
          Report Groups
        </NavigationLink>
        <NavigationLink
          path={routes.admin.workspaces}
          iconPath={workspacesIcon}
        >
          Workspaces
        </NavigationLink>
        <NavigationLink path={routes.admin.users} iconPath={usersIcon}>
          Users
        </NavigationLink>
        {appSettings?.groupsEnabled && (
          <NavigationLink path={routes.admin.groups} iconPath={groupsIcon}>
            Groups
          </NavigationLink>
        )}
        <NavigationLink path={routes.admin.appearance} iconPath={palletIcon}>
          Appearance
        </NavigationLink>
        {environment.isOwner && (
          <NavigationLink
            path={routes.admin.subscription}
            icon={AdminPanelSettingsOutlinedIcon}
          >
            {subscriptionNavLinkTitle}
          </NavigationLink>
        )}
      </List>
    </NavigationSection>
  )

  return (
    <List
      sx={{
        width: '340px',
      }}
    >
      <TextField
        id='search'
        label='Search'
        variant='outlined'
        value={searchText}
        onChange={e => {
          const text = escapeRegex(e.target.value)
          setSearchText(text)
          handleSearch(text)
        }}
        sx={{
          width: '100%',
          mb: 1,
          '& .MuiInputBase-input': {
            fontSize: '16px',
          },
          //make the height smaller
          '& .MuiOutlinedInput-root': {
            height: '48px',
          },
        }}
        InputProps={{
          endAdornment: searchText && (
            <Button onClick={handleClear}>Clear</Button>
          ),
        }}
      />
      <AnalyticSection />
      {environment && (environment.isAdmin || environment.isOwner) && (
        <AdminSection />
      )}
    </List>
  )
}

function escapeRegex(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '')
}
