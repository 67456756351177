import { useMutation, useQueryClient } from 'react-query'
import { ApiReport } from '../../models'
import { reportApi } from '../../api-interface'
import { getDashboardKey, getFoldersKey, getEnvironmentReportsKey } from '..'
import { environmentAtom } from '../../state'
import { useRecoilValue } from 'recoil'
type DeleteReportContext = {
  previousReports: ApiReport[]
}
export function useDeleteReport() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (reportId: string) => reportApi.deleteReport(reportId, environmentId),
    {
      async onMutate(newReportId: string): Promise<DeleteReportContext> {
        await queryClient.cancelQueries([getEnvironmentReportsKey, environmentId])

        let previousReports: ApiReport[] = queryClient.getQueryData([
          getEnvironmentReportsKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getEnvironmentReportsKey, environmentId],
          (old: any) => {
            if (old === undefined) {
              return []
            }
            return old.filter((report: ApiReport) => report.id !== newReportId)
          }
        )


        return { previousReports }
      },
      onError(err, newAppRegId, context: DeleteReportContext) {
        queryClient.setQueryData(
          [getEnvironmentReportsKey, environmentId],
          context.previousReports
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getEnvironmentReportsKey, environmentId])
        queryClient.invalidateQueries([getFoldersKey, environmentId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
