import { Stack, Grid, Typography } from '@mui/material'
import { ReportTile } from './report-tile'
import { ReportGroupTile } from './report-group-tile' // Import the new component

export function ReportTiles({
  title,
  subtitle,
  cards,
}: {
  title?: string
  subtitle?: string
  cards: any[]
}) {
  return (
    <Stack sx={{ width: '100%', maxWidth: '1764px', mt: 5.5 }}>
      <Typography variant='h5'>{title}</Typography>
      <Typography variant='subtitle2' sx={{ mb: 4 }}>
        {subtitle}
      </Typography>
      <Grid
        container
        spacing={6}
        columns={{ xs: 2, md: 3, lg: 6 }}
        alignContent='center'
      >
        {cards.map(card => (
          <Grid item xs={2} md={3} lg={2} key={`${card.id}-${card.name}-grid`}>
            {card.type === 'report-group' ? (
              <ReportGroupTile
                key={`${card.id}-${card.name}`}
                title={card.name}
                description={card.description || ''}
                id={card.id}
                imageUrl={card.imageUrl}
              />
            ) : (
              <ReportTile
                key={`${card.id}-${card.name}`}
                title={card.name}
                description={card.description || ''}
                id={card.id}
                type={card.type || 'report'}
                imageUrl={card.imageUrl}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
