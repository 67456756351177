import { useMutation, useQueryClient } from 'react-query'
import { reportGroupApi } from '../../api-interface'
import { getDashboardKey, getReportGroupUsersKey } from '../queries'
import { User } from '../../models'
import { environmentAtom } from '../../state'
import { useRecoilValue } from 'recoil'

export const removeReportGroupUserKey = 'remove-report-group-user'

export type RemoveReportGroupUsersPayload = {
  reportGroupId: string
  userId: string
}

export function useRemoveReportGroupUser() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (payload: RemoveReportGroupUsersPayload) =>
      reportGroupApi.removeReportGroupUser(
        environmentId,
        payload.reportGroupId,
        payload.userId,
        
      ),
    {
      async onMutate(payload: RemoveReportGroupUsersPayload): Promise<User[]> {
        await queryClient.cancelQueries([
          getReportGroupUsersKey,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getReportGroupUsersKey, payload.reportGroupId],
          (old: User[]) => {
            const updatedUsers = old?.filter(r => r.id !== payload.userId)
            return updatedUsers
          }
        )

        let previousUsers: User[] = queryClient.getQueryData([
          getReportGroupUsersKey,
          payload.reportGroupId,
        ])

        let allPreviousUsers = previousUsers

        if (allPreviousUsers?.length === 0) {
          previousUsers = await queryClient.fetchQuery(
            [getReportGroupUsersKey, payload.reportGroupId],
            () => reportGroupApi.getReportGroupUsers(environmentId, payload.reportGroupId)
          )
          allPreviousUsers = previousUsers
        }

        queryClient.setQueryData(
          [getReportGroupUsersKey, payload.reportGroupId],
          (old: User[]) => {
            const updatedUsers = old?.filter(
              (oldReport: User) => oldReport.id !== payload.userId
            )

            return updatedUsers
          }
        )

        return allPreviousUsers
      },
      onError(
        _err,
        payload: RemoveReportGroupUsersPayload,
        previousUsers: User[]
      ) {
        queryClient.setQueryData(
          [getReportGroupUsersKey, payload.reportGroupId],
          previousUsers
        )

      },
      onSettled: (data, error, payload: RemoveReportGroupUsersPayload) => {
        queryClient.invalidateQueries([getReportGroupUsersKey, payload.reportGroupId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
