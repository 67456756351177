import {ThemeOptions} from '@mui/material'
import { AppTheme } from '../models'

// TODO: move this to another file later
export const defaultTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#2b2b2b',
    },
    secondary: {
      main: '#CB3757',
    },
    background: {
      default: '#F2F2F2',
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
}

export const defaultAppTheme:AppTheme = {
  primaryColor: '#2b2b2b',
  secondaryColor: '#CB3757',
  successColor: '#28a745',
  warningColor: '#ffc107',
  errorColor: '#dc3545',
  backgroundColor: '#F2F2F2',
}

export const convertAppThemeToMuiTheme = (appTheme: AppTheme): ThemeOptions => {
  return {
    palette: {
      mode: 'light',
      primary: {
        main: appTheme?.primaryColor || defaultAppTheme.primaryColor,
      },
      secondary: {
        main: appTheme?.secondaryColor || defaultAppTheme.secondaryColor,
      },
      background: {
        default: appTheme?.backgroundColor || defaultAppTheme.backgroundColor,
      },
      success: {
        main: appTheme?.successColor || defaultAppTheme.successColor,
      },
      warning: {
        main: appTheme?.warningColor || defaultAppTheme.warningColor,
      },
      error: {
        main: appTheme?.errorColor || defaultAppTheme.errorColor,
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize',
      },
    },
  }
}