import queryString from 'query-string'

const baseUrl = '/api' // 'http://127.0.0.1:7071/api'
// prettier-ignore
const environmentRoute = (environmentId: string) => `${baseUrl}/environments/${environmentId}`

// prettier-ignore
export const endpoints = {
  // embedAccess: `${baseUrl}/embed-access`,
  // testConnection: `${baseUrl}/test-report-connection`,
  // userReports: `${baseUrl}/user-reports`,
  userProfile: `${baseUrl}/user/profile`,
  // ------------------------------- ENVIRONMENTS --------------------------------
  environment: (environmentId: string) => environmentRoute(environmentId),
  dashboard: (environmentId: string) => `${environmentRoute(environmentId)}/dashboard`,
  defaultEnvironment: (environmentId: string) => `${environmentRoute(environmentId)}/default`,
  defaultEnvironmentReport: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/default`,
  
  // ------------------------------- USERS --------------------------------
  users: (environmentId: string) => `${environmentRoute(environmentId)}/users`,
  user: (environmentId: string, userId: string) => `${environmentRoute(environmentId)}/users/${userId}`,
  userReports: (environmentId: string, userId: string) => `${environmentRoute(environmentId)}/users/${userId}/reports`,
  exportUsers: (environmentId: string) => `${environmentRoute(environmentId)}/users/export`,
  

  // ------------------------------- REPORTS --------------------------------
  reports: (environmentId: string) => `${environmentRoute(environmentId)}/reports`,
  adminReports: (environmentId: string) => `${environmentRoute(environmentId)}/admin/reports`,

  report: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}`,
  reportClone: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/clone`,
  // not implemented/different route
  // reportNavMenuItems: (environmentId: string, reportId: string) =>
  //   `${environmentRoute(environmentId)}/reports/${reportId}/items`,
  reportSettings: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/settings`,
  reportEmbedAccess: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/embed`,
  reportPreview:(environmentId:string) => `${environmentRoute(environmentId)}/report/preview`,
  // reportImportCustom: ,
  reportPages: (environmentId: string, pbiReportId: string) => `${environmentRoute(environmentId)}/pbi/reports/${pbiReportId}/pages`,
  reportViewers: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/viewers`,
  reportViewer: (environmentId: string, reportId: string, viewerId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/viewers/${viewerId}`,
  
  reportPerspectives: (environmentId: string) => `${environmentRoute(environmentId)}/reports/{reportId}/perspectives`,
  reportItems: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/items`,
  reportQuery: (environmentId: string, reportId: string) => `${environmentRoute(environmentId)}/reports/${reportId}/query`,
  

  // ------------------------------- WORKSPACES --------------------------------
  workspaces: (environmentId: string)                                   => `${environmentRoute(environmentId)}/workspaces`,
  adminWorkspaces: (environmentId: string)                              => `${environmentRoute(environmentId)}/admin/workspaces`,
  workspace: (environmentId: string, workspaceId: string)               => `${environmentRoute(environmentId)}/workspaces/${workspaceId}`,
  workspaceUsers: (environmentId: string, workspaceId: string)          => `${environmentRoute(environmentId)}/workspaces/${workspaceId}/users`,
  workspaceUser: (environmentId: string, workspaceId: string, userId: string) => `${environmentRoute(environmentId)}/workspaces/${workspaceId}/users/${userId}`,
  pbiWorkspaceDatasets: (environmentId: string, pbiWorkspaceId: string) => `${environmentRoute(environmentId)}/pbi/workspaces/${pbiWorkspaceId}/datasets`,
  pbiWorkspaceProfiles: (environmentId: string, pbiWorkspaceId: string) => `${environmentRoute(environmentId)}/pbi/workspaces/${pbiWorkspaceId}/profiles`,
  pbiWorkspaceReports: (environmentId: string, pbiWorkspaceId: string)  => `${environmentRoute(environmentId)}/pbi/workspaces/${pbiWorkspaceId}/reports`,


  // ------------------------------- THEME --------------------------------
  theme: (environmentId: string) => `${environmentRoute(environmentId)}/theme`,
  
  // environmentReports: `${baseUrl}/environment-reports`,
  
  testConnection: `${baseUrl}/test-report-connection`,
    
  
  // ------------------------------- APP REGISTRATIONS --------------------------------
  appRegistration:           (environmentId:string, appRegistrationId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}`,
  appRegistrations:          (environmentId:string)                           => `${environmentRoute(environmentId)}/appRegistrations`,
  appRegistrationItems:      (environmentId:string, appRegistrationId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}/items`,
  appRegistrationCapacities: (environmentId:string, appRegistrationId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}/capacities`,
  appRegistrationWorkspaces: (environmentId:string, appRegistrationId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}/workspaces`,
  appRegistrationReports:    (environmentId:string, appRegistrationId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}/reports`, //Unused currently
  appRegistrationProfiles:   (environmentId:string, appRegistrationId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}/profiles`,
  appRegistrationProfile:    (environmentId:string, appRegistrationId:string, profileId:string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegistrationId}/profiles/${profileId}`,
  appRegistrationTestReportConnection: (environmentId: string, appRegId: string) => `${environmentRoute(environmentId)}/appRegistrations/${appRegId}/testReportConnection`,
  
  profiles: (environmentId: string) => `${environmentRoute(environmentId)}/profiles`,


  // ------------------------------- FOLDERS --------------------------------
  folder: (environmentId:string, folderId:string) => `${environmentRoute(environmentId)}/folders/${folderId}`,
  folders:(environmentId:string)    =>  `${environmentRoute(environmentId)}/folders`,
  folderItems:(environmentId:string, folderId:string) => `${environmentRoute(environmentId)}/folders/${folderId}/items`,
  folderItem:(environmentId:string, folderId:string, itemId:string) => `${environmentRoute(environmentId)}/folders/${folderId}/items/${itemId}`,
  favorites:(environmentId:string)  =>  `${environmentRoute(environmentId)}/folders/favorites`,
  favoriteItem:(environmentId:string, itemId:string) => `${environmentRoute(environmentId)}/folders/favorites/items/${itemId}`,
  favoriteItems:(environmentId:string) => `${environmentRoute(environmentId)}/folders/favorites/items`,

  // ------------------------------- GROUPS --------------------------------
  groups:(environmentId:string)     =>  `${environmentRoute(environmentId)}/groups`,
  group:(environmentId:string, groupId:string) => `${environmentRoute(environmentId)}/groups/${groupId}`,
  groupUsers:(environmentId:string, groupId:string) => `${environmentRoute(environmentId)}/groups/${groupId}/users`,
  groupUser:(environmentId:string, groupId:string, userId: string) => `${environmentRoute(environmentId)}/groups/${groupId}/users/${userId}`,
  userGroups: (environmentId: string, userId: string) => `${environmentRoute(environmentId)}/users/${userId}/groups`,
  
  
  // ------------------------------- BOOKMARKS --------------------------------
  reportBookmarks:(environmentId:string, reportId:string) => `${environmentRoute(environmentId)}/reports/${reportId}/bookmarks`,
  reportBookmark:(environmentId:string, reportId:string, bookmarkId:string)  => `${environmentRoute(environmentId)}/reports/${reportId}/bookmarks/${bookmarkId}`,
  reportBookmarkDefault:(environmentId:string, reportId:string, bookmarkId:string) => `${environmentRoute(environmentId)}/reports/${reportId}/bookmarks/${bookmarkId}/default`,
  
  // ------------------------------- REPORT GROUPS --------------------------------
  reportGroups: (environmentId: string) => `${environmentRoute(environmentId)}/reportGroups`,
  reportGroup: (environmentId: string, reportGroupId: string) => `${environmentRoute(environmentId)}/reportGroups/${reportGroupId}`,
  reportGroupUsers: (environmentId: string, reportGroupId: string) => `${environmentRoute(environmentId)}/reportGroups/${reportGroupId}/users`,
  reportGroupReports: (environmentId: string, reportGroupId: string) => `${environmentRoute(environmentId)}/reportGroups/${reportGroupId}/reports`,
  reportGroupReport: (environmentId: string, reportGroupId: string, reportId: string) => `${environmentRoute(environmentId)}/reportGroups/${reportGroupId}/reports/${reportId}`,
  reportGroupUser: (environmentId: string, reportGroupId: string, userId: string) => `${environmentRoute(environmentId)}/reportGroups/${reportGroupId}/users/${userId}`,
  reportGroupFolders:(environmentId:string, reportGroupId:string) => `${environmentRoute(environmentId)}/reportGroups/${reportGroupId}/folders`,  
  

  environments: `${baseUrl}/environments`,
  license: `${baseUrl}/license`,

  paypalSubscriptionPlan: `${baseUrl}/paypal-subscription-plan`,
  paypalLicense: `${baseUrl}/paypal-license`,
  appSettings: `${baseUrl}/app-settings`,

  
  // to be done:
  // folderItems: `${baseUrl}/folder-items`, 
  navMenuItems: `${baseUrl}/nav-menu-items`, //Combine with dashboard

  
}
export function getEnvironmentRoute(
  endpoint: string,
  environmentId: string,
  entityId: string
): string {
  let route = `${baseUrl}/licenes/${environmentId}`
  if (endpoint) {
    route = `${route}/${endpoint}` + (entityId ? `/${entityId}` : '')
  }
  return route
}

export function buildQueryUrl(
  endpoint: string,
  query: { [key: string]: string | string[] }
) {
  return endpoint + '?' + queryString.stringify(query, { arrayFormat: 'comma' })
}
