import {
  GetUserInfoResponse,
  Group,
  NewUser,
  User,
  UserFullName,
  UserWithGroups,
} from '../models'

import {getAxios, endpoints} from './util'

export const userApi = {
  async createUsers(users: NewUser[], environmentId: string, reportIds: string[]) {

    let axios = await getAxios()
    let data = {
      users,
      reportIds,
    }

    try {
      await axios({
        method: 'put',
        url: endpoints.users(environmentId),
        data: data,
      })
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },
  
  async updateUserName(userName: UserFullName): Promise<User> {
    let axios = await getAxios()
    let data = {
      ...userName,
    }
    try {
      let response = await axios({
        method: 'patch',
        // Route not implemented
        url: endpoints.userProfile,
        data: data,
      })
      return response.data
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async updateUsers(users: NewUser[], environmentId: string): Promise<User[]> {
    let axios = await getAxios()

    let data = {
      users,
      environmentId,
    }

    try {
      let response = await axios({
        method: 'post',
        url: endpoints.users(environmentId),
        data: data,
      })
      return response.data
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async updateUser(user: NewUser, environmentId: string): Promise<User> {
    let axios = await getAxios()

    let data = {
      user,
    }

    if(user.id === undefined) {
      throw new Error('User ID is required to update user')
    }

    try {
      let response = await axios({
        method: 'patch',
        url: endpoints.user(environmentId, user.id),
        data: data,
      })
      return response.data
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async deleteUsers(userIds: string[], environmentId: string) {
    let axios = await getAxios()

    try {
      await axios({
        method: 'delete',
        url: endpoints.users(environmentId),
        data: [...userIds],
      })
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async getEnvironmentUsers(environmentId: string) {
    let axios = await getAxios()
    let url = endpoints.users(environmentId)
    
    try {
      const response = await axios({
        method: 'get',
        url,
      })
      return response.data as User[]
    } catch (e) {
      return []
    }
    
  },

  async getCurrentUserInfo(): Promise<GetUserInfoResponse> {
    let axios = await getAxios()

    try {
      let response = await axios.get<GetUserInfoResponse>(endpoints.userProfile)

      return response.data
    } catch (e) {
      return null
    }
  },

  // environment route?
  async updateDefaultEnvironmentId(environmentId: string): Promise<User> {
    let axios = await getAxios()

    try {
      const data = {environmentId}
      const response = await axios({
        method: 'patch',
        url: endpoints.defaultEnvironment(environmentId),
        data,
      })

      return response.data
    } catch (e) {
      return null
    }
  },

  async getUserGroups(userId: string, environmentId: string): Promise<Group[]> {
    let axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.userGroups(environmentId, userId),
    })

    return response.data.userGroups
  },

  async getExportUsers(
    environmentId: string,
    includeReports: boolean,
    userIds: string[]
  ): Promise<UserWithGroups[]> {
    let axios = await getAxios()

    const data = {
      includeReports,
      userIds,
    }

    const response = await axios({
      method: 'post',
      url: endpoints.exportUsers(environmentId),
      data,
    })
    const exportUsersData = response.data.export

    const formattedUsers = exportUsersData.map((user: UserWithGroups) => {
      return {
        ...user,
        groups: user.groups?.map((group: any) => group.name) || [],
        reports: user.reports?.map((report: any) => report.name) || [],
      }
    })
    return formattedUsers
  },
}
