import { useMutation, useQueryClient } from 'react-query'
import { reportApi } from '../../api-interface'
import { ApiReport } from '../../models'
import { getDashboardKey, getEnvironmentReportsKey } from '../queries'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

type CreateReportContext = {
  previousReports: ApiReport[]
}

export function useCreateReport() {
  const queryClient = useQueryClient()

  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(reportApi.createReport, {
    async onMutate(newReport): Promise<CreateReportContext> {
      await queryClient.cancelQueries([getEnvironmentReportsKey, environmentId])

      let previousReports: ApiReport[] = await queryClient.getQueryData([
        getEnvironmentReportsKey,
        environmentId,
      ])
      // Have to destructure the ApiReport out of the ReportToCreate
      const { appRegistrationNodeId, viewers, ...newApiReport } = newReport
      queryClient.setQueryData(
        [getEnvironmentReportsKey, environmentId],
        (old: any) => {          
          if(old === undefined) {
            return [newApiReport]
          }
          return [...old, newApiReport]
        }
      )

      // Return the context passed on onError
      return { previousReports }
    },
    onError(err, newUser, context: CreateReportContext) {
      queryClient.setQueryData(
        [getEnvironmentReportsKey, environmentId],
        context.previousReports
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getEnvironmentReportsKey, environmentId])
      queryClient.invalidateQueries([getDashboardKey, environmentId])
    },
  })
}
