import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'
import { ApiReport, ReportsFromUser } from '../../models'
import { environmentAtom } from '../../state'
import {
  getReportsByUserKey,
  getEnvironmentReportsKey,
  getDashboardKey,
} from '../queries'

type AddReportsToUserPayload = {
  reportIds: string[]
  viewerId: string
}

/** Add viewer to a PBI reports */
export function useAddReportsToUser() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddReportsToUserPayload) =>
      reportApi.addViewerToReports(
        payload.reportIds,
        payload.viewerId,
        environmentId
      ),
    {
      async onMutate(
        payload: AddReportsToUserPayload
      ): Promise<ReportsFromUser> {
        await queryClient.cancelQueries([getReportsByUserKey, payload.viewerId])
        await queryClient.cancelQueries([
          getEnvironmentReportsKey,
          environmentId,
        ])

        let allReports: ApiReport[] = queryClient.getQueryData([
          getEnvironmentReportsKey,
          environmentId,
        ])

        if (!allReports) {
          allReports = await queryClient.fetchQuery(
            [getEnvironmentReportsKey, environmentId],
            () => reportApi.getEnvironmentReports(environmentId)
          )
        }

        const previousReports: ReportsFromUser = queryClient.getQueryData([
          getReportsByUserKey,
          payload.viewerId,
        ])

        queryClient.setQueryData(
          [getReportsByUserKey, payload.viewerId],
          (old: ReportsFromUser) => {
            const reportsBeingAdded = allReports?.filter((r: ApiReport) =>
              payload.reportIds.includes(r.id)
            )
            const reportsFromUser = old?.sharedReports || []
            const userReports = [...reportsFromUser, ...reportsBeingAdded]

            return {
              ...old,
              sharedReports: userReports,
            }
          }
        )

        return previousReports
      },
      onError(
        err: Error,
        payload: AddReportsToUserPayload,
        previousReports: ReportsFromUser
      ) {
        queryClient.setQueryData(
          [getReportsByUserKey, payload.viewerId],
          previousReports
        )
      },
      // Always refetch after error or success:
      onSettled(
        _data,
        _error,
        payload: AddReportsToUserPayload,
        _previousGroups
      ) {
        queryClient.invalidateQueries([getReportsByUserKey, payload.viewerId])
        queryClient.invalidateQueries([getEnvironmentReportsKey, environmentId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
