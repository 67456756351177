export type LicenseSubscriptionStatus =
  | 'APPROVAL_PENDING'
  | 'APPROVED'
  | 'ACTIVE'
  | 'SUSPENDED'
  | 'CANCELLED'
  | 'EXPIRED'


export type AppLicense = {
  licenseSku: 'basic' | 'enterprise'
  id: string
  licenseStartDate: string
  trialEndDate: string
  isTrial: boolean
  trialDaysLeft: number
}

export type License = {
  id: string
  subscriptionPlanId?: string
  allowedUserCount: number
  currentUserCount: number
  isAdmin: boolean
  isOwner?: boolean
  company?: string
  subscriptionStatus?: LicenseSubscriptionStatus
  subscriptionId?: string
  subscriptionEndDate?: string
}

export const getLicenseState = (
  license: License
): {valid: boolean; status?: LicenseSubscriptionStatus; expired: boolean} => {
  if (!license) return {valid: false, status: 'EXPIRED', expired: true}

  // A Environment is valid if:
  // 1. There is no subscription status (i.e. not a PayPal Environment)
  // 2. It is a PayPal Environment and is Active
  // 3. It is a Suspended/Cancelled PayPal Environment that has not yet expired
  const licenseExpired = new Date(license.subscriptionEndDate) < new Date()
  const validlicense =
    !license.subscriptionStatus ||
    (license.subscriptionStatus && license.subscriptionStatus === 'ACTIVE') ||
    (license.subscriptionStatus &&
      (license.subscriptionStatus === 'SUSPENDED' ||
        license.subscriptionStatus === 'CANCELLED') &&
      !licenseExpired)

  return {
    valid: validlicense,
    status: license.subscriptionStatus,
    expired: licenseExpired,
  }
}
