import React from 'react'
import { Box, Typography } from '@mui/material'

interface KpiCardProps {
  value: string | number
  label: string
}

export const KpiCard: React.FC<KpiCardProps> = ({ value, label }) => {
  return (
    <Box>
      <Typography
        variant='h6'
        sx={{
          fontWeight: 700,
          color: '#333',
          lineHeight: 1.2,
          textTransform: 'capitalize',
        }}
      >
        {value}
      </Typography>
      <Typography
        variant='subtitle1'
        sx={{
          color: '#666',
          fontWeight: 400,
          mt: 0.5,
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}
