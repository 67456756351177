import { AppTheme } from '../models'
import { getAxios, endpoints } from './util'

export const themeApi = {
  async createTheme(environmentId:string, theme: AppTheme) {
    let axios = await getAxios()
    await axios({
      method: 'post',
      url: endpoints.theme(environmentId),
      data: {theme},
    })
  },

  async getTheme(environmentId: string) {
    let axios = await getAxios()
    let url = endpoints.theme(environmentId)
    let response = await axios({
      method: 'get',
      url,
    })

    return response.data
  },
}
