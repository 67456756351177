import { Box, Theme } from '@mui/material'

import { makeStyles } from '@mui/styles'
import { PowerBIEmbed } from 'powerbi-client-react'
import { environmentAtom } from '../../../../../state'
import { models, Report } from 'powerbi-client'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'

import {
  PBIEmbeddedReportPage,
  ProfileAndAppReg,
  ReportSettings,
} from './types'
import { powerBiApi, reportApi } from '../../../../../api-interface'
import { NewReportEmbedRequest } from '../../../../../models'
import { StatusMessage } from '../../../shared'
import { FullPageLoader } from '../../../../../components'

const useStyles = makeStyles((theme: Theme) => ({
  embedWrapper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '400px',
    width: '100%',
  },

  embeddedReport: {
    '& iframe': {
      border: 'none',
    },
    flex: 1,
    height: '400px',
    width: '100%',
  },
}))

type ReportPreviewProps = {
  reportSettings: ReportSettings
  profile: ProfileAndAppReg
  existingReportId: string
  rlsRoles?: string[]
  username?: string
  setPBIPages: (pages: PBIEmbeddedReportPage[]) => void
}

export function ReportPreview(props: ReportPreviewProps) {
  const {
    reportSettings,
    profile,
    rlsRoles,
    existingReportId,
    username,
    setPBIPages,
  } = props
  const classes = useStyles()
  const [embedAccess, setEmbedAccess] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const environmentId = useRecoilValue(environmentAtom)
  const reportRef = useRef<Report>()
  const fetchEmbedAccess = useCallback(async () => {
    try {
      let request = existingReportId
        ? existingReportId
        : {
            environmentId,
            pbiReportId: reportSettings.pbiReportId,
            appRegNodeId: profile.appRegNodeId,
            appClientId: profile.appRegId,
            appRegTenantId: profile.tenantId,
            workspaceId: reportSettings.pbiWorkspaceId,
            profileId: profile.id,
            rlsRoles: rlsRoles,
            username,
          }

      let embedAccess = existingReportId
        ? await powerBiApi.embedAccess(environmentId, request as string)
        : await reportApi.embedAccess(request as NewReportEmbedRequest)

      setEmbedAccess(embedAccess)
    } catch (e) {
      let message = e.message
      if (e.message === 'Report Access Error') {
        message =
          'There was an issue authenticating access for this Report.  If this persists, try updating the App Registration associated with this report.'
      } else {
        message = e.response.data.errorMessage
      }
      setErrorMessage(message)
      return
    }
  }, [
    existingReportId,
    environmentId,
    reportSettings.pbiReportId,
    reportSettings.pbiWorkspaceId,
    profile,
    rlsRoles,
    username,
  ])

  useEffect(() => {
    fetchEmbedAccess()
  }, [fetchEmbedAccess])

  const setPages = useCallback(async () => {
    if (!reportRef?.current) return
    const pbiPages = await reportRef?.current?.getPages()
    const formattedPages: PBIEmbeddedReportPage[] = pbiPages.map(page => ({
      name: page.name,
      displayName: page.displayName,
      isActive: page.isActive,
      visibility: page.visibility,
    }))
    setPBIPages(formattedPages)
  }, [setPBIPages])

  if (!embedAccess) {
    return (
      <div className={classes.embeddedReport}>
        {errorMessage ? (
          <StatusMessage
            status={{
              type: 'error',
              message: errorMessage,
              title: 'Failed to load report',
            }}
          />
        ) : (
          <FullPageLoader />
        )}
      </div>
    )
  }

  return (
    <Box className={classes.embedWrapper}>
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id: embedAccess.id,
          embedUrl: embedAccess.embedUrl,
          accessToken: embedAccess.accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: reportSettings.filterPaneEnabled,
            navContentPaneEnabled: reportSettings.showPageNavigation,
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
            },
          },
          pageName: reportSettings.defaultPageName || undefined,
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                setPages()
              },
            ],
            [
              'rendered',
              function () {
                // setPages()
              },
            ],
            ['error', function (event) {}],
          ])
        }
        cssClassName={classes.embeddedReport}
        getEmbeddedComponent={embeddedReport => {
          reportRef.current = embeddedReport as Report
          // setPages()
        }}
      />
    </Box>
  )
}
