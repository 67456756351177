import {FC} from 'react'
import {useGetGroupUsers, useGetOtherUsersOnEnvironment} from '../../../../hooks'
import {useAddUsersToGroup} from '../../../../hooks/mutations/useAddUsersToGroup'
import {Group, User} from '../../../../models'
import {LinkItemsMenu} from '../../shared'

type LinkUsersMenuProps = {
  group: Group
}

export const LinkUsersMenu: FC<LinkUsersMenuProps> = (
  props: LinkUsersMenuProps
) => {
  const {group} = props
  const {data: users} = useGetOtherUsersOnEnvironment()
  const {data: linkedUsers} = useGetGroupUsers(group.id)
  const {mutate: addUsers} = useAddUsersToGroup()

  const linkedUsersNotLoaded = linkedUsers
    ?.map((u: User) => {
      return !!(u?.firstName && u?.lastName && u?.email)
    })
    .some((u: boolean) => u === false)

  const linkedUserIds =
    (linkedUsers &&
      !linkedUsersNotLoaded &&
      linkedUsers.map((u: User) => u.email)) ||
    []
  const usersAvailableToAdd =
    (users &&
      linkedUserIds &&
      users.filter((u: User) => !linkedUserIds.includes(u.email))) ||
    []

  const linkUsers = (userIds: string[]) => {
    addUsers({
      groupId: group.id,
      userIds: userIds,
    })
  }

  return (
    <LinkItemsMenu
      onConfirm={linkUsers}
      addButtonLabel='Add Users'
      placeHolderText='Search users'
      availableItems={
        (usersAvailableToAdd &&
          usersAvailableToAdd.map((u: User) => {
            return {
              id: u.email,
              name: `${u.firstName} ${u.lastName}`,
              username: u.email,
            }
          })) ||
        []
      }
    />
  )
}
