import {
  CircularProgress,
  Icon,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Folder, FolderItem } from '../../../../models'
import { useState, useRef } from 'react'
import { useRoutes } from '../../../../hooks'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import { useHistory } from 'react-router-dom'
import { AddToFolderOptions } from './add-to-folder-options'
import { FolderItemTypeToIcon } from '../util'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useCreateFolder } from '../../../../hooks/mutations/useCreateFolder'
import { useFavorites } from '../../../../hooks/useFavorites'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { AccessDeniedDialog } from './access-denied-dialog'

type FolderItemProps = {
  item: FolderItem
  provided: any
  onRemove: () => void
  disableEdit?: boolean
  foldersAvailable: Folder[]
  isFavoritesFolder?: boolean
}

export function SingleFolderItem(props: FolderItemProps) {
  const {
    item,
    provided,
    onRemove,
    disableEdit,
    foldersAvailable,
    isFavoritesFolder,
  } = props

  const routes = useRoutes()
  const history = useHistory()
  const moreButtonRef = useRef<HTMLButtonElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [isAddingToFolder, setIsAddingToFolder] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const { isLoadingFavorites, toggleFavorite } = useFavorites(item)
  const [isAccessDeniedDialogOpen, setIsAccessDeniedDialogOpen] =
    useState(false)
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (moreButtonRef.current) {
      setPopoverOpen(true)
    }
  }

  const handleAddToFavorites = async (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation()
    await toggleFavorite()
    setPopoverOpen(false)
  }

  const handlePopoverClose = () => {
    setPopoverOpen(false)
    setIsAddingToFolder(false)
  }

  const onItemClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    if (item.accessDenied) return
    if (item.type === 'bookmark' && item.reportId) {
      history.push(
        routes.reports.report({
          id: item.reportId,
          bookmark: item.id,
        })
      )
      return
    }
    history.push(
      routes.reports.report({
        id: item.id,
      })
    )
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    if (!isAddingToFolder) {
      setPopoverOpen(false)
    }
  }

  const handleAccessDeniedClicked = () => {
    setIsAccessDeniedDialogOpen(true)
  }

  const id = popoverOpen ? `simple-popover-${item.id}` : undefined

  const hideMoreButton = item.accessDenied && (disableEdit || isFavoritesFolder)

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={'space-between'}
      gap={1}
      sx={{
        borderRadius: '8px',
        py: 0.5,
        px: 1,
        '&:hover': {
          backgroundColor: item.accessDenied ? 'default' : 'rgba(0, 0, 0, 0.1)',
        },
        width: '100%',
        cursor: item.accessDenied ? 'default' : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
      onClick={onItemClicked}
      ref={provided.innerRef}
      {...(!disableEdit ? provided.draggableProps : {})}
    >
      <Stack
        direction='row'
        alignItems={'center'}
        gap={1}
        sx={{
          width: isHovered && !hideMoreButton ? '90%' : '94%',
        }}
      >
        {item.accessDenied ? (
          <Tooltip
            title={<Typography fontSize={'14px'}>Access Denied</Typography>}
          >
            <IconButton
              sx={{
                color: theme => theme.palette.warning.main,
                height: '24px',
                width: '24px',
              }}
              onClick={() => handleAccessDeniedClicked()}
            >
              <ErrorOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Icon
            {...provided.dragHandleProps}
            sx={{
              cursor: disableEdit ? 'inherit' : 'grab',
            }}
          >
            <FolderItemTypeToIcon type={item.type} />
          </Icon>
        )}
        <Typography
          fontSize={'14px'}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: 1,
            fontStyle: item.accessDenied ? 'italic' : 'normal',
          }}
        >
          {item.name}
        </Typography>
      </Stack>

      {!hideMoreButton && (
        <IconButton
          onClick={handleMoreClicked}
          ref={moreButtonRef}
          sx={{
            height: '24px',
            width: '24px',
            visibility: isHovered ? 'visible' : 'hidden',
            '&:hover': {
              color: theme => theme.palette.primary.light,
            },
            zIndex: 10,
          }}
        >
          <MoreHorizOutlinedIcon />
        </IconButton>
      )}
      <AccessDeniedDialog
        item={item}
        handleDelete={() => onRemove()}
        open={isAccessDeniedDialogOpen}
        onClose={() => setIsAccessDeniedDialogOpen(false)}
        disableEdit={disableEdit}
      />
      <Popover
        id={id}
        open={popoverOpen && !!moreButtonRef.current} // Ensure ref exists
        anchorEl={moreButtonRef.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '8px' } },
        }}
      >
        {isAddingToFolder ? (
          <AddToFolderOptions
            folders={foldersAvailable?.filter(folder => !folder.isFavorites)}
            handleAddToFolder={async (
              folderId: string,
              newFolderName?: string
            ) => {
              if (!item.id) return
              if (newFolderName && folderId === '') {
                await createFolder({
                  folderName: newFolderName,
                  items: [
                    {
                      id: item.id,
                      type: item.type || 'report',
                      name: item.name,
                    },
                  ],
                })
                setPopoverOpen(false)
                return
              }

              const folder = foldersAvailable?.find(
                folder => folder.id === folderId
              )

              if (!folder) return
              await updateFolder({
                folderId: folder.id,
                items: [
                  ...folder.items,
                  { id: item.id, type: item.type || 'report', name: item.name },
                ],
              })
              setPopoverOpen(false)
            }}
            handleFolderClose={handlePopoverClose}
          />
        ) : (
          <Stack
            direction='column'
            sx={{
              backgroundColor: '#f0f4f8',
              p: 1,
            }}
            gap={1}
          >
            {!disableEdit && !isFavoritesFolder && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  borderRadius: '8px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme => theme.palette.primary.light,
                  },
                }}
                onClick={e => {
                  e.stopPropagation()
                  onRemove()
                  setPopoverOpen(false)
                }}
                spacing={1}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ mt: '2px', fontSize: '20px' }}
                />
                <Typography>Remove</Typography>
              </Stack>
            )}
            {!item.accessDenied && (
              <>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    '&:hover': {
                      color: theme => theme.palette.primary.light,
                    },
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    setIsAddingToFolder(true)
                  }}
                  spacing={1}
                >
                  <CreateNewFolderOutlinedIcon
                    sx={{ mt: '2px', fontSize: '20px' }}
                  />
                  <Typography>Add To Folder</Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    '&:hover': {
                      color: theme => theme.palette.primary.light,
                    },
                  }}
                  onClick={handleAddToFavorites}
                  spacing={1}
                >
                  {isLoadingFavorites ? (
                    <CircularProgress size={20} />
                  ) : item?.isFavorite ? (
                    <FavoriteIcon sx={{ mt: '2px', fontSize: '20px' }} />
                  ) : (
                    <FavoriteBorderIcon sx={{ mt: '2px', fontSize: '20px' }} />
                  )}
                  <Typography>
                    {item?.isFavorite ? 'Unfavorite' : 'Favorite'}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Popover>
    </Stack>
  )
}
