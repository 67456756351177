import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  ThemeOptions,
} from '@mui/material'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { RecoilRoot } from 'recoil'
import { Routes } from './routes'
import {
  convertAppThemeToMuiTheme,
  defaultTheme as defaultThemeOptions,
} from './utils/theme'
import { useGetTheme } from './hooks'
import { GlobalStyles } from './components/common'

export default function App() {
  const [isLoadingThemeOptions, setIsLoadingThemeOptions] = useState(true)

  return (
    <Providers setIsLoadingThemeOptions={setIsLoadingThemeOptions}>
      <CssBaseline />
      <GlobalStyles />
      <Routes isLoadingThemeOptions={isLoadingThemeOptions} />
    </Providers>
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24,
    },
  },
})

function Providers(props: {
  children: any
  setIsLoadingThemeOptions: (isLoading: boolean) => void
}) {
  const payPalEnabled = process.env.REACT_APP_PAYPAL_CLIENT_ID !== undefined
  return (
    <StyledEngineProvider injectFirst>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          {payPalEnabled ? (
            <PayPalScriptProvider
              options={{
                'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
                vault: true,
                intent: 'subscription',
              }}
            >
              <PbiThemeLoader
                setIsLoadingThemeOptions={props.setIsLoadingThemeOptions}
              >
                <Router>{props.children}</Router>
              </PbiThemeLoader>
            </PayPalScriptProvider>
          ) : (
            <PbiThemeLoader
              setIsLoadingThemeOptions={props.setIsLoadingThemeOptions}
            >
              <Router>{props.children}</Router>
            </PbiThemeLoader>
          )}
        </QueryClientProvider>
      </RecoilRoot>
    </StyledEngineProvider>
  )
}

const globalThemeOptions: ThemeOptions = {
  //Set global button styles:
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
        },
      },
    },
  },
}

function PbiThemeLoader(props: {
  children: any
  setIsLoadingThemeOptions: (isLoading: boolean) => void
}) {
  const { data: theme, isLoading: loadingThemeOptions } = useGetTheme()
  const [muiTheme, setMuiTheme] = useState<ThemeOptions>(defaultThemeOptions)

  useEffect(() => {
    props.setIsLoadingThemeOptions(loadingThemeOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingThemeOptions])

  useEffect(() => {
    const muiAppTheme = convertAppThemeToMuiTheme(theme)
    setMuiTheme(muiAppTheme || defaultThemeOptions)
  }, [theme])

  return (
    <ThemeProvider theme={createTheme({ ...muiTheme, ...globalThemeOptions })}>
      {props.children}
    </ThemeProvider>
  )
}
