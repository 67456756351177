import { Environment } from '../models'
import { getAxios, endpoints } from './util'

export const environmentApi = {
  async getEnvironment(environmentId: string): Promise<Environment> {
    const axios = await getAxios()

    const url = endpoints.environment(environmentId)
    // const url = getUrl(endpoints.environment, {environmentId: environmentId})

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data
  },

  async getAdminEnvironments(): Promise<Environment[]> {
    const axios = await getAxios()
    const response = await axios({
      method: 'get',
      url: endpoints.environments,
    })
    return response?.data
  },
  async updateEnvironment(payload: {
    environmentId: string
    environmentName: string
  }): Promise<Environment> {
    const axios = await getAxios()

    const url = endpoints.environment(payload.environmentId)

    const response = await axios({
      method: 'patch',
      url,
      data: { environmentName: payload.environmentName },
    })

    return response?.data
  },

  async createEnvironment(payload: {
    environmentName: string
  }): Promise<Environment> {
    const axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.environments,
      data: payload,
    })

    return response?.data
  },
  async deleteEnvironment(environmentId: string): Promise<void> {
    const axios = await getAxios()

    const url = endpoints.environment(environmentId)

    await axios({
      method: 'delete',
      url,
    })
  },
}
