import { useParams } from 'react-router-dom'
import {
  useGetDashboard,
  useGetEnvironment,
  useGetReportGroupReports,
  useGetFolders,
} from '../../../hooks'
import { useEffect, useState, useMemo } from 'react'
import { Layout } from '../../../components'
import { Stack, Typography } from '@mui/material'
import { ReportTiles, ReportTilesSkeleton } from './dashboard-components'
import { Folders, FolderSkeletons } from './folders'
import { Folder, FolderItem, getLicenseState } from '../../../models'

type DashboardViewProps = {
  isReportGroup?: boolean
}

export function DashboardView({ isReportGroup }: DashboardViewProps) {
  const { id: reportGroupId } = useParams<{ id?: string }>()
  const { data: environment, isLoading: environmentLoading } =
    useGetEnvironment()
  const { data: dashboard, isLoading: dashboardLoading } = useGetDashboard()
  const { data: reports, isLoading: reportsLoading } = useGetReportGroupReports(
    environment?.id,
    reportGroupId
  )
  const { data: folders, isLoading: foldersLoading } =
    useGetFolders(reportGroupId)
  const { data: userFolders } = useGetFolders() // For main dashboard or user-specific folders
  // const { data: favorites } = useGetFavorites()

  const validLicense = useMemo(
    () => getLicenseState(environment).valid,
    [environment]
  )
  const isLoading =
    environmentLoading || dashboardLoading || reportsLoading || foldersLoading

  // Dynamic skeleton counts (default to 6 reports, 2 folders)
  const [skeletonNumber, setSkeletonNumber] = useState(6)
  const [foldersSkeletonNumber, setFoldersSkeletonNumber] = useState(2)

  useEffect(() => {
    if (isReportGroup && reportGroupId) {
      const reportsInLocalStorage = localStorage.getItem(
        `reportGroupMembers${reportGroupId}`
      )
      if (reportsInLocalStorage) {
        setSkeletonNumber(parseInt(reportsInLocalStorage))
      }
      const foldersInLocalStorage = localStorage.getItem(
        `folders${reportGroupId}`
      )
      if (foldersInLocalStorage) {
        setFoldersSkeletonNumber(parseInt(foldersInLocalStorage) || 2)
      }
    }
    // For main dashboard, keep default values unless you want to add local storage logic here
  }, [isReportGroup, reportGroupId])

  useEffect(() => {
    if (isReportGroup && reportGroupId && reports && folders) {
      localStorage.setItem(
        `reportGroupMembers${reportGroupId}`,
        JSON.stringify(reports.length)
      )
      localStorage.setItem(
        `folders${reportGroupId}`,
        JSON.stringify(folders.length)
      )
    }
  }, [isReportGroup, reportGroupId, reports, folders])

  // Determine cards based on view type
  const cards = useMemo(() => {
    if (isReportGroup && reports) {
      return reports.filter(report => !!report.isKeyReport)
    }
    if (dashboard?.reports && dashboard?.reportGroups) {
      const { ownedReports, sharedReports } = dashboard.reports
      const reportGroups = dashboard.reportGroups.filter(
        group => group.showOnHome
      )
      return [
        ...reportGroups.map(group => ({ ...group, type: 'report-group' })),
        ...ownedReports,
        ...sharedReports,
      ]
        .filter(card => card?.showOnHome && card.id)
        .reduce((unique, card) => {
          if (!unique.find(c => c.id === card.id)) unique.push(card)
          return unique
        }, [])
    }
    return []
  }, [isReportGroup, reports, dashboard])

  // Process folders based on view type
  const processedFolders = useMemo(() => {
    if (isReportGroup && folders) {
      return folders.map(folder => ({
        ...folder,
        items: folder.items?.map(item => ({
          ...item,
          accessDenied: !reports?.find(
            r => r.id === item.id || r.id === item.reportId
          ),
        })),
        disableEdit: true,
      }))
    }
    // need to convert the reports to items for folders
    const myReportItems: FolderItem[] = dashboard?.reports?.ownedReports?.map(
      report => ({
        id: report.id,
        name: report.name,
        type: report.type,
      })
    )

    const sharedReports: FolderItem[] = dashboard?.reports?.sharedReports?.map(
      report => ({
        id: report.id,
        name: report.name,
        type: report.type,
      })
    )
    // create the my reports folder
    const myReportsFolder: Folder = {
      id: 'my-reports',
      name: 'My Reports',
      items: myReportItems,
      disableEdit: true,
    }
    if (sharedReports?.length === 0) {
      return [myReportsFolder, ...(dashboard?.folders || [])]
    }

    const sharedReportsFolder: Folder = {
      id: 'shared-reports',
      name: 'Shared Reports',
      items: sharedReports,
      disableEdit: true,
    }

    return [myReportsFolder, sharedReportsFolder, ...(dashboard?.folders || [])]
  }, [isReportGroup, folders, reports, dashboard])

  const selectedReportGroup =
    isReportGroup && reportGroupId
      ? dashboard?.reportGroups?.find(group => group.id === reportGroupId) ||
        null
      : null

  if (isLoading) {
    return <Layout loading={true} />
  }

  if (!validLicense) {
    return (
      <Layout>
        <Typography variant='h6' sx={{ mt: 10 }}>
          Please contact your administrator to reactivate the environment.
        </Typography>
      </Layout>
    )
  }

  if (isReportGroup && !selectedReportGroup && !isLoading) {
    return (
      <Layout
        error={true}
        errorMessage='Error: You do not have access to this report group or the report group does not exist'
      />
    )
  }

  return (
    <Layout>
      <Stack
        sx={{
          m: 'auto',
          mb: 8,
          maxWidth: '1280px',
          px: { xs: 3.5, sm: 4.5 },
          alignItems: 'center',
          gap: 4,
        }}
      >
        {cards.length === 0 ? (
          <Typography variant='h6' sx={{ mt: 10 }}>
            {isReportGroup ? 'No reports to display' : 'No items to display'}
          </Typography>
        ) : isLoading ? (
          <ReportTilesSkeleton numReports={skeletonNumber} />
        ) : (
          <ReportTiles
            title={selectedReportGroup?.name ?? ''}
            subtitle={selectedReportGroup?.description ?? ''}
            cards={cards}
          />
        )}
        {isLoading ? (
          <FolderSkeletons numFolders={foldersSkeletonNumber} />
        ) : (
          <Folders
            folders={processedFolders}
            userFolders={userFolders || folders}
          />
        )}
      </Stack>
    </Layout>
  )
}
