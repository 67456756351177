import {getAxios, endpoints} from './util'
import {EmbedAccess} from '../models'

export const powerBiApi = {
  async embedAccess(environmentId:string, reportId: string) {
    let axios = await getAxios()
    let response
    try {
      response = await axios({
        method: 'get',
        url: endpoints.reportEmbedAccess(environmentId, reportId),
      })
    } catch (e) {
      if (!e.response) {
        throw new Error('Report Access Error')
      }
      response = e.response
      throw new Error(response.data.errorMessage)
    }
    return response.data as EmbedAccess
  },
}
