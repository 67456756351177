import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getGroupsKey = 'get-groups'

export function useGetGroups() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getGroupsKey, environmentId],
    () => groupApi.getGroups(environmentId),
    {
      enabled: !!environmentId,
    }
  )
}
