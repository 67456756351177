import { Button, Stack, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ExistingItem, TabLayoutModal } from '../../shared'
import { AppRegistration } from '../../../../models'
import { useDeleteAppReg } from '../../../../hooks'
import { useState } from 'react'
import { LinkedProfiles } from './linked-profiles'
import { AddAppRegistrationForm } from './add-app-registration'
import { AddProfileForm } from './add-connection-profile-form'
import { ToastNotificationWithTimeout } from '../../../../components/common/Toast/ToastNotificationWithTimeout'
import DeleteAppRegDialog from '../users/dialogs/delete-appReg-dialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkedReportsTitle: {
      marginRight: theme.spacing(1),
    },
  })
)

export function ExistingAppRegistration(props: ExistingAppRegistrationProps) {
  const { appRegistration, isDisabled, refetch, totalNumAppRegistrations } =
    props
  const classes = useStyles()
  const { mutate: deleteAppReg } = useDeleteAppReg()
  const [isAppRegModalOpen, setIsAppRegModalOpen] = useState(false)
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [error, setError] = useState<string>('')
  const [isDeleteAppRegDialogOpen, setIsDeleteAppRegDialogOpen] =
    useState(false)

  async function handleDelete() {
    try {
      await deleteAppReg(appRegistration.id)
    } catch (e) {
      setError(e.message)
    }
    refetch()
  }

  async function handleDeleteAppRegClicked() {
    setIsDeleteAppRegDialogOpen(true)
  }

  function handleAddNewProfile() {
    setIsProfileModalOpen(true)
  }

  return (
    <>
      <ToastNotificationWithTimeout
        open={!!error}
        message={error}
        variant='error'
        onClose={() => {
          setError('')
        }}
        timeout={3000}
      />
      <ExistingItem
        key={appRegistration.id}
        name={appRegistration.name}
        secondaryLabel={appRegistration.appRegistrationId}
        //Only include on delete if there is more than 1 app reg:
        onDelete={
          totalNumAppRegistrations > 1 ? handleDeleteAppRegClicked : undefined
        }
        onEdit={isDisabled ? null : () => setIsAppRegModalOpen(true)}
      >
        <Stack direction='row'>
          <Typography
            variant='body1'
            display='inline'
            className={classes.linkedReportsTitle}
          >
            Linked Profiles
          </Typography>
          <Button
            variant='text'
            color='secondary'
            sx={{
              textTransform: 'none',
              height: '24px',
            }}
            onClick={handleAddNewProfile}
          >
            Add New Profile
          </Button>
        </Stack>
        <LinkedProfiles appRegistration={appRegistration} refetch={refetch} />
      </ExistingItem>
      <TabLayoutModal
        title='Add Connection Profile'
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
      >
        <AddProfileForm
          onClose={() => setIsProfileModalOpen(false)}
          onSave={() => {
            setIsProfileModalOpen(false)
            refetch()
          }}
          appReg={appRegistration}
          setError={setError}
        />
      </TabLayoutModal>
      {appRegistration && (
        <DeleteAppRegDialog
          open={isDeleteAppRegDialogOpen}
          onClose={() => setIsDeleteAppRegDialogOpen(false)}
          handleDelete={handleDelete}
          appReg={appRegistration}
          numberOfAppRegistrations={totalNumAppRegistrations}
        />
      )}

      <TabLayoutModal
        title='Edit App Registrations'
        isOpen={isAppRegModalOpen}
        onClose={() => setIsAppRegModalOpen(false)}
      >
        <AddAppRegistrationForm
          onClose={() => setIsAppRegModalOpen(false)}
          onSave={() => setIsAppRegModalOpen(false)}
          isEdit={true}
          appRegistrationToEdit={appRegistration}
        />
      </TabLayoutModal>
    </>
  )
}

type ExistingAppRegistrationProps = {
  appRegistration: AppRegistration
  isDisabled?: boolean
  refetch: () => void
  totalNumAppRegistrations: number
}
