import { ApiReport, ReportGroup, ReportGroupToCreate, User } from '../models'
import { buildQueryUrl, endpoints, getAxios } from './util'

export const reportGroupApi = {
  async createReportGroup(params: {
    reportGroup: ReportGroupToCreate
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.reportGroups(params.environmentId),
      data: { ...params.reportGroup },
    })
  },

  async getReportGroups(environmentId: string): Promise<ReportGroup[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.reportGroups(environmentId),
    })

    const reportGroups: ReportGroup[] = response.data

    return reportGroups
  },

  async deleteReportGroup(
    reportGroupId: string,
    environmentId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.reportGroup(environmentId, reportGroupId),
    })
  },

  async editReportGroup(params: {
    reportGroup: ReportGroupToCreate
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()
    await axios({
      method: 'patch',
      url: endpoints.reportGroup(params.environmentId, params.reportGroup.id),
      data: {
        ...params.reportGroup,
      },
    })
  },

  async getReportGroupReports(
    environmentId: string,
    reportGroupId: string
  ): Promise<ApiReport[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.reportGroupReports(environmentId, reportGroupId),
    })

    return response.data
  },

  async addReportGroupReports(params: {
    environmentId: string
    reportGroupId: string
    reportIds: string[]
    isKeyReport?: boolean
  }): Promise<void> {
    const axios = await getAxios()
    const isKeyReport = params.isKeyReport.toString()
    await axios({
      method: 'post',
      url: buildQueryUrl(
        endpoints.reportGroupReports(params.environmentId, params.reportGroupId),
        { isKeyReport }
      ),
      data: [...params.reportIds],
    })
  },

  async removeReportGroupReport(
    environmentId: string,
    reportGroupId: string,
    reportId: string,
    onlyRemoveKeyReport?: boolean
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.reportGroupReport(environmentId, reportGroupId, reportId),
      //Fix the api call
      data: { onlyRemoveKeyReport },
    })
  },

  async addReportGroupUsers(
    environmentId: string,
    reportGroupId: string,
    userIds: string[]
  ): Promise<void> {
    const axios = await getAxios()


    await axios({
      method: 'post',
      url: endpoints.reportGroupUsers(environmentId, reportGroupId),
      data: [...userIds],
    })
  },

  async getReportGroupUsers(
    environmentId: string,
    reportGroupId: string
  ): Promise<User[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.reportGroupUsers(environmentId, reportGroupId),
    })

    return response.data
  },

  async removeReportGroupUser(
    environmentId: string,
    reportGroupId: string,
    userId: string,
    onlyRemoveKeyReport?: boolean
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.reportGroupUser(environmentId, reportGroupId, userId),
      data: { onlyRemoveKeyReport },
    })
  },
}
