import {useQuery} from 'react-query'
import {bookmarkApi} from '../../api-interface'
import {useRecoilValue} from 'recoil'
import {environmentAtom} from '../../state'

export const getBookmarkMembersKey = 'get-bookmarks-members'

export function useGetBookmarkMember(reportId:string, bookmarkId: string) {
  const environmentId = useRecoilValue(environmentAtom)

  return useQuery(
    [getBookmarkMembersKey, bookmarkId],
    () => bookmarkApi.getBookmarkMember(environmentId, reportId, bookmarkId),
    {
      enabled: !!bookmarkId,
    }
  )
}
