import {useQuery} from 'react-query'
import {workspacesApi} from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

export const getWorkspaceReportsKey = 'get-workspace-reports'

export function useGetWorkspaceReports(
  appRegNodeId: string,
  appClientId: string,
  appRegTenantId: string,
  workspaceId: string,
  workspaceName?: string
) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getWorkspaceReportsKey, appRegNodeId, workspaceId],
    () =>
      workspacesApi.getWorkspaceReports(
        environmentId,
        appRegNodeId,
        appClientId,
        appRegTenantId,
        workspaceId,
        workspaceName
        
      ),
    {
      enabled: !!appRegNodeId && !!workspaceId && !!workspaceName,
    }
  )
}
