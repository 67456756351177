import { useMutation } from 'react-query'
import { reportApi } from '../../api-interface'
import {environmentAtom} from '../../state'
import {useRecoilValue} from 'recoil'

export const testReportConnectionKey = 'test-report-connection'

export type TestReportConnectionPayload = {
  pbiWorkspaceId: string
  itemId: string
  appRegistrationNodeId: string
  profileId: string
  type: string
}

export function useTestReportConnection() {
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation((payload: TestReportConnectionPayload) =>
    reportApi.testReportConnection(
      environmentId,
      payload.pbiWorkspaceId,
      payload.itemId,
      payload.appRegistrationNodeId,
      payload.profileId,
      payload.type
    )
  )
}
