import { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  useGetEnvironment,
  useGetClientPrincipal,
  useCurrentUserInfo,
  useGetAppSettings,
  useGetLicense,
} from './hooks'
import { AdminPage, LandingPage, ReportsPage } from './pages'
import { useRecoilState } from 'recoil'
import { environmentAtom } from './state'
import { getLicenseState, UserFullName } from './models'
import { useUpdateUserName } from './hooks/mutations/useUpdateUserName'
import { Pricing } from './pages/paypal'
import { useLicenseRouting } from './useLicenseRouting'
import { ReportGroupsPage } from './pages/dashboard/report-groups-page'
import { DashboardPage } from './pages/dashboard/dashboard-page'
import { Layout } from './components'
import { InitialSetup } from './pages'

export const routes = {
  reports: {
    index: '/reports',
    report: ({ id, bookmark }: { id: string; bookmark?: string }) =>
      `/reports/${id}${bookmark ? `/${bookmark}` : ''}`,
  },
  reportGroups: {
    index: '/report-groups',
    group: ({ id }: { id: string }) => `/report-groups/${id}`,
  },
  dashboard: {
    index: '/',
  },
  admin: {
    index: '/admin',
    reports: '/admin/reports',
    reportGroups: '/admin/report-groups',
    users: '/admin/users',
    groups: '/admin/groups',
    workspaces: '/admin/workspaces',
    appReg: '/admin/connection-profiles',
    appearance: '/admin/appearance',
    subscription: '/admin/subscription',
  },
}

export function Routes(props: { isLoadingThemeOptions: boolean }) {
  const { data: clientPrincipal, isFetched: isClientFetched } =
    useGetClientPrincipal()
  const { data: license, isFetched: isLicenseFetched } = useGetLicense()
  const { data: appSettings, isFetched: appSettingsFetched } =
    useGetAppSettings()
  const { data: userInfo, isFetched: userInfoFetched } = useCurrentUserInfo()
  const { data: environment, isFetched: isEnvironmentFetched } =
    useGetEnvironment()

  const { mutate: updateUserName } = useUpdateUserName()
  const [currentEnvironment, setCurrentEnvironment] =
    useRecoilState(environmentAtom)

  useLicenseRouting()

  useEffect(() => {
    setupCurUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoFetched, userInfo, currentEnvironment])

  const setupCurUser = () => {
    if (userInfo && userInfo.environments?.[0]) {
      // Set the Current environment if it does not exist or it is stale (i.e. not part of User Info Environments)
      const hasCurrentEnvironment = userInfo.environments.find(
        environment => environment.id === currentEnvironment
      )
      if (!currentEnvironment || !hasCurrentEnvironment) {
        const defaultEnvironment =
          userInfo.environments.find(environment => environment.default) ||
          userInfo.environments[0]
        setCurrentEnvironment(defaultEnvironment.id)
      }

      // For New Users, update UserFullName based upon claims
      if (userInfo.newUser) {
        const name = clientPrincipal.claims.filter(c => c.typ === 'name')[0].val
        const userName: UserFullName = {
          firstName: name.split(' ')[0],
          lastName: name.split(' ')[1],
        }
        updateUserName({ userName })
      }
    }
  }

  // User is not authenticated
  const userEmail = clientPrincipal?.userDetails
  if (userInfoFetched && !userEmail) {
    return <GoToLandingPage />
  }

  console.log({
    userInfoFetched,
    userInfo,
    isClientFetched,
    isLicenseFetched,
    license,
    isEnvironmentFetched,
    environment,
    appSettingsFetched,
    appSettings,
  })
  // LicenseObject is not found
  if (
    (isLicenseFetched && !license) ||
    (isEnvironmentFetched && !environment)
  ) {
    return appSettings.payPalEnabled ? <Pricing /> : <InitialSetup />
  }

  if (userInfoFetched && !userInfo?.user) {
    // need to redirect to login with a parmaeter to show the error message
    // log the user out /.auth/logout and then redirect them to the login page
    window.location.href =
      '/.auth/logout?post_logout_redirect_uri=/?userNotFound=true'
  }
  //user info -> environment -> theme -> app settings -> anything else
  const currentItemLoading = !userInfoFetched
    ? 'user info'
    : !isClientFetched
    ? 'client'
    : !isLicenseFetched
    ? 'license'
    : !isEnvironmentFetched
    ? 'environment configurations'
    : props.isLoadingThemeOptions
    ? 'theme'
    : !appSettingsFetched
    ? 'app settings'
    : 'user data'

  // Page is Loading
  if (
    !appSettingsFetched ||
    !isEnvironmentFetched ||
    !isClientFetched ||
    !userInfoFetched ||
    !environment
  ) {
    return (
      <Layout
        loading={true}
        loadingMessage={`Fetching ${currentItemLoading}...`}
      />
    )
  }

  // Cancelled + Expired Environment
  // Owner should be redirected to Pricing
  const environmentState = getLicenseState(environment)
  if (
    isEnvironmentFetched &&
    environmentState.status === 'CANCELLED' &&
    environmentState.expired &&
    environment.isOwner
  ) {
    return <Pricing />
  }
  const isAdmin = environment.isAdmin || environment.isOwner

  return (
    <Switch>
      <Route path={routes.admin.index}>
        {isAdmin && <AdminPage />}
        {!isAdmin && <Redirect to={routes.reports.index} />}
      </Route>
      <Route path={routes.reportGroups.index}>
        <ReportGroupsPage />
      </Route>
      <Route path='/reports'>
        <ReportsPage />
      </Route>
      <Route path='/'>
        <DashboardPage />
      </Route>
      <Route>
        <Redirect to='/' /> {/* Fallback */}
      </Route>
    </Switch>
  )
}

export function GoToLandingPage() {
  return (
    <Switch>
      <Route path='/'>
        <LandingPage />
      </Route>
      <Route>
        <Redirect to='/' />
      </Route>
    </Switch>
  )
}
