import { useMutation, useQueryClient } from 'react-query'
import { ReportData } from '../../models'
import { environmentAtom } from '../../state'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'
import { getDashboardKey } from '../queries'

type CreatePaginatedReportContext = {
  previousReport: ReportData
}

const postPaginatedReportKey = 'post-paginated-report'

export function useCreatePaginatedReport() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(reportApi.createPaginatedReport, {
    async onMutate(newPaginatedReport): Promise<CreatePaginatedReportContext> {
      await queryClient.cancelQueries([postPaginatedReportKey, environmentId])

      let previousPaginatedReport: any = queryClient.getQueryData([
        postPaginatedReportKey,
        environmentId,
      ])

      queryClient.setQueryData(
        [postPaginatedReportKey, environmentId],
        newPaginatedReport
      )

      // Return the context passed on onError
      return { previousReport: previousPaginatedReport }
    },
    onSettled() {
      queryClient.invalidateQueries([getDashboardKey, environmentId])
    },
  })
}
