import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {bookmarkApi} from '../../api-interface'
import {environmentAtom} from '../../state'
import {
  getBookmarkMembersKey,
  getBookmarksKey,
} from '../queries'

type AddDefaultBookmarkPayload = {
  bookmarkId: string
}

/** Add Bookmark to the Environment as a member */
export function useAddDefaultBookmark(reportId:string) {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddDefaultBookmarkPayload) =>
      bookmarkApi.addDefaultBookmark(environmentId, reportId, payload.bookmarkId),
    {
      onSettled() {
        queryClient.invalidateQueries(getBookmarkMembersKey)
        queryClient.invalidateQueries(getBookmarksKey)
      },
    }
  )
}
