import { useMutation, useQueryClient } from 'react-query'
import { reportApi } from '../../api-interface'
import { ApiReport } from '../../models'
import { getDashboardKey, getEnvironmentReportsKey } from '../queries'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

type CreateReportContext = {
  previousReports: ApiReport[]
}

export function useUpdateReport() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(reportApi.updateReport, {
    async onMutate(newReport): Promise<CreateReportContext> {
      await queryClient.cancelQueries([getEnvironmentReportsKey, environmentId])

      let previousReports: ApiReport[] = await queryClient.getQueryData([
        getEnvironmentReportsKey,
        environmentId,
      ])

      return { previousReports }
    },
    onError(err, newUser, context: CreateReportContext) {
      queryClient.setQueryData(
        [getEnvironmentReportsKey, environmentId],
        context.previousReports
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getEnvironmentReportsKey, environmentId])
      queryClient.invalidateQueries([getDashboardKey, environmentId])
    },
  })
}
