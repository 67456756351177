import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {appRegApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getAppRegistrationItemsKey = 'get-app-registration-items'

export function useGetAppRegItems(appRegId: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getAppRegistrationItemsKey, appRegId, environmentId],
    () => appRegApi.getAppRegistrationItems(environmentId, appRegId),
    {
      enabled: !!environmentId && !!appRegId,
    }
  )
}
