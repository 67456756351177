import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'
import { environmentAtom } from '../../state'

export const getReportKey = 'get-report'

export function useGetReport(reportId: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getReportKey, environmentId, reportId],
    () => reportApi.getReport(environmentId, reportId),
    {
      enabled: !!environmentId && !!reportId,
    }
  )
}
