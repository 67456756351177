import {
  alpha,
  Collapse,
  Divider,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import { Folder } from '../../../../models'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../../create-custom-report/custom-report-table/strict-mode-dropable'
import ManageFolderDialog from '../manage-folder-dialog'
import { SingleFolderItem } from './single-folder-item'

type FolderProps = {
  folder: Folder
  numFolders: number
  userFolders: Folder[]
}

export function SingleFolder(props: FolderProps) {
  const { folder, numFolders, userFolders } = props
  const { name, items, id, disableEdit } = folder

  const { mutateAsync: updateFolder } = useUpdateFolder()

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return
    const sortableItems = Array.from(items)
    const [reorderedItem] = sortableItems.splice(result.source.index, 1)
    sortableItems.splice(result.destination.index, 0, reorderedItem)
    await updateFolder({ items: sortableItems, folderId: id })
  }

  const onRemoveFolderItem = async (itemId: string) => {
    const newItems = items.filter(item => item.id !== itemId)
    await updateFolder({ items: newItems, folderId: id })
  }

  const [isExpanded, setIsExpanded] = useState(items?.length > 0)
  const [openFolderSettings, setOpenFolderSettings] = useState(false)

  const folderSpacing = numFolders > 2 ? 3 : numFolders > 1 ? 2 : 1

  if (folder.isFavorites && folder.items?.length === 0) {
    return null
  }

  return (
    <Stack
      direction={'column'}
      sx={{
        width: `calc(100% / ${folderSpacing} - ${
          folderSpacing === 1 ? 0 : 32
        }px)`,
        minWidth: '340px',
        borderRadius: '8px',
        backgroundColor: theme => alpha(theme.palette.background.paper, 0.5),
        height: '100%',
        maxHeight: '288px',
        overflow: 'hidden',
      }}
      onClick={() => {
        if (!isExpanded) setIsExpanded(true)
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
        sx={{
          p: 1,
          width: '100%',
          position: 'sticky !important',
          top: 0,
          backgroundColor: theme => alpha(theme.palette.background.paper, 0.5),

          '&:hover': {
            backgroundColor: theme =>
              !isExpanded && alpha(theme.palette.secondary.light, 0.2),
            cursor: 'pointer',
          },
          zIndex: 100,
          height: '40px',
        }}
      >
        <Typography fontSize={'12px'} fontWeight={'bold'}>
          {name}
        </Typography>
        <Stack direction={'row'} alignItems={'center'}>
          {isExpanded && !disableEdit && folder.isOwnedByUser && (
            <Tooltip title={'Edit folder'} placement={'top'} arrow>
              <Icon
                sx={{
                  cursor: 'pointer',
                  ml: 1,
                  '&:hover': {
                    color: 'rgba(0, 0, 0, 0.5)',
                  },
                }}
                onClick={() => setOpenFolderSettings(true)}
              >
                <EditOutlinedIcon sx={{ fontSize: '16px', mb: '4px' }} />
              </Icon>
            </Tooltip>
          )}
          <Icon
            sx={{
              cursor: 'pointer',
              ml: 1,
              '&:hover': {
                color: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            {isExpanded ? (
              <ArrowDropUpOutlinedIcon
                onClick={() => setIsExpanded(!isExpanded)}
              />
            ) : (
              <ArrowDropDownOutlinedIcon
                onClick={() => setIsExpanded(!isExpanded)}
              />
            )}
          </Icon>
        </Stack>
      </Stack>

      <Collapse in={isExpanded} easing={'ease-in-out'}>
        <Divider
          sx={{
            mx: 1,
          }}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId='droppable'>
            {provided => (
              <Stack
                direction='column'
                gap={2}
                sx={{
                  p: 1,
                  my: 1,
                  minWidth: '340px',
                  maxHeight: '240px',
                  overflowY: 'auto',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items?.map((item, index) => (
                  <Draggable
                    key={name + item.id + index}
                    draggableId={name + item.id + index}
                    index={index}
                  >
                    {provided => (
                      <SingleFolderItem
                        item={item}
                        provided={provided}
                        onRemove={() => {
                          onRemoveFolderItem(item.id)
                        }}
                        disableEdit={disableEdit}
                        foldersAvailable={userFolders?.filter(
                          folder =>
                            folder.id !== id &&
                            !folder?.items?.find(
                              folderItem => folderItem.id === item.id
                            )
                        )}
                        isFavoritesFolder={folder?.isFavorites}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Collapse>
      {openFolderSettings && (
        <ManageFolderDialog
          folder={folder}
          open={openFolderSettings}
          onClose={() => setOpenFolderSettings(false)}
        />
      )}
    </Stack>
  )
}
