import { Folder, FolderItem } from '../models'
import { parseBoolean } from '../utils/parseBoolean'
import { getAxios, endpoints } from './util'

export const folderApi = {
  async createFolder(params: {
    folderName: string
    folderDescription?: string
    items?: FolderItem[]
    isFavorites?: boolean
    environmentId: string
    reportGroupId?: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.folders(params.environmentId),
      data: {
        folderName: params.folderName.replaceAll(/'/g, "\\'"),
        folderDescription: params?.folderDescription || undefined,
        items: params?.items ? JSON.stringify(params.items) : undefined,
        isFavorites: params?.isFavorites || undefined,
        reportGroupId: params?.reportGroupId || undefined,
      },
    })
  },

  async addItemToFavoritesFolder(params: {
    item: FolderItem
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.favoriteItems(params.environmentId),
      data: JSON.stringify(params.item),
    })
  },

  async removeItemFromFavoritesFolder(params: {
    itemId: string
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.favoriteItem(params.environmentId, params.itemId),
    })
  },
  async getFavoritesFolder(environmentId: string): Promise<Folder> {
    const axios = await getAxios()
    const response = await axios({
      method: 'get',
      url: endpoints.favorites(environmentId),
    })
    const formattedFolder = {
      ...response?.data,
      items: response?.data?.items
        ? (JSON.parse(response?.data?.items) as FolderItem[])
        : [],
    }
    return formattedFolder as Folder
  },

  async editFolder(params: {
    folderId: string
    folderName?: string
    items?: FolderItem[]
    environmentId: string
    reportGroupId?: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.folder(params.environmentId, params.folderId),
      data: {
        folderName: params?.folderName?.replaceAll(/'/g, "\\'") || undefined,
        items: params?.items
          ? JSON.stringify(
              params.items?.map(item => {
                return {
                  ...item,
                  name: item.name.replaceAll(/'/g, "\\'"),
                }
              })
            )
          : undefined,
        reportGroupId: params?.reportGroupId || undefined,
      },
    })
  },
  async addItemToFolder(params: {
    folderId: string
    item: FolderItem
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()
    const response = await axios({
      method: 'post',
      url: endpoints.folderItems(params.environmentId, params.folderId),
      data: params.item,
    })
    return response.data
  },
  async removeItemFromFolder(params: {
    folderId: string
    itemId: string
    environmentId: string
  }): Promise<void> {
    const axios = await getAxios()
    const response = await axios({
      method: 'delete',
      url: endpoints.folderItem(
        params.environmentId,
        params.folderId,
        params.itemId
      ),
    })
    return response.data
  },

  async deleteFolder(folderId: string, environmentId: string): Promise<void> {
    const axios = await getAxios()

    const response = await axios({
      method: 'delete',
      url: endpoints.folder(environmentId, folderId),
    })
    return response.data
  },

  async getFoldersForUser(
    environmentId: string,
    reportGroupId?: string
  ): Promise<Folder[]> {
    const axios = await getAxios()
    let url = reportGroupId
      ? endpoints.reportGroupFolders(environmentId, reportGroupId)
      : endpoints.folders(environmentId)

    const response = await axios({
      method: 'get',
      url,
    })

    const formattedFolders = response?.data?.map((folder: any) => ({
      ...folder,
      items: folder?.items ? (JSON.parse(folder?.items) as FolderItem[]) : [],
      isFavorites: parseBoolean(folder?.isFavorites),
    }))

    return formattedFolders as Folder[]
  },
}
