import React from 'react'

import { Alert, Stack, Typography } from '@mui/material'
import { Environment } from '../../../../../models'
import CustomDialog from '../../../shared/dialog/dialog'
import { useRemoveEnvironment } from '../../../../../hooks'

type RemoveEnvironmentDialogProps = {
  open: boolean
  onClose: () => void
  environment: Environment
  onRemove: () => void
}

export const RemoveEnvironmentDialog: React.FC<
  RemoveEnvironmentDialogProps
> = ({ open, onClose, environment, onRemove }) => {
  const { mutate: removeEnvironment } = useRemoveEnvironment()
  const handleRemove = async () => {
    // Logic to remove the environment
    await removeEnvironment(environment.id)
    onRemove()
    onClose()
  }
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title='Remove Environment'
      primaryButton
      primaryButtonProps={{
        children: 'Remove',
        onClick: handleRemove,
      }}
      secondaryButton
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: onClose,
      }}
      hideCloseButton
    >
      <Stack sx={{ p: 1 }} gap={2}>
        <Typography>
          Are you sure you want to remove the environment "{environment?.name}"?
        </Typography>
        <Alert severity='warning' sx={{}}>
          Users that belong to this environment will lose access.
        </Alert>
      </Stack>
    </CustomDialog>
  )
}
