import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getViewersKey = 'get-report-viewers'

export function useGetReportViewers(reportId: string) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery([getViewersKey, reportId], () =>
    reportApi.getViewers(reportId, environmentId)
  )
}
