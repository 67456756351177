import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {Environment, User} from '../../models'
import {environmentAtom} from '../../state'
import {getEnvironmentQueryKey, getOtherUsersOnEnvironmentKey} from '../queries'

export type DeleteUserPayload = {userIds: string[]; environmentId: string}

type DeleteUserContext = {
  previousUsers: User[]
}

export function useDeleteUsers() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)
  return useMutation(
    (payload: DeleteUserPayload) =>
      userApi.deleteUsers(payload.userIds, payload.environmentId),
    {
      async onMutate(
        deletedUsers: DeleteUserPayload
      ): Promise<DeleteUserContext> {
        await queryClient.cancelQueries([getOtherUsersOnEnvironmentKey, environmentId])

        let previousUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnEnvironmentKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getOtherUsersOnEnvironmentKey, environmentId],
          (old: User[]) =>
            old.filter(user => !deletedUsers.userIds.includes(user.email))
        )

        queryClient.setQueryData(
          [getEnvironmentQueryKey, environmentId],
          (old: Environment) => ({
            ...old,
            currentUserCount:
              old.currentUserCount - deletedUsers.userIds.length,
          })
        )

        return {previousUsers}
      },
      onError(_err, _newUser, context: DeleteUserContext) {
        queryClient.setQueryData(
          [getOtherUsersOnEnvironmentKey, environmentId],
          context.previousUsers
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getEnvironmentQueryKey, environmentId])
        queryClient.invalidateQueries([getOtherUsersOnEnvironmentKey, environmentId])
      },
    }
  )
}
