import CustomDialog from '../../../shared/dialog/dialog'
import { useState } from 'react'
import { Profile } from '../../../../../models'
import { useGetAppRegItems } from '../../../../../hooks/queries/useGetAppRegItems'
import { AffectedItemsDisplay, WarningMessage } from './affected-app-reg-items'

function DeleteProfileDialog(props: DeleteProfileDialogProps) {
  const { open, onClose, profile, handleDelete } = props

  const [deleteLoading, setDeleteLoading] = useState(false)

  const { data: items } = useGetAppRegItems(props.appRegNodeId)

  if (!profile?.id) return null

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`${profile.name}`}
      primaryButtonProps={{
        disabled: deleteLoading,
        children: 'Delete',
        onClick: async () => {
          setDeleteLoading(true)
          await new Promise(resolve => setTimeout(resolve, 2500))
          await handleDelete()
          setDeleteLoading(false)
          onClose()
        },
      }}
      secondaryButtonProps={{
        disabled: deleteLoading,
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <WarningMessage itemType='profile' />

      <AffectedItemsDisplay items={items} profileId={profile.id} />
    </CustomDialog>
  )
}

type DeleteProfileDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => void
  profile: Profile
  appRegNodeId: string
}

export default DeleteProfileDialog
