import {SubscriptionPlan} from '../models/paypal'
import {getAxios, endpoints} from './util'

export const paypalApi = {
  async getPaypalSubscriptionPlan(): Promise<SubscriptionPlan[]> {
    const axios = await getAxios()
    const response = await axios({
      method: 'get',
      url: endpoints.paypalSubscriptionPlan,
    })

    return response?.data?.plans
  },

  async postPaypalLicense(subscriptionId: string): Promise<string> {
    const axios = await getAxios()
    const response = await axios({
      method: 'post',
      url: endpoints.paypalLicense,
      data: {subscriptionId},
    })

    return response?.data?.id
  },

  async setPaypalLicenseStatus(
    environmentId: string,
    status: 'SUSPENDED' | 'ACTIVE'
  ): Promise<string> {
    const axios = await getAxios()
    const response = await axios({
      method: 'put',
      url: endpoints.paypalLicense,
      data: {environmentId, status},
    })

    return response?.data?.id
  },

  async cancelPaypalLicense(environmentNodeId: string): Promise<void> {
    const axios = await getAxios()
    await axios({
      method: 'DELETE',
      url: endpoints.paypalLicense,
      data: {subscriptionId: environmentNodeId},
    })
  },
}
