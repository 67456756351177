import { useMutation, useQueryClient } from 'react-query'
import { environmentApi } from '../../api-interface'

import {
  currentUserInfoKey,
  getAdminEnvironments,
  getEnvironmentQueryKey,
} from '../queries'

export function useRemoveEnvironment() {
  const queryClient = useQueryClient()

  return useMutation((id: string) => environmentApi.deleteEnvironment(id), {
    onSettled() {
      queryClient.invalidateQueries(getEnvironmentQueryKey)
      queryClient.invalidateQueries(getAdminEnvironments)
      queryClient.invalidateQueries(currentUserInfoKey)
    },
  })
}
