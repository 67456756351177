import { useMutation, useQueryClient } from 'react-query'
import { environmentApi } from '../../api-interface'

import {
  currentUserInfoKey,
  getAdminEnvironments,
  getEnvironmentQueryKey,
} from '../queries'

type CreateEnvironmentPayload = {
  environmentName: string
}

export function useCreateEnvironment() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: CreateEnvironmentPayload) =>
      environmentApi.createEnvironment(payload),
    {
      onSettled() {
        queryClient.invalidateQueries(getEnvironmentQueryKey)
        queryClient.invalidateQueries(getAdminEnvironments)
        queryClient.invalidateQueries(currentUserInfoKey)
      },
    }
  )
}
