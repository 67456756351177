import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'
import { GetReportViewersResponse, Group, User } from '../../models'
import { environmentAtom } from '../../state'
import { getDashboardKey, getViewersKey } from '../queries'

export const removeViewersKey = 'remove-viewers'

export type RemoveViewerPayload = { reportId: string; viewerId: string }

/** Remove a viewer from a PBI report */
export function useRemoveViewer() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveViewerPayload) =>
      reportApi.removeViewers(
        payload.reportId,
        payload.viewerId,
        environmentId
      ),
    {
      async onMutate(
        payload: RemoveViewerPayload
      ): Promise<GetReportViewersResponse> {
        await queryClient.cancelQueries([getViewersKey, payload.reportId])

        let previousViewers: GetReportViewersResponse =
          queryClient.getQueryData([getViewersKey, payload.reportId])

        if (!previousViewers) {
          previousViewers = await queryClient.fetchQuery(
            [getViewersKey, payload.reportId],
            () => reportApi.getViewers(payload.reportId, environmentId)
          )
        }

        queryClient.setQueryData(
          [getViewersKey, payload.reportId],
          (old: GetReportViewersResponse) => {
            const users = old.users.filter(
              (oldUser: User) => oldUser.id !== payload.viewerId
            )
            const groups = old.groups.filter(
              (oldGroup: Group) => oldGroup.id !== payload.viewerId
            )
            return {
              users,
              groups,
            }
          }
        )

        return previousViewers
      },
      onSettled: (data, error, payload: RemoveViewerPayload) => {
        queryClient.invalidateQueries([getViewersKey, payload.reportId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
      onError(
        _err,
        payload: RemoveViewerPayload,
        context: GetReportViewersResponse
      ) {
        queryClient.setQueryData([getViewersKey, payload.reportId], context)
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
