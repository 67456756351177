import { useRecoilValue } from "recoil"
import { environmentAtom } from "../../state"
import { useQuery } from "react-query"
import { reportGroupApi } from "../../api-interface"

export const getReportGroupsKey = 'get-report-groups'

export function useGetReportGroups() {
    const environmentId = useRecoilValue(environmentAtom)
    return useQuery(
        [getReportGroupsKey, environmentId],
        () => reportGroupApi.getReportGroups(environmentId),
        {
            enabled: !!environmentId,
        }
    )
}