import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { DashboardView } from '../reports/report-dashboard'

export function DashboardPage() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/report-groups/:id`}>
        <DashboardView isReportGroup={true} />
      </Route>
      <Route>
        <DashboardView isReportGroup={false} />
      </Route>
    </Switch>
  )
}
