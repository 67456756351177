import {useMutation, useQueryClient} from 'react-query'
import {reportApi} from '../../api-interface'
import {getEnvironmentReportsKey} from '../queries'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

type MakeDefaultPayload = {reportId: string}

/** Add viewers to a PBI report */
export function useAddDefaultReport() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: MakeDefaultPayload) =>
      reportApi.addDefault(payload.reportId, environmentId),
    {
  
      onSettled() {
        queryClient.invalidateQueries(getEnvironmentReportsKey)
      },
    }
  )
}
