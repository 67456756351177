import CustomDialog from '../../../shared/dialog/dialog'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { FormField } from '../../../shared'

function EditUsersDialog(props: EditUsersDialogProps) {
  const { open, onClose, users, loading, handleEdit } = props

  const [role, setRole] = useState<'viewer' | 'admin'>('viewer')

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      loading={loading}
      title={`Edit ${users.length} user${users.length > 1 ? 's' : ''}?`}
      primaryButtonProps={{
        children: 'Confirm',
        onClick: () => {
          handleEdit(role)
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <Box>
        <Typography variant='h6'>
          Select the role that will be assigned to the selected users.
        </Typography>

        <FormField
          label='User Role'
          value={role}
          onTextChange={setRole as any}
          helperText='The level of permission that this user will have on your Entelexos environment'
          selectOptions={{ viewer: 'Viewer', admin: 'Admin' }}
        />
      </Box>
    </CustomDialog>
  )
}

type EditUsersDialogProps = {
  open: boolean
  onClose: () => void
  handleEdit: (userRole: 'viewer' | 'admin') => void
  users: string[]
  loading?: boolean
}

export default EditUsersDialog
