import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { groupApi, userApi } from '../../api-interface'
import { Group } from '../../models'
import { environmentAtom } from '../../state'
import {
  getDashboardKey,
  getReportsByUserKey,
  getUserGroupsKey,
} from '../queries'

export const removeGroupFromUserKey = 'remove-group-from-user'

type RemoveGroupFromUserPayload = { groupId: string; userId: string }

/** Remove a viewer from a PBI report */
export function useRemoveGroupFromUser() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveGroupFromUserPayload) =>
      groupApi.removeGroupUser(payload.groupId, payload.userId, environmentId),
    {
      async onMutate(payload: RemoveGroupFromUserPayload): Promise<Group[]> {
        await queryClient.cancelQueries([getUserGroupsKey, payload.groupId])
        await queryClient.cancelQueries([getReportsByUserKey, payload.userId])

        let previousGroups: Group[] = queryClient.getQueryData([
          getUserGroupsKey,
          payload.groupId,
        ])

        if (!previousGroups) {
          previousGroups = await queryClient.fetchQuery(
            [getUserGroupsKey, payload.groupId],
            () => userApi.getUserGroups(payload.userId, environmentId)
          )
        }

        queryClient.setQueryData(
          [getUserGroupsKey, payload.groupId],
          (old: Group[]) =>
            old.filter((oldGroup: Group) => oldGroup.id !== payload.groupId)
        )

        return previousGroups
      },
      onError(
        err,
        payload: RemoveGroupFromUserPayload,
        previousGroups: Group[]
      ) {
        queryClient.setQueryData(
          [getUserGroupsKey, payload.groupId],
          previousGroups
        )
      },

      onSettled: (data, error, payload: RemoveGroupFromUserPayload) => {
        queryClient.invalidateQueries([getUserGroupsKey, payload.groupId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
