import { FC, PropsWithChildren, MouseEvent, useMemo } from 'react'
import {
  Box,
  Stack,
  ButtonProps,
  debounce,
  IconButton,
  Theme,
  Typography,
} from '@mui/material'
import { SxProps } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'
import {
  StyledDialog,
  StyledDialogContainer,
  StyledDialogFooter,
  StyledDialogButton,
} from './dialog.styles'
import { ContentLoader } from '../Loader/ContentLoader'

type DialogProps = {
  open: boolean
  onClose?: () => void
  title?: string | JSX.Element
  primaryButton?: boolean
  primaryButtonProps?: ButtonProps
  secondaryButton?: boolean
  secondaryButtonProps?: ButtonProps
  tertiaryButton?: boolean
  tertiaryButtonProps?: ButtonProps
  hideCloseButton?: boolean
  loading?: boolean
  paperSx?: SxProps<Theme>
  allowBackdropClickClose?: boolean
  allowEscapeKeyClose?: boolean
  error?: string
  warning?: string
  width?: number
}

const CustomDialog: FC<PropsWithChildren<DialogProps>> = (
  props: PropsWithChildren<DialogProps>
) => {
  const {
    children,
    title,
    open,
    onClose,
    primaryButton,
    primaryButtonProps,
    secondaryButton,
    secondaryButtonProps,
    tertiaryButton,
    tertiaryButtonProps,
    hideCloseButton,
    loading,
    paperSx,
    allowBackdropClickClose,
    allowEscapeKeyClose,
    error,
    warning,
    width,
  } = props

  const handlePrimaryClick = (event: MouseEvent<HTMLButtonElement>) => {
    primaryButtonProps?.onClick?.(event)
  }

  const handleSecondaryClick = (event: MouseEvent<HTMLButtonElement>) => {
    secondaryButtonProps?.onClick?.(event)
  }

  const handleDialogClose = (
    _event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' && !allowBackdropClickClose) return false
    if (reason === 'escapeKeyDown' && !allowEscapeKeyClose) return false
    onClose?.()
  }

  const debouncedPrimaryBtnClick = useMemo(
    () => debounce(handlePrimaryClick, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [primaryButtonProps?.onClick]
  )

  const debouncedSecondaryBtnClick = useMemo(
    () => debounce(handleSecondaryClick, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [secondaryButtonProps?.onClick]
  )

  return (
    <StyledDialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby='dialog'
      PaperProps={{
        sx: {
          width: width ?? 424,
          ...paperSx,
          minHeight: '200px',
          '&::-webkit-scrollbar': {
            display: 'none !important',
          },
          //hide the scroll bar
          '-ms-overflow-style': 'none !important',
          //hide scrollbar
        },
      }}
    >
      <ContentLoader
        loading={loading ?? false}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10000,
        }}
      />
      <StyledDialogContainer px={2}>
        <Box>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              // pb: 2,
              // mt: 2,
              position: 'sticky',
              top: 0,
              alignItems: 'center',
              backgroundColor: 'white',
              height: '64px !important',
              zIndex: 100,
            }}
          >
            <Box>
              {title && typeof title === 'string' && (
                <Box>
                  <Typography sx={{ width: '100%' }} variant='h6'>
                    {title}
                  </Typography>
                </Box>
              )}
              {title && typeof title !== 'string' && title}
            </Box>
            {!hideCloseButton && (
              <Box>
                <IconButton color='primary' onClick={() => onClose?.()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Stack>
          <Box
            sx={{
              position: 'sticky',
              top: '56px',
              height: '16px',
              background: `linear-gradient(to bottom, 
        rgba(255, 255, 255, 0.8) 100%, rgba(255, 255, 255, 0.2) 0%)`,
              //blur the content behind the dialog
              // backdropFilter: 'blur(4px)',
              width: '100%',
              pointerEvents: 'none',
              zIndex: 100,
            }}
          />
          <Box>
            <Stack
              direction='column'
              justifyContent='space-between'
              sx={{ pb: 2, height: '100%' }}
            >
              {children}
              {error && (
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      color: 'red',
                      marginTop: '4px',
                      paddingLeft: '4px',
                    }}
                    fontSize={14}
                  >
                    {error}
                  </Typography>
                </Box>
              )}
              {warning && (
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      color: 'orange',
                      marginTop: '4px',
                      paddingLeft: '4px',
                    }}
                    fontSize={14}
                  >
                    {warning}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
        {primaryButtonProps && (
          <Box
            sx={{
              position: 'sticky',
              bottom: '64px',
              height: '16px',
              background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.8) 100%)`,
              // backdropFilter: 'blur(4px)',
              width: '100%',
              pointerEvents: 'none',
              zIndex: 100,
            }}
          />
        )}
        {(primaryButtonProps ||
          secondaryButtonProps ||
          tertiaryButtonProps) && (
          <StyledDialogFooter sx={{ padding: 0, py: 2 }}>
            {tertiaryButtonProps || tertiaryButton ? (
              <StyledDialogButton
                variant='text'
                color='primary'
                {...tertiaryButtonProps}
                onClick={tertiaryButtonProps?.onClick}
              >
                {tertiaryButtonProps?.children ?? 'Delete'}
              </StyledDialogButton>
            ) : (
              <Box />
            )}
            <Box>
              {(secondaryButtonProps || secondaryButton) && (
                <StyledDialogButton
                  variant='text'
                  color='secondary'
                  {...secondaryButtonProps}
                  onClick={debouncedSecondaryBtnClick}
                >
                  {secondaryButtonProps?.children ?? 'No'}
                </StyledDialogButton>
              )}
              {(primaryButtonProps || primaryButton) && (
                <StyledDialogButton
                  variant='contained'
                  color='primary'
                  {...primaryButtonProps}
                  onClick={e => {
                    if (!error) {
                      debouncedPrimaryBtnClick(e)
                    }
                  }}
                  sx={{ ml: 1 }}
                >
                  {primaryButtonProps?.children ?? 'Yes'}
                </StyledDialogButton>
              )}
            </Box>
          </StyledDialogFooter>
        )}
      </StyledDialogContainer>
    </StyledDialog>
  )
}

export default CustomDialog
