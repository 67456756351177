import {
  Card,
  Stack,
  CardHeader,
  CardContent,
  Box,
  Typography,
  TextField,
} from '@mui/material'
import {PayPalButtons} from '@paypal/react-paypal-js'
import {useState} from 'react'

import {paypalApi} from '../../api-interface'
import {SubscriptionPlan} from '../../models/paypal'

type PricingTierProps = {
  plan: SubscriptionPlan
}
export function PricingTier(props: PricingTierProps) {
  const reloadPage = () => {
    window.location.reload()
  }

  const {pricePerEnvironment, name, planId, description, intervalType, taxes} =
    props.plan
  const [numberOfUsers, setNumberOfUsers] = useState('1')

  const pricePerPeriod =
    parseFloat(pricePerEnvironment) *
    parseFloat(numberOfUsers) *
    (1 + parseFloat(taxes.percentage) / 100)

  const pricePerPeriodDisplay = isNaN(pricePerPeriod)
    ? '0'
    : pricePerPeriod.toFixed(2)

  return (
    <Card elevation={8} sx={{borderRadius: 2, width: '280px', zIndex: '1'}}>
      <CardHeader
        title={name}
        titleTypographyProps={{align: 'center'}}
        subheaderTypographyProps={{
          align: 'center',
        }}
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[50]
              : theme.palette.grey[700],

          borderBottom: '1px solid #e6e6e6',
          height: '64px',
        }}
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',

            gap: 1,
            mb: 2,
          }}
        >
          <Stack direction='row' alignItems='baseline'>
            <Typography
              sx={{alignSelf: 'flex-start', mt: '4px'}}
              variant='h6'
              color='text.primary'
            >
              $
            </Typography>
            <Typography component='h2' variant='h3' color='text.primary'>
              {pricePerPeriodDisplay}
            </Typography>
            <Typography variant='body1' color='text.primary'>
              /{intervalType.toLowerCase()}
            </Typography>
          </Stack>
          <Typography variant='caption' color='text.primary'>
            {`($${pricePerEnvironment} per user ${intervalType.toLowerCase()}ly, plus taxes)`}
          </Typography>
          <Stack direction='row' spacing={1} alignItems='baseline'>
            <TextField
              size='small'
              type='number'
              inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: '1'}}
              sx={{width: '80px'}}
              value={numberOfUsers}
              onChange={e => {
                let value = e.target.value === '' ? '1' : e.target.value
                setNumberOfUsers(value)
              }}
            />
            <Typography variant='body2' color='text.secondary'>
              users
            </Typography>
          </Stack>
        </Box>
        <Stack direction='column' sx={{height: '96px'}}>
          <ul>
            {description.split('|').map(line => (
              <Typography
                component='li'
                variant='subtitle2'
                align='left'
                key={line}
              >
                {line}
              </Typography>
            ))}
          </ul>
        </Stack>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            bottom: 2,
          }}
        >
          <PayPalButtons
            fundingSource='paypal'
            forceReRender={[numberOfUsers, planId]}
            createSubscription={(data, actions) => {
              return actions.subscription
                .create({
                  plan_id: planId,
                  quantity: numberOfUsers,
                })
                .then((subscriptionId: string) => {
                  return subscriptionId
                })
            }}
            onApprove={async (data, actions) => {
              await paypalApi.postPaypalLicense(data.subscriptionID)
              reloadPage()
              return null
            }}
            onCancel={() => {}}
            onError={error => {}}
          />
        </Box>
      </CardContent>
      {/* <CardActions></CardActions> */}
    </Card>
  )
}
