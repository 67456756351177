import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'
import { ApiReport, ReportsFromUser } from '../../models'
import { environmentAtom } from '../../state'
import { getDashboardKey, getReportsByUserKey } from '../queries'

export const removeReportFromUserKey = 'remove-report-from-user'

export type RemoveReportFromUserPayload = { reportId: string; viewerId: string }

/** Remove a viewer from a PBI report */
export function useRemoveReportFromViewer() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveReportFromUserPayload) =>
      reportApi.removeViewers(
        payload.reportId,
        payload.viewerId,
        environmentId
      ),
    {
      async onMutate(
        payload: RemoveReportFromUserPayload
      ): Promise<ReportsFromUser> {
        await queryClient.cancelQueries([getReportsByUserKey, payload.viewerId])

        let previousReports: ReportsFromUser = queryClient.getQueryData([
          getReportsByUserKey,
          payload.viewerId,
        ])

        if (!previousReports) {
          previousReports = await queryClient.fetchQuery(
            [getReportsByUserKey, payload.viewerId],
            () => reportApi.getReportsByUser(environmentId, payload.viewerId)
          )
        }

        queryClient.setQueryData(
          [getReportsByUserKey, payload.viewerId],
          (old: ReportsFromUser) => {
            const userReports = old?.sharedReports?.filter(
              (r: ApiReport) => r.id !== payload.reportId
            )
            return {
              ...old,
              sharedReports: userReports,
            }
          }
        )

        return previousReports
      },
      onError(
        _err,
        payload: RemoveReportFromUserPayload,
        context: ReportsFromUser
      ) {
        queryClient.setQueryData(
          [getReportsByUserKey, payload.viewerId],
          context
        )
      },
      onSettled: (data, error, payload: RemoveReportFromUserPayload) => {
        queryClient.invalidateQueries([getReportsByUserKey, payload.viewerId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
    }
  )
}
