import { useEffect, useState } from 'react'
import {
  useAddReportGroupUsers,
  useGetOtherUsersOnEnvironment,
  useGetReportGroupUsers,
} from '../../../../hooks'
import { User, ReportGroup } from '../../../../models'
import { LinkItemsMenu } from '../../shared'

type LinkUsersMenuProps = {
  reportGroup: ReportGroup
}

export const LinkUsersMenu = (props: LinkUsersMenuProps) => {
  const { reportGroup } = props
  const { data: users, isLoading: usersLoading } = useGetOtherUsersOnEnvironment()

  const allUsers = users || []
  const { data: linkedUsers, isLoading: loadingLinkedUsers } =
    useGetReportGroupUsers(reportGroup.id)

  const { mutate: addReportGroupUsers } = useAddReportGroupUsers()

  const [usersAvailableToAdd, setUsersAvailableToAdd] = useState<User[]>([])

  useEffect(() => {
    const linkedUsersNotLoaded = linkedUsers
      ?.map(u => {
        return !!u?.id
      })
      .some((r: boolean) => r === false)

    let linkedUserIds = []

    linkedUserIds =
      (linkedUsers && !linkedUsersNotLoaded && linkedUsers.map(u => u.id)) || []

    setUsersAvailableToAdd(
      allUsers?.filter((u: User) => !linkedUserIds.includes(u.id)) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedUsers, users])

  const linkUsers = async (userIds: string[]) => {
    await addReportGroupUsers({
      reportGroupId: reportGroup.id,
      userIds: userIds,
    })
  }

  return (
    <>
      <LinkItemsMenu
        onConfirm={linkUsers}
        addButtonLabel='Add Users'
        placeHolderText='Search Users'
        loading={usersLoading || loadingLinkedUsers}
        availableItems={
          (usersAvailableToAdd &&
            usersAvailableToAdd.map((u: User) => {
              return {
                id: u.id,
                name: `${u.firstName} ${u.lastName}`,
                email: u.email,
              }
            })) ||
          []
        }
      />
    </>
  )
}
