import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {appRegApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getAppRegistrationsKey = 'get-app-registrations'

export function useGetAppRegs() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getAppRegistrationsKey, environmentId],
    () => appRegApi.getAppRegistrations(environmentId),
    {
      enabled: !!environmentId,
    }
  )
}
