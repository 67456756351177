import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { groupApi } from '../../api-interface'
import { User } from '../../models'
import { environmentAtom } from '../../state'
import { getDashboardKey, getGroupUsersKey } from '../queries'

export const removeGroupUserKey = 'remove-group-user'

export type RemoveGroupUserPayload = { groupId: string; userId: string }

/** Remove a viewer from a PBI report */
export function useRemoveGroupUser() {
  const environmentId = useRecoilValue(environmentAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveGroupUserPayload) =>
      groupApi.removeGroupUser(payload.groupId, payload.userId, environmentId),
    {
      async onMutate(payload: RemoveGroupUserPayload): Promise<User[]> {
        await queryClient.cancelQueries([getGroupUsersKey, payload.groupId])

        let previousUsers: User[] = queryClient.getQueryData([
          getGroupUsersKey,
          payload.groupId,
        ])

        if (!previousUsers) {
          previousUsers = await queryClient.fetchQuery(
            [getGroupUsersKey, payload.groupId],
            () => groupApi.getGroupUsers(payload.groupId, environmentId)
          )
        }

        queryClient.setQueryData(
          [getGroupUsersKey, payload.groupId],
          (old: User[]) =>
            old.filter((oldUser: User) => oldUser.id !== payload.userId)
        )

        return previousUsers
      },

      onSettled: (data, error, payload: RemoveGroupUserPayload) => {
        queryClient.invalidateQueries([getGroupUsersKey, payload.groupId])
        queryClient.invalidateQueries([getDashboardKey, environmentId])
      },
      onError(err, payload: RemoveGroupUserPayload, previousUsers: User[]) {
        queryClient.setQueryData(
          [getGroupUsersKey, payload.groupId],
          previousUsers
        )
      },
    }
  )
}
