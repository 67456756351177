import React from 'react'
import { Button, Stack } from '@mui/material'
import { AppLicense } from '../../../../../models'
import { KpiCard } from './kpi-card'

interface LicenseInformationProps {
  license: AppLicense
  onUpgrade: () => void
}

export const LicenseInformation: React.FC<LicenseInformationProps> = ({
  license,
  onUpgrade,
}) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      flexWrap={'wrap'}
      gap={2}
      sx={{ mb: 2 }}
    >
      <KpiCard
        label='Start Date'
        value={
          license?.licenseStartDate
            ? new Date(license.licenseStartDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : 'N/A'
        }
      />
      <KpiCard
        label='License Type'
        value={license?.isTrial ? 'trial' : license.licenseSku}
      />

      {license?.isTrial && (
        <KpiCard label='Trial Days Remaining' value={license.trialDaysLeft} />
      )}
      {license?.licenseSku === 'basic' && (
        <Button
          variant='contained'
          disableElevation
          color='secondary'
          sx={{
            height: '40px',
            px: 3,
            width: 'fit-content',
            fontWeight: 600,
          }}
          onClick={onUpgrade}
        >
          Upgrade
        </Button>
      )}
    </Stack>
  )
}
