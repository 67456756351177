import { Folder } from './folder'
import { Group } from './group'
import { User } from './user'

export type ReportData = {
  id?: string
  name: string
  description?: string
  url?: string
  default?: boolean
}

export type RlsValue = {
  roles: string[]
  username: 'email'
}

export type ReportPage = {
  name: string
  displayName: string
  order: number
}

export type NewReportEmbedRequest = {
  environmentId: string
  pbiReportId: string
  appRegNodeId: string
  appClientId: string
  appRegTenantId: string
  workspaceId: string
  profileId: string
  rlsRoles?: string[]
  username?: string
}

export type PaginatedReportToCreate = {
  id?: string
  name: string
  description: string
  selectedColumns: string
  filters: string
  appRegistrationNodeId: string
  pbiWorkspaceId: string
  pbiDatasetWorkspaceId?: string
  environmentId?: string
  perspective?: string
  sortVal?: string
  profileId: string
  file: string
  datasetId: string
}

export type ReportToUpdate = ReportData & {
  appRegistrationNodeId: string
  environmentNodeId: string
  viewers?: string[]
  name?: string
  rlsRoles?: string[]
  rlsUsername?: string
  filterPaneEnabled?: boolean
  showPageNavigation?: boolean
  defaultPageName?: string
}

export type GetReportResponse = {
  report: ApiReport
  permissions?: string[]
}

export type ReportToCreate = ReportData & {
  pbiReportId?: string
  pbiReportName?: string
  pbiWorkspaceId?: string
  appRegistrationNodeId: string
  appRegistrationName: string
  profileId: string
  profileName: string
  environmentNodeId: string
  viewers?: string[]
  datasetId?: string
  rlsRoles?: string[]
  rlsUsername?: string
  filterPaneEnabled?: boolean
  showPageNavigation?: boolean
  defaultPageName?: string
  type?: 'report' | 'paginated-report' | 'dataset' | 'custom-paginated'
  perspective?: string
  showOnHome: boolean
  permissions?: string
}

export type ReportSettings = {
  displayName: string
  filterPaneEnabled: boolean
  showPageNavigation: boolean
  defaultPageName?: string
}

export type ReportSettingsToUpdate = ReportSettings & {
  id: string
  environmentNodeId: string
}

export type AllReports = {
  ownedReports: ApiReport[]
  sharedReports: ApiReport[]
  defaultReports: ApiReport[]
  environmentReports: ApiReport[]
}

/*REPORT TYPES:
  custom-paginated: Custom paginated report created from table builder
  paginated-report: Existing paginated report
  report: Existing report visual
  dataset: Table builder dataset
*/

export const REPORT_TYPES = {
  CUSTOM_PAGINATED: 'custom-paginated',
  PAGINATED_REPORT: 'paginated-report',
  REPORT: 'report',
  DATASET: 'dataset',
}

export type ApiReport = ReportData & {
  owner: string
  pbiWorkspaceId: string
  pbiDatasetWorkspaceId?: string
  datasetId?: string
  pbiReportId: string
  pbiReportName?: string
  appRegistrationNodeId: string
  appRegistrationName: string
  profileId?: string
  profileName?: string
  defaultPageName?: string
  rlsRoles?: string[]
  rlsUsername?: string
  default?: boolean
  workspaceName?: string
  filterPaneEnabled?: boolean
  expandFilterPane?: boolean
  showPageNavigation?: boolean
  type?: 'report' | 'paginated-report' | 'dataset' | 'custom-paginated'
  selectedColumns?: string
  filters?: string
  showOnHome: boolean
  sortVal?: string
  isKeyReport?: boolean
  perspective?: string
  permissions?: string
  userOwnsReport?: boolean
}

export type ApiGroupReport = ApiReport & {
  groupId: string
  groupName: string
}

export type ReportsFromUser = {
  groupReports: ApiGroupReport[]
  sharedReports: ApiReport[]
  ownedReports: ApiReport[]
}

export type StatusMessageInfo = {
  type: string
  title: string
  message?: string
  reasons?: string[]
}

export type GetReportViewersResponse = {
  users: User[]
  groups: Group[]
}

// export type GetAllReportsForUserResponse = {
//   reportsFromGroups: ApiGroupReport[]
//   reportsFromUser: ApiReport[]
// }

export enum ReportConnectionStatus {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  // PaginatedReport = 998, //used to allow user to specify rls if they need to
  RowLevelSecurity = 999,
}

export type HomepageCards = {
  reportGroups: ReportGroupWithDetails[]
  reports: AllReports
  folders: Folder[]
}

// export type ReportGroupWithDetails = {
//   reportGroup: ReportGroup
//   reports: ApiReport[]
//   folders: Folder[]
// }

export type ReportGroupWithDetails = {
  id: string
  name: string
  description: string
  showOnHome: boolean
  reports: {
    id: string
    name: string
    isKeyReport: boolean
  }[]
  folders: Folder[]
}

export type GetNavMenuItemsType = {
  reportGroups: ReportGroupWithDetails[]
  folders: Folder[]
  reports: {
    myReports: {
      id: string
      name: string
    }[]
    sharedReports: {
      id: string
      name: string
    }[]
  }
}
