import { useEffect, useState } from 'react'
import {
  Button,
  Theme,
  Typography,
  // Switch,
  // FormControlLabel,
  // FormGroup,
  Stack,
  useTheme,
  alpha,
  Paper,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { ColorPickerField } from './color-picker-field'
import {
  useCreateTheme,
  useGetAppSettings,
  useGetTheme,
} from '../../../../hooks'
import { defaultAppTheme } from '../../../../utils/theme'
import { FullPageLoader } from '../../../../components'
import { AppTheme } from '../../../../models'
import { ImageSelector } from './image-selector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    addLogo: {
      mt: 1,
    },
    addLogoBox: {
      height: '300px',
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f5f5f5',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    },
    secondaryColor: {
      color: theme.palette.secondary.main,
    },
    currentLogo: {
      padding: theme.spacing(2),
      borderRadius: '5px',
      maxWidth: '175px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    themeBox: {
      padding: theme.spacing(2),
      marginRight: theme.spacing(2),
      justifyContent: 'space-between',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
    },
    themeContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
)

export function AppearanceConfig() {
  const classes = useStyles()
  const { mutate: saveTheme } = useCreateTheme()
  const { data: appSettings } = useGetAppSettings()

  const { data: themeForLicense, isLoading: themeLoading } = useGetTheme()
  const isLoading = themeLoading

  const currentTheme = useTheme()

  const [primaryColor, setPrimaryColor] = useState(
    themeForLicense?.primaryColor || currentTheme.palette.primary.main
  )
  const [secondaryColor, setSecondaryColor] = useState(
    themeForLicense?.secondaryColor || currentTheme.palette.secondary.main
  )
  const [successColor, setSuccessColor] = useState(
    themeForLicense?.successColor || currentTheme.palette.success.main
  )
  const [warningColor, setWarningColor] = useState(
    themeForLicense?.warningColor || currentTheme.palette.warning.main
  )
  const [backgroundColor, setBackgroundColor] = useState(
    themeForLicense?.backgroundColor || currentTheme.palette.background.default
  )
  const [errorColor, setErrorColor] = useState(
    themeForLicense?.errorColor || currentTheme.palette.error.main
  )
  // const [mode, setMode] = useState(currentTheme.palette.mode)

  const [logo, setLogo] = useState(themeForLicense?.logo)

  useEffect(() => {
    setPrimaryColor(currentTheme.palette.primary.main)
    setSecondaryColor(currentTheme.palette.secondary.main)
    setSuccessColor(currentTheme.palette.success.main)
    setWarningColor(currentTheme.palette.warning.main)
    setErrorColor(currentTheme.palette.error.main)
    setBackgroundColor(currentTheme.palette.background.default)
    if (themeForLicense?.logo) setLogo(themeForLicense.logo)
  }, [currentTheme, themeForLicense])

  async function resetToDefault() {
    saveTheme(defaultAppTheme)
  }

  async function updateTheme() {
    const newTheme: AppTheme = {
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      successColor: successColor,
      warningColor: warningColor,
      errorColor: errorColor,
      backgroundColor: backgroundColor,
      logo: logo,
    }

    saveTheme(newTheme)
  }
  // function toggleDarkMode(isDark: boolean) {
  //   setMode(isDark ? 'dark' : 'light')
  // }
  // const isDarkMode = mode === 'dark'

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Appearance</Typography>
      <Typography variant='body2' sx={{ marginBottom: 2 }}>
        Change the theme settings
      </Typography>
      {isLoading && <FullPageLoader />}
      {!isLoading && (
        <div>
          <div className={classes.themeContainer}>
            <Paper className={classes.themeBox}>
              <Typography variant='h6'>Theme Colors</Typography>
              <ColorPickerField
                title='Primary Color'
                selectedColor={primaryColor}
                onColorChanged={color => {
                  setPrimaryColor(color)
                }}
              />
              <ColorPickerField
                title='Secondary Color'
                selectedColor={secondaryColor}
                onColorChanged={color => {
                  setSecondaryColor(color)
                }}
              />
              <ColorPickerField
                title='Background'
                selectedColor={backgroundColor}
                onColorChanged={color => {
                  setBackgroundColor(color)
                }}
              />
            </Paper>
            <Paper className={classes.themeBox}>
              <Typography variant='h6'>Status Colors</Typography>
              <ColorPickerField
                title='Success'
                selectedColor={successColor}
                onColorChanged={color => {
                  setSuccessColor(color)
                }}
              />
              <ColorPickerField
                title='Warning'
                selectedColor={warningColor}
                onColorChanged={color => {
                  setWarningColor(color)
                }}
              />
              <ColorPickerField
                title='Error'
                selectedColor={errorColor}
                onColorChanged={color => {
                  setErrorColor(color)
                }}
              />
            </Paper>
            {appSettings?.customLogoEnabled && (
              <Paper className={classes.themeBox}>
                <div>
                  <Typography variant='h6'>Theme Logo</Typography>

                  {logo && (
                    <div
                      className={classes.currentLogo}
                      style={{ backgroundColor: primaryColor }}
                    >
                      <img
                        src={logo}
                        alt='Logo'
                        style={{ width: '150px', marginBottom: 2 }}
                      />
                    </div>
                  )}

                  {!logo && (
                    <Typography
                      variant='body2'
                      sx={{ m: 2 }}
                      fontStyle='italic'
                    >
                      No logo set
                    </Typography>
                  )}
                </div>

                <ImageSelector
                  label='Logo'
                  setImage={setLogo}
                  image={logo}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                  preview={{
                    height: '100px',
                    width: '300px',
                  }}
                />
              </Paper>
            )}
          </div>
          <Stack direction='row' gap={1}>
            <Button
              sx={{ mt: 2 }}
              style={{ color: primaryColor, borderColor: primaryColor }}
              onClick={() => updateTheme()}
              size='large'
              variant='outlined'
            >
              Save Theme
            </Button>
            <Button
              sx={{ mt: 2 }}
              style={{ color: secondaryColor }}
              onClick={() => resetToDefault()}
              size='large'
            >
              Reset Theme
            </Button>
          </Stack>
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={isDarkMode}
                  onChange={e => {
                    toggleDarkMode(e.target.checked)
                  }}
                />
              }
              label='Dark mode'
            />
          </FormGroup> */}
        </div>
      )}
    </div>
  )
}
