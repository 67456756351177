import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getEnvironmentReportsKey = 'get-environment-reports'

export function useGetEnvironmentReports() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getEnvironmentReportsKey, environmentId],
    () => reportApi.getEnvironmentReports(environmentId),
    {
      enabled: !!environmentId,
    }
  )
}
