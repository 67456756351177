import { useMutation, useQueryClient } from 'react-query'
import { reportApi } from '../../api-interface'
import { getReportKey, getReportsKey } from '../queries'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'
import {  GetReportResponse } from '../../models'

type UpdateReportSettingsPayload = {
  id: string
  name: string
  filterPaneEnabled: boolean
  showPageNavigation: boolean
}

type CreateReportContext = {
  previousReport: GetReportResponse
}


export function useUpdateReportSettings() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: UpdateReportSettingsPayload) =>
      reportApi.updateReportSettings({
        id: payload.id,
        displayName: payload.name,
        filterPaneEnabled: payload?.filterPaneEnabled ?? false,
        showPageNavigation: payload?.showPageNavigation ?? false,
        environmentNodeId: environmentId,
      }),
    {
      async onMutate(newReport): Promise<CreateReportContext> {
        await queryClient.cancelQueries([getReportKey, environmentId, newReport.id])

        let previousReport: GetReportResponse = await queryClient.getQueryData([
          getReportKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getReportKey, environmentId, newReport.id],
          (old: GetReportResponse) => {
            return {
              report: {
                ...old.report,
                displayName: newReport.name,
                filterPaneEnabled: newReport.filterPaneEnabled,
                showPageNavigation: newReport.showPageNavigation,
              },
              permissions: old?.permissions || [],
            }
          } 
        )

        // Return the context passed on onError
        return { previousReport }
      },
      onError(err, newReport, context: CreateReportContext) {
        queryClient.setQueryData(
          [getReportKey, environmentId, newReport.id],
          context.previousReport
        )
      },
      onSettled(data, error, variables) {
        queryClient.invalidateQueries([getReportKey, environmentId, variables.id])
         queryClient.invalidateQueries([getReportsKey, environmentId])
      },
    }
  )
}
