import { Alert, Stack, Typography } from '@mui/material'
import { AppRegItem } from '../../../../../models'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import React from 'react'

type AffectedItemsDisplayProps = {
  items: AppRegItem[]
  profileId?: string
}

export function AffectedItemsDisplay(props: AffectedItemsDisplayProps) {
  const { items, profileId } = props

  const filteredProfiles =
    items?.filter(item => (profileId ? item.id === profileId : true)) ?? []

  const allReports: Item[] = filteredProfiles
    ?.map(item => item.reports)
    ?.flat()
    ?.map(report => ({ id: report.id, name: report.name }))

  const allWorkspaces: Item[] = filteredProfiles
    ?.map(item => item.workspaces)
    ?.flat()
    ?.map(workspace => ({ id: workspace.id, name: workspace.name }))

  if (allWorkspaces?.length === 0 && allReports?.length === 0) return null

  return (
    <Stack direction='column' spacing={2} mt={2}>
      <Typography variant='subtitle1'>
        The following items will loose connection:
      </Typography>
      {allReports?.length > 0 && (
        <ItemList items={allReports} title='Reports' />
      )}
      {allWorkspaces?.length > 0 && (
        <ItemList items={allWorkspaces} title='Workspaces' />
      )}
    </Stack>
  )
}

type Item = {
  id: string
  name: string
}

function ItemList(props: { items: Item[]; title: string }) {
  const [showAll, setShowAll] = React.useState(false)
  const itemsToShow = showAll ? props.items : props.items.slice(0, 3)
  const title =
    props.items.length === 1 ? props.title.slice(0, -1) : props.title

  return (
    <Stack direction={'column'} mb={2}>
      <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }} mb={1}>
        {props.items?.length} {title}
      </Typography>
      <Stack
        direction={'column'}
        maxHeight={'120px'}
        sx={{
          overflowY: 'auto',
        }}
        spacing={1}
      >
        {itemsToShow.map(item => (
          <SingleItem key={item.id} item={item} />
        ))}
        {props.items.length > 3 && !showAll && (
          <Typography
            variant='body2'
            onClick={() => setShowAll(true)}
            sx={{
              cursor: 'pointer',
              fontStyle: 'italic',
              color: 'secondary.main',
              pl: 1,
            }}
          >
            Show more
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

function SingleItem(props: { item: Item }) {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <FiberManualRecordIcon
        sx={{
          color: 'primary.main',
          fontSize: '8px',
        }}
      />
      <Typography>{props.item.name}</Typography>
    </Stack>
  )
}

export function WarningMessage(props: {
  itemType: string
  disableDelete?: boolean
}) {
  const text = props.disableDelete
    ? `Deleting the only existing ${props.itemType} is prohibited.`
    : `Are you sure you want to delete this ${props.itemType}?`
  const severity = props.disableDelete ? 'error' : 'warning'

  return (
    <Alert severity={severity} sx={{ mb: 1 }}>
      {text}
    </Alert>
  )
}
