import { useQuery } from 'react-query'
import { reportGroupApi } from '../../api-interface'
import { environmentAtom } from '../../state'
import { useRecoilValue } from 'recoil'

export const getReportGroupUsersKey = 'get-report-group-users'

export function useGetReportGroupUsers(reportGroupId: string) {
  
  const environmentId = useRecoilValue(environmentAtom)
  
  return useQuery(
    [getReportGroupUsersKey, reportGroupId],
    () => reportGroupApi.getReportGroupUsers(environmentId, reportGroupId),
    {
      enabled: !!reportGroupId,
    }
  )
}
