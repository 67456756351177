import { useState } from 'react'
import CustomDialog from '../../../admin/shared/dialog/dialog'
import { FolderItem } from '../../../../models'
import { CircularProgress, Typography } from '@mui/material'

type AccessDeniedDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => void
  item: FolderItem
  disableEdit: boolean
}

export function AccessDeniedDialog(props: AccessDeniedDialogProps) {
  const { open, onClose, item, handleDelete, disableEdit } = props

  const [deleteLoading, setDeleteLoading] = useState(false)

  const removeButtonProps = {
    disabled: deleteLoading,
    children: 'Remove',
    onClick: async () => {
      setDeleteLoading(true)
      await handleDelete()
      await new Promise(resolve => setTimeout(resolve, 1000))
      setDeleteLoading(false)
      onClose()
    },
    endIcon: deleteLoading ? <CircularProgress size={20} /> : undefined,
  }

  const cancelButtonProps = {
    disabled: deleteLoading,
    children: 'Cancel',
    onClick: () => {
      onClose()
    },
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`${item.name}`}
      primaryButtonProps={!disableEdit && removeButtonProps}
      secondaryButtonProps={!disableEdit && cancelButtonProps}
    >
      <Typography
        variant='body1'
        sx={{
          display: 'inline',
        }}
      >
        <Typography
          variant='body1'
          sx={{
            color: theme => theme.palette.error.main,
            fontWeight: 'bold',
            display: 'inline',
          }}
        >
          Access Denied
        </Typography>
        . Either you do not have the necessary permissions to view this item, or
        the item no longer exists.
        {!disableEdit && (
          <Typography
            variant='body1'
            sx={{
              display: 'inline',
            }}
          >
            {' '}
            Click 'Remove' to remove this item from your folder.
          </Typography>
        )}
      </Typography>
    </CustomDialog>
  )
}
