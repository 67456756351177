import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getReportsKey = 'get-reports'

export function useGetReports() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getReportsKey, environmentId],
    () => reportApi.getReports(environmentId),
    {
      enabled: !!environmentId,
    }
  )
}
