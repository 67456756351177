import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {environmentAtom} from '../../state'

export const getUserGroupsKey = 'get-user-groups'

export function useGetUserGroups(userId: string) {
  const environmentId = useRecoilValue(environmentAtom)

  return useQuery(
    [getUserGroupsKey, userId],
    () => userApi.getUserGroups(userId, environmentId),
    {
      enabled: true,
    }
  )
}
