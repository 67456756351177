import { useQuery } from 'react-query'
import { workspacesApi } from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'

export const getAdminWorkspacesKey = 'get-admin-workspaces'

export function useGetAdminWorkspaces() {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery([getAdminWorkspacesKey, environmentId], () =>
    workspacesApi.getAdminWorkspaces(environmentId)
  )
}
