import {useQuery, UseQueryOptions} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {User} from '../../models'
import {environmentAtom} from '../../state'

export const getOtherUsersOnEnvironmentKey = 'get-other-users-on-environment-key'

export function useGetOtherUsersOnEnvironment(
  options: UseQueryOptions<User[]> = {}
) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getOtherUsersOnEnvironmentKey, environmentId],
    () => userApi.getEnvironmentUsers(environmentId),
    {
      ...options,
      enabled:
        typeof options.enabled === 'boolean' ? options.enabled : !!environmentId,
    }
  )
}
