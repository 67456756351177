import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { workspacesApi } from '../../api-interface'
import { environmentAtom } from '../../state'

export const getCapacitiesKey = 'get-capacities'

export function useGetCapacities(
  appRegNodeId: string,
  appClientId: string,
  appRegTenantId: string,
  profileId: string
) {
  const environmentId = useRecoilValue(environmentAtom)
  return useQuery(
    [getCapacitiesKey, environmentId, profileId],
    () =>
      workspacesApi.getCapacities(
        environmentId,
        appRegNodeId,
        appClientId,
        appRegTenantId,
        profileId
      ),
    {
      enabled: !!environmentId,
    }
  )
}
