import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { environmentAtom } from '../../state'
import { workspacesApi } from '../../api-interface/workspace-api'

export const getCustomReportItemsKey = 'get-custom-report-items'



export function useGetCustomReportItems({
  reportId,
}) {
  const environmentId = useRecoilValue(environmentAtom)

  return useQuery(
    [getCustomReportItemsKey],
    () =>
      workspacesApi.getCustomReportItems(
        environmentId,
        reportId
      ),
    {
      onError: error => {
        // Handle your error here, e.g., log it, show a notification, etc.
        console.error('Error fetching custom report items:', error)
        // Optionally, you can throw the error to propagate it further
        throw error
      },
    }
  )
}
