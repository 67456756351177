import { useState, useEffect } from 'react'
import { Typography, Box, Container, Divider } from '@mui/material'
import { TabLayout } from '../../shared'
import { useGetLicense, useUpdateLicense } from '../../../../hooks'

import { LicenseInformation } from './components/license-information'
import { EnvironmentList } from './environment-list'
import { UpgradeLicenseDialog } from './dialogs/upgrade-subscription'
import { EnvironmentDialog } from './dialogs/environment'

export function SubscriptionPage() {
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { mutate: updateLicenseSku } = useUpdateLicense()

  const [licenseSku, setLicenseSku] = useState('basic')
  const [dialogState, setDialogState] = useState({
    addEnvironment: false,
    upgradeLicense: false,
  })

  useEffect(() => {
    if (license) {
      setLicenseSku(license.licenseSku)
    }
  }, [license])

  const handleDialogClose = (dialog: keyof typeof dialogState) => {
    setDialogState(prev => ({ ...prev, [dialog]: false }))
  }

  const handleDialogOpen = (dialog: keyof typeof dialogState) => {
    setDialogState(prev => ({ ...prev, [dialog]: true }))
  }

  const handleUpgradeLicense = () => {
    updateLicenseSku({ licenseSku: 'enterprise' })
    setLicenseSku('enterprise')
    handleDialogClose('upgradeLicense')
  }

  if (licenseLoading) return <Typography>Loading...</Typography>

  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          marginBottom: 2,
          p: 2,

          borderRadius: 2,
          background: theme => theme.palette.background.default,
        }}
      >
        <Typography variant='h5' gutterBottom sx={{ mb: 2 }}>
          Subscription Details
        </Typography>

        <LicenseInformation
          license={license}
          onUpgrade={() => handleDialogOpen('upgradeLicense')}
        />
        <Divider sx={{ my: 2 }} />

        <TabLayout
          tabTitle='Environments'
          primaryButton={{
            buttonText: 'Add Environment',
            variant: 'text',
            color: 'secondary',
            onClick: () => handleDialogOpen('addEnvironment'),
          }}
        >
          <EnvironmentList />
        </TabLayout>
      </Box>

      <UpgradeLicenseDialog
        open={dialogState.upgradeLicense}
        onClose={() => handleDialogClose('upgradeLicense')}
        licenseSku={licenseSku}
        onUpgrade={handleUpgradeLicense}
        onSkuChange={setLicenseSku}
      />

      <EnvironmentDialog
        open={dialogState.addEnvironment}
        onClose={() => handleDialogClose('addEnvironment')}
        onSave={() => handleDialogClose('addEnvironment')}
      />
    </Container>
  )
}
