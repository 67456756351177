import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { workspacesApi } from '../../api-interface'
import { Workspace } from '../../models'
import { environmentAtom } from '../../state'
import { getAdminWorkspacesKey, getWorkspacesKey } from '../queries'

type CreateWorkspaceContext = {
  previousWorkspaces: Workspace[]
}

type CreateWorkspacePayload = {
  workspaceId: string
  workspaceName: string
  sharedWithEnvironment?: boolean
  description?: string
}

export function useUpdateWorkspace() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: CreateWorkspacePayload) =>
      workspacesApi.editWorkspace({
        workspaceId: payload.workspaceId,
        name: payload.workspaceName,
        environmentId: environmentId,
        description: payload.description,
        sharedWithEnvironment: payload.sharedWithEnvironment,
      }),
    {
      async onMutate(
        newWorkspace: CreateWorkspacePayload
      ): Promise<CreateWorkspaceContext> {
        await queryClient.cancelQueries([getAdminWorkspacesKey, environmentId])

        let previousWorkspaces: Workspace[] = queryClient.getQueryData([
          getAdminWorkspacesKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getAdminWorkspacesKey, environmentId],
          (old: Workspace[]) =>
            old.map((workspace: Workspace) => {
              if (workspace.id === newWorkspace.workspaceId) {
                return {
                  ...newWorkspace,
                  name: newWorkspace.workspaceName,
                  id: newWorkspace.workspaceId,
                }
              }
              return workspace
            })
        )

        return { previousWorkspaces }
      },
      onError(_err, _newWorkspace, context: CreateWorkspaceContext) {
        queryClient.setQueryData(
          [getAdminWorkspacesKey, environmentId],
          context.previousWorkspaces
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAdminWorkspacesKey, environmentId])
        queryClient.invalidateQueries([getWorkspacesKey, environmentId])
      },
    }
  )
}
