import {useQuery} from 'react-query'
import {bookmarkApi} from '../../api-interface'
import {useRecoilValue} from 'recoil'
import {environmentAtom} from '../../state'

export const getBookmarksKey = 'get-bookmarks'

export function useGetBookmarks(reportId: string) {
  const environmentId = useRecoilValue(environmentAtom)

  return useQuery(
    [getBookmarksKey, environmentId, reportId],
    () => bookmarkApi.getBookmarks(environmentId, reportId),
    {
      enabled: !!reportId,
    }
  )
}
