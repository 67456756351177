import { useQuery, UseQueryOptions } from 'react-query'
import { AppLicense } from '../../models'
import { licenseApi } from '../../api-interface'

export const getLicenseKey = 'get-license'

export function useGetLicense(options: UseQueryOptions<AppLicense> = {}) {
  return useQuery(
    [getLicenseKey],
    async () => {
      try {
        const license = await licenseApi.getLicense()
        return license
      } catch (error) {
        if (error.response?.status === 404) {
          return null // Return null if 404 error occurs
        }
        throw error // Re-throw other errors
      }
    },
    {
      ...options,
      enabled: true,
    }
  )
}
