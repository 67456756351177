import {AppSettings} from '../models'
import {getAxios, endpoints} from './util'

export const appSettingsApi = {
  async getAppSettings(): Promise<AppSettings> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: endpoints.appSettings,
    })

    return response.data
  },
}
