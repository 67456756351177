import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder } from '../../models'
import { environmentAtom } from '../../state'
import { getDashboardKey, getFavoritesFolder, getFoldersKey } from '../queries'

type CreateFolderContext = {
  previousFolders: Folder[]
}

type RemoveItemFromFavoritesPayload = {
  itemId: string
}

export function useRemoveItemFromFavorites() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: RemoveItemFromFavoritesPayload) =>
      folderApi.removeItemFromFavoritesFolder({ ...payload, environmentId }),

    {
      async onMutate(
        newFolder: RemoveItemFromFavoritesPayload
      ): Promise<CreateFolderContext> {
        await queryClient.cancelQueries([getFoldersKey, environmentId])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          environmentId,
        ])

        queryClient.setQueryData(
          [getFoldersKey, environmentId],
          (old: Folder[]) => {
            if (old) {
              const oldFavorites = old.find(folder => folder.isFavorites)
              const newItems = oldFavorites.items.filter(
                item => item.id !== newFolder.itemId
              )
              const newFavorites = {
                ...oldFavorites,
                items: newItems,
              }
              return [
                ...old.filter(folder => !folder.isFavorites),
                newFavorites,
              ]
            }
          }
        )

        return { previousFolders }
      },

      onSettled: () => {
        queryClient.invalidateQueries([getFoldersKey, environmentId, undefined])
        queryClient.invalidateQueries([getFoldersKey, environmentId])

        queryClient.invalidateQueries([getDashboardKey, environmentId])
        queryClient.invalidateQueries([getFavoritesFolder, environmentId])
      },
    }
  )
}
