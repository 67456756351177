import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'

import { environmentAtom } from '../../state'
import { getAdminWorkspacesKey } from '../queries'
import { workspacesApi } from '../../api-interface/workspace-api'
import { Workspace } from '../../models'

type CreateWorkspaceContext = {
  previousWorkspaces: Workspace[]
}

export type CreateWorkspacePayload = {
  name: string
  appRegNodeId: string
  profileId: string
  capacityId: string
  description?: string
}

export function useCreateWorkspace() {
  const queryClient = useQueryClient()
  const environmentId = useRecoilValue(environmentAtom)

  return useMutation(
    (payload: CreateWorkspacePayload) =>
      workspacesApi.createWorkspace(payload, environmentId),
    {
      async onMutate(
        newWorkspace: CreateWorkspacePayload
      ): Promise<CreateWorkspaceContext> {
        await queryClient.cancelQueries([getAdminWorkspacesKey, environmentId])

        const previousWorkspaces: Workspace[] =
          queryClient.getQueryData([getAdminWorkspacesKey, environmentId]) || []

        queryClient.setQueryData(
          [getAdminWorkspacesKey, environmentId],
          (old: Workspace[] = []) => [
            ...old,
            {
              ...newWorkspace,
              id: newWorkspace.name, // Ensure the new workspace has an id
            },
          ]
        )

        return { previousWorkspaces }
      },
      onError(_err, _newWorkspace, context: CreateWorkspaceContext) {
        queryClient.setQueryData(
          [getAdminWorkspacesKey, environmentId],
          context.previousWorkspaces
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAdminWorkspacesKey, environmentId])
      },
    }
  )
}
