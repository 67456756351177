import CustomDialog from '../../../shared/dialog/dialog'
import { useState } from 'react'
import { AppRegistration } from '../../../../../models'
import { useGetAppRegItems } from '../../../../../hooks/queries/useGetAppRegItems'
import { AffectedItemsDisplay, WarningMessage } from './affected-app-reg-items'

function DeleteAppRegDialog(props: DeleteAppRegDialogProps) {
  const { open, onClose, appReg, handleDelete, numberOfAppRegistrations } =
    props

  const [deleteLoading, setDeleteLoading] = useState(false)

  const { data: items } = useGetAppRegItems(appReg.id)

  const disableDelete =
    numberOfAppRegistrations === 1 &&
    items?.[0]?.reports?.length > 0 &&
    items?.[0]?.workspaces?.length > 0

  if (!appReg) return null

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`${appReg.name}`}
      primaryButtonProps={{
        disabled: deleteLoading || disableDelete,
        children: 'Delete',
        onClick: async () => {
          setDeleteLoading(true)
          await new Promise(resolve => setTimeout(resolve, 2500))
          await handleDelete()
          setDeleteLoading(false)
          onClose()
        },
      }}
      loading={deleteLoading}
      secondaryButtonProps={{
        disabled: deleteLoading,
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <WarningMessage
        itemType='app registration'
        disableDelete={disableDelete}
      />
      <AffectedItemsDisplay items={items} />
    </CustomDialog>
  )
}

type DeleteAppRegDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => void
  appReg: AppRegistration
  numberOfAppRegistrations: number
}

export default DeleteAppRegDialog
